import { translation } from "../redux/selectors/translation";
import { useSelector } from "react-redux";

const useTranslation = () => {
  const translations = useSelector(translation);
  let result = {};

  translations.forEach((elem) => (result[elem.key] = elem.value));

  if (Object.keys(result) !== 0) return result;

  return null;
};

export { useTranslation };
