const ResultAttachmentStatusEnum = Object.freeze({
    NotAdded: 1,
    Added: 2,
    Published: 3,
});

const ResultAttachmentStatusMapLV = new Map([
    [ResultAttachmentStatusEnum.NotAdded, "Nav"],
    [ResultAttachmentStatusEnum.Added, "Pievienots"],
    [ResultAttachmentStatusEnum.Published, "Publicēts"],
]);

export { ResultAttachmentStatusEnum, ResultAttachmentStatusMapLV };