export const SET_SYSTEM_NOTIFICATIONS = "SET_SYSTEM_NOTIFICATIONS";
export const UNSET_SYSTEM_NOTIFICATIONS = "UNSET_SYSTEM_NOTIFICATIONS";
export const SET_SYTEM_NOTIFICATOIN_ERROR = "SET_SYTEM_NOTIFICATION_ERROR";

export const setSystemNotifications = (payload) => ({
  type: SET_SYSTEM_NOTIFICATIONS,
  payload: payload,
});

export const unsetSystemNotifications = (id) => ({
  type: UNSET_SYSTEM_NOTIFICATIONS,
  payload: id,
});

export const setSystemNotificationError = (payload) => ({
  type: SET_SYTEM_NOTIFICATOIN_ERROR,
  payload: payload,
});
