import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { TitleForm, CustomBreadCrumbs } from "../../MUI_components";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ConsentBiobankFilling = () => {
  const [consent, setConsent] = React.useState();

  const {
    text: { dark_color },
  } = useTheme();

  const [hasPreviousConsentVersion, setHasPreviousConsentVersion] =
    React.useState(false);

  const handleClickViewConsentVersions = () => {};

  React.useEffect(() => {
    //Fetch checkbox agreements
  }, []);

  return (
    <>
      <Container maxWidth={"xl"} disableGutters>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} my={2}>
            <TitleForm
              title={"Informētā piekrišana"}
              titleCode={"FRM.CONS.REG"}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomBreadCrumbs links={[]} />
          </Grid>
          {hasPreviousConsentVersion && (
            <Grid item xs={4}>
              <Grid container component={Box} boxShadow={3} p={3}>
                <Grid
                  container
                  display={"flex"}
                  justifyContent="space-between"
                  item
                  xs={12}
                >
                  <Grid item xs={8}>
                    <Typography variant="h4">{consent?.title}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  direction={"column"}
                  xs={3}
                  item
                  display={"flex"}
                  gap="1rem"
                >
                  <Typography color={dark_color} variant="h5">
                    Datums -
                  </Typography>
                  <Typography color={dark_color} variant="h5">
                    Aizpildītājs -
                  </Typography>
                </Grid>
                <Grid item xs={9} display="flex" justifyContent={"flex-end"}>
                  <Grid>
                    <Button
                      size="large"
                      variant="outlined"
                      color="primary"
                      onClick={handleClickViewConsentVersions}
                    >
                      Apskatīt
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Typography variant="h4">Piekrišanas nosaukums</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography p={3}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
              sem. Nulla consequat massa quis enim. Donec. Lorem ipsum dolor sit
              amet, consectetuer adipiscing elit. Aenean commodo ligula eget
              dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
              parturient montes, nascetur ridiculus mus. Donec quam felis,
              ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat
              massa quis enim. Donec.
            </Typography>
          </Grid>
          <Grid direction={"column"} mx={2} item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
              <FormControlLabel
                disabled
                control={<Checkbox />}
                label="Disabled"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
              <FormControlLabel
                disabled
                control={<Checkbox />}
                label="Disabled"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              />
              <FormControlLabel
                disabled
                control={<Checkbox />}
                label="Disabled"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center" gap="1rem">
            <Button variant="contained" color="success">
              Iesniegt
            </Button>
            <Button variant="contained" color="inherit">
              Atcelt
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ConsentBiobankFilling;
