import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { decreaseFont, increaesFont, setStep } from "./redux/actions/font";
import { h1, h2, h3, h4, h5, h6 } from "./redux/selectors/font";

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 321,
      md: 800,
      lg: 1000,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "Roboto",
      },
      styleOverrides: {
        h1: {
          fontWeight: "400",
          fontSize: "64px",
          lineHeight: "96px",
          color: "rgba(16, 58, 93, 1)",
        },
        h2: {
          fontWeight: "400",
          fontSize: "48px",
          lineHeight: "72px",
          color: "rgba(16, 58, 93, 1)",
        },
        h3: {
          fontWeight: "400",
          fontSize: "36px",
          lineHeight: "54px",
          color: "rgba(16, 58, 93, 1)",
        },
        h4: {
          fontWeight: "400",
          fontSize: "24px",
          lineHeight: "36px",
          color: "rgba(16, 58, 93, 1)",
        },
        h5: {
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: "rgba(16, 58, 93, 1)",
        },
        h6: {
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
        },
        caption: {
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "18px",
        },
        paragraph: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "21px",
          color: "rgba(51, 51, 51, 1)",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "false",
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        containedPrimary: {
          backgroundColor: "rgba(16, 58, 93, 1)",
          borderRadius: "2px",
          ":hover": {
            background: "rgba(23, 84, 134, 1)",
          },
          ":active": {
            background: "rgba(1, 35, 64, 1)",
          },
        },
        outlinedSecondary: {
          borderColor: "#103A5D",
          color: "rgba(16, 58, 93, 1)",
          borderRadius: "1px",
          ":hover": {
            borderColor: "rgba(23, 84, 134, 1)",
          },
          ":active": {
            borderColor: "rgba(1, 35, 64, 1)",
          },
        },
        containedSuccess: {
          background: "rgba(82, 110, 48, 1)",
          borderRadius: "2px",
          ":hover": {
            background: "rgba(118, 158, 69, 1)",
          },
          ":active": {
            background: "rgba(41, 59, 19, 1)",
          },
        },
        containedError: {
          background: "rgba(200, 33, 52, 1)",
          borderRadius: "2px",
          ":hover": {
            background: "rgba(225, 80, 96, 1)",
          },
          ":active": {
            background: "rgba(140, 23, 36, 1)",
          },
        },
      },
    },
  },
});

export const useTheme = () => {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.Font.step);
  const h1Value = useSelector(h1);
  const h2Value = useSelector(h2);
  const h3Value = useSelector(h3);
  const h4Value = useSelector(h4);
  const h5Value = useSelector(h5);
  const h6Value = useSelector(h6);

  const customTheme = createTheme({
    text: {
      link_color: "#007FE3",
      dark_color: "#333333",
      dark_yellow: "#5B4200",
    },

    palette: {
      primary: {
        main: "#103A5D",
        light: "#175486",
        dark: "#012340",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 321,
        md: 800,
        lg: 1000,
        lx: 1200,
        xl: 1440,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontWeight: "400",
            fontSize: `${h1Value}px`,
            lineHeight: "96px",
            color: "rgba(16, 58, 93, 1)",
          },
          h2: {
            fontWeight: "400",
            fontSize: `${h2Value}px`,
            lineHeight: "72px",
            color: "rgba(16, 58, 93, 1)",
          },
          h3: {
            fontWeight: "400",
            fontSize: `${h3Value}px`,
            lineHeight: "54px",
            color: "rgba(16, 58, 93, 1)",
          },
          h4: {
            fontWeight: "400",
            fontSize: `${h4Value}px`,
            lineHeight: "36px",
            color: "rgba(16, 58, 93, 1)",
          },
          h5: {
            fontWeight: "400",
            fontSize: `${h5Value}px`,
            lineHeight: "24px",
            color: "rgba(16, 58, 93, 1)",
          },
          h6: {
            fontWeight: "400",
            fontSize: `${h6Value}px`,
            lineHeight: "18px",
          },
          paragraph: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "rgba(51, 51, 51, 1)",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "false",
        },
        styleOverrides: {
          root: {
            textDecoration: "none",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
          },
          containedPrimary: {
            backgroundColor: "rgba(16, 58, 93, 1)",
            borderRadius: "2px",
            ":hover": {
              background: "rgba(23, 84, 134, 1)",
            },
            ":active": {
              background: "rgba(1, 35, 64, 1)",
            },
          },
          outlinedSecondary: {
            borderColor: "#103A5D",
            color: "rgba(16, 58, 93, 1)",
            borderRadius: "1px",
            ":hover": {
              borderColor: "rgba(23, 84, 134, 1)",
            },
            ":active": {
              borderColor: "rgba(1, 35, 64, 1)",
            },
          },
          containedSuccess: {
            background: "rgba(82, 110, 48, 1)",
            borderRadius: "2px",
            ":hover": {
              background: "rgba(118, 158, 69, 1)",
            },
            ":active": {
              background: "rgba(41, 59, 19, 1)",
            },
          },
        },
      },
    },
  });

  const increaseFontAction = () => {
    const h1 = 64;
    const h2 = 48;
    const h3 = 36;
    const h4 = 24;
    const h5 = 16;
    const h6 = 14;

    let newValue = {
      h1: h1Value + h1 * 0.5,
      h2: h2Value + h2 * 0.5,
      h3: h3Value + h3 * 0.5,
      h4: h4Value + h4 * 0.5,
      h5: h5Value + h5 * 0.5,
      h6: h6Value + h6 * 0.5,
    };
    if (step < 2) {
      dispatch(setStep(step + 1));
      dispatch(increaesFont(newValue));
    }
  };

  const decreaseFontAction = () => {
    let h1 = 64;
    let h2 = 48;
    let h3 = 36;
    let h4 = 24;
    let h5 = 16;
    let h6 = 14;

    let newValue = {
      h1: h1Value - h1 * 0.5,
      h2: h2Value - h2 * 0.5,
      h3: h3Value - h3 * 0.5,
      h4: h4Value - h4 * 0.5,
      h5: h5Value - h5 * 0.5,
      h6: h6Value - h6 * 0.5,
    };

    if (step > 0) {
      dispatch(setStep(step - 1));
      dispatch(decreaseFont(newValue));
    }
  };

  return { customTheme, increaseFontAction, decreaseFontAction };
};
