import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { translation } from "../redux/selectors/translation";

const CancelButton = ({ onClick }) => {
  const translations = useSelector(translation);
  const cancelButtonTr = translations.find(
    (x) => x.key === "ButtonCancel"
  )?.value;

  return (
    <Button color="secondary" variant="outlined" onClick={onClick}>
      {cancelButtonTr}
    </Button>
  );
};

export default CancelButton;
