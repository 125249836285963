import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const DashboardCard = ({ title, headerActions, loading = false, children }) => {
  const theme = useTheme();

  return (
    <>
    {
      loading ? (
        <Skeleton
          variant="rectangular"
          height={"12.5rem"}
        />
      ) :
      (
        <Card sx={{ boxShadow: 2, background: theme.palette.common.white, minHeight: "12.5rem"}}>
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "h4" }}
            action={headerActions}
          />
          <CardContent>
            {children}
          </CardContent>
        </Card >
      )
    }
  </>
  )
};

export default DashboardCard;
