import { useState } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import BaseButton from "../buttons/BaseButton";
import FileUpload from "../file-upload/FileUpload";

const FileUploadDialog = ({ show, allowedFileFormats, handleFileUpload, handleDialogClose }) => {

  const [files, setFiles] = useState([]);
  function dialogTitle() {
    return "Augšupielādēt datni";
  }

  function isUploadButtonDisabled() {
    return files?.length ? false : true;
  }

  function handleClose() {
    handleDialogClose();
    setFiles([]);
  }

  function handleUploadClick() {
    handleFileUpload(files[0]);
    handleDialogClose();
    setFiles([]);
  }
  return (
    <Dialog open={show} maxWidth="lg">
        <DialogTitle>{dialogTitle()}</DialogTitle>
        <DialogContent>
          <Grid container gap={2} direction="column">
            <Grid item>
               <FileUpload 
                value={files}
                onChange={setFiles}
                allowedFileFormats ={allowedFileFormats}
               />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <BaseButton
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleUploadClick}
                fullWidth
                disabled={isUploadButtonDisabled()}
              >
                {"Augšupielādēt"}
              </BaseButton>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                color="error"
                variant="contained"
                fullWidth
                onClick={handleClose}
              >
                {"Atcelt"}
              </BaseButton>
            </Grid>
          </Grid>
        </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
