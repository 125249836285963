const ClSurveyResponseStatusEnum = Object.freeze({
  Draft: 1,
  Submitted: 2,
  Withdrawn: 3,
});

const ClSurveyResponseStatusMapLV = new Map([
  [ClSurveyResponseStatusEnum.Draft, "Sagatave"],
  [ClSurveyResponseStatusEnum.Submitted, "Iesniegts"],
  [ClSurveyResponseStatusEnum.Withdrawn, "Atsaukts"],
]);

export { ClSurveyResponseStatusEnum, ClSurveyResponseStatusMapLV };