import { useSelector } from "react-redux";
import { translation } from "../redux/selectors/translation";

const useCodeToMessage = () => {
  const translations = useSelector(translation);

  function findMessageByCode(code) {
    const foundMessage = translations.find((elem) => elem.key === code)?.value;
    if (foundMessage !== undefined) return foundMessage;
    else return code;
  }

  return { findMessageByCode };
};

export { useCodeToMessage };
