import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CustomInput, TitleForm } from "../../MUI_components";
import CustomDatePicker from "../../MUI_components/CustomDatePicker";
import { FormProvider, useForm } from "react-hook-form";
import http from "../../api/http";
import ConsentBlock from "../research/adminView/InformedConsent/ConsentBlock";
import PublishedSingleResearchDropdown from "../../MUI_components/PublishedSingleResearchDropdown";
import ConsentResearch from "./ConsentResearch";
import { useEffect } from "react";

const dummyConsent = {
  id: 1,
  titleLv: "title lv",
  titleEn: "title en",
  titleRu: "title ru",
  contentLv:
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  contentEn:
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
  contentRu:
    "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
};

const ConsentResearchNurseFill = () => {
  const form = useForm();

  const [researchId, setResearchId] = React.useState("");

  const [participant, setParticipant] = React.useState({});

  //const [consentFound, setConsentFound] = React.useState(false);

  const [researchConsent, setResearchConsent] = React.useState({});

  const fetchParticipantsWithPersonCodeOrUniqueId = async (code) => {
    try {
      const { data } = await http.backendInstance.post(
        "/api/person/searchbypersoncodeorid",
        { code }
      );
      setParticipant(data);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleParticipantSearch = async (event) => {
    const inputValue = form.getValues("participant");
    await fetchParticipantsWithPersonCodeOrUniqueId(inputValue);
  };

  const handleResearchChange = (event, research) => {
    if (!research) return;
    const { id } = research;
    setResearchId(id);
    console.log(id);
  };

  const fetchParticipantResearch = () => {
    //request to find out if participant has research consent filled in
  };

  const fetchResearchConsents = async () => {
    if (!researchId) return;
    try {
      const { data } = await http.backendInstance.get(
        `/api/research/publication/${researchId}/consents`
      );
      setResearchConsent(data);
    } catch (error) {
      console.log(error);
    }
  };

  console.log(researchId);

  useEffect(() => {
    if (researchId) fetchResearchConsents();
  }, [researchId]);

  return (
    <>
      <Container maxWidth={"xl"} mx={"auto"}>
        <Grid container my={2} rowSpacing={3} columnSpacing={1}>
          <FormProvider {...form}>
            <Grid xs={12} item>
              <TitleForm
                title={"Informētā piekrišana"}
                titleCode={"FRM.CONS.REG"}
              />
            </Grid>
            <Grid xs={4} item>
              <CustomInput name={"participant"} label={"Dalībnieks*"} />
            </Grid>
            <Grid xs={1} item>
              <Button
                onClick={handleParticipantSearch}
                variant="contained"
                color="primary"
              >
                Atlasīt
              </Button>
            </Grid>
            <Grid xs={5} mx={2} item>
              <CustomDatePicker
                label={"Iesniegšanas datums"}
                name={"consentFilledDate"}
              />
            </Grid>
            <Grid xs={10} item my={2}>
              <PublishedSingleResearchDropdown
                name={"researchName"}
                onChange={handleResearchChange}
              />
            </Grid>
          </FormProvider>
          {/*             { Somehow figure out what this consent block does and why it is here } */}
          {/*             { researchConsent ?  <ConsentBlock /> : null } */}
          {/*             <Grid item xs={6}>
              <ConsentBlock consent={{ title: "some title" }} />
            </Grid> */}
          <ConsentResearch {...dummyConsent} />
        </Grid>
      </Container>
    </>
  );
};

export default ConsentResearchNurseFill;
