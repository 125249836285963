export const LOAD_RESEARCHERS = "LOAD_RESEARCHERS";
export const LOAD_LEADING_RESEARCHERS = "LOAD_LEADING_RESEARCHERS";
export const LOAD_INVOLVED_RESEARCHERS = "LOAD_INVOLVED_RESEARCHERS";
export const SET_RESEARCH_ID = "SET_RESEARCH_ID";
export const UNSET_INVOLVED_RESEARCHER = "UNEST_INVOLVED_RESEARCHER";
export const SET_THIRD_STAGE_ERROR = "SET_THIRD_STAGE_ERROR";
export const SET_SUBMIT_SUCCESSFUL = "SET_SUBMIT_SUCCESSFUL";
export const RESET_SUBMIT_SUCCESSFUL = "RESET_SUBMIT_SUCCESSFUL";

export const setThirdStageError = (payload) => ({
  type: SET_THIRD_STAGE_ERROR,
  payload: payload,
});

export const loadResearchers = (payload) => ({
  type: LOAD_RESEARCHERS,
  payload: payload,
});

export const loadLeadingResearchers = (payload) => ({
  type: LOAD_LEADING_RESEARCHERS,
  payload: payload,
});

export const loadInvolvedResearchers = (payload) => ({
  type: LOAD_INVOLVED_RESEARCHERS,
  payload: payload,
});

export const setResearchId = (payload) => ({
  type: SET_RESEARCH_ID,
  payload: payload,
});

export const setSubmitSuccessful = (payload) => ({
  type: SET_SUBMIT_SUCCESSFUL,
  payload: payload,
});

export const unsetInvolvedResearcher = () => ({
  type: UNSET_INVOLVED_RESEARCHER,
});

export const resetSubmitSuccessful = (payload) => ({
  type: RESET_SUBMIT_SUCCESSFUL,
  payload: payload
});

