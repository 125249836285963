const ParticipantStatusEnum = Object.freeze({
    Active: "ACTIVE",
    Applied: "APPLIED",
    Canceled: "CANCELED"
});

const ParticipantStatusMapLv = new Map([
    [ParticipantStatusEnum.Active, "Aktīvs"],
    [ParticipantStatusEnum.Applied, "Pieteikts"],
    [ParticipantStatusEnum.Canceled, "Atcelts"],
]);

export {
    ParticipantStatusEnum,
    ParticipantStatusMapLv
};