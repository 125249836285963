const ClAnswerTypeEnum = Object.freeze({
  Radio: 1,
  Checkbox: 2,
  DropDown: 3,
  Free: 4,
});

const ClAnswerTypeMapLV = new Map([
  [ClAnswerTypeEnum.Radio, "Radio"],
  [ClAnswerTypeEnum.Checkbox, "Checkbox"],
  [ClAnswerTypeEnum.DropDown, "DropDown"],
  [ClAnswerTypeEnum.Free, "Brīvs teksts"]
]);

export { ClAnswerTypeEnum, ClAnswerTypeMapLV };