import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import React from "react";

export const TranslationInput = React.forwardRef(
  ({ defaultValue, value, ...props }, ref) => {
    return (
      <Box
        alignItems="center"
        justifyContent="space-between"
        gap="1rem"
        display="flex"
      >
        <Box display="flex" gap="1rem">
          <FormControl size="medium">
            <TextField
              ref={ref}
              size="small"
              {...props}
              value={value}
              defaultValue={defaultValue}
            />
          </FormControl>
        </Box>
      </Box>
    );
  }
);
