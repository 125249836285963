import http from "../api/http";
import { configureStore } from '../redux/store';
import {
  setAccessToken,
  setAuthenticated,
  setAccessRights,
  setTokenLifeTime,
} from "../redux/actions/auth";

export async function refreshAccessToken() {
  try {
    const { data } = await http.authInstance.get("/api/auth/refreshToken");
    const { accessToken, rights, tokenLifeTime } = data;

    configureStore.dispatch(setAccessToken(accessToken));
    configureStore.dispatch(setAccessRights(rights));
    configureStore.dispatch(setTokenLifeTime(tokenLifeTime));
    configureStore.dispatch(setAuthenticated(true));
  } catch (error) {
    console.error("Failed to refresh token:", error);
    configureStore.dispatch(setAuthenticated(false));
    throw error;
  }
}
