import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState, useRef } from "react";
import HelpIcon from "@mui/icons-material/Help";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Link as RouterLink } from "react-router-dom";
import ROLE from "../../roles/roles";
import classes from "./header.module.scss";
import LogoutButton from "../logout/LogoutButton";
import { ShowCountdown } from "../showCountdown";
import { LanguageDropdown } from "../language/LanguageDropdown";
import RIGHTS from "../../rights_enum";
import useAuth from "../../hooks/useAuth";
import useRole from "../../hooks/useRole";
import useToken from "../../hooks/useToken";
import { DisplayTextIncreaseDecrease } from "./DisplayTextIncreaseDecrease";
import { DisplayUsernameAndRole } from "./DisplayUsernameAndRole";
import { AnimatedSettingsIcon } from "./AnimatedSettingsIcon";
import { AnimatedMessageIcon } from "./AnimatedMessageIcon";
import { useTranslation } from "../../hooks/useTranslation";
import roles from "../../roles/roles";
import messageService from "../../services/message-service";
import { useLocation } from "react-router-dom";
import axios from "axios";

const parentBlockStyles = {
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
export const HeaderTop = () => {
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0);
  const location = useLocation();
  const prevLocationRef = useRef(location.pathname);
  const { isAuthenticated } = useAuth();
  const { role } = useRole();
  const [anchorSettings, setAnchorSettings] = useState(null);
  const openSettings = Boolean(anchorSettings);
  const { rights } = useToken();

  const {
    MainMenuNotifications,
    MainMenuSettings,
    MainMenuSettingsClList,
    MainMenuSettingsCms,
    MainMenuSettingsMyProfile,
    MainMenuSettingsSwitchRole,
    MainMenuSettingsUserAdm,
    MainMenuFaq,
  } = useTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const currentPath = location.pathname;
    const prevPath = prevLocationRef.current;

    const shouldUpdateCount =
      currentPath.includes("/dashboard") ||
      currentPath.includes("/notifications/receivedMessages") ||
      (prevPath.includes("/dashboard") && !currentPath.includes("/dashboard"));

    const updateUnreadMessageCount = async () => {
      try {
        const count = await messageService.getUnreadMessageCount(signal);
        setNumberOfUnreadMessages(count);
      } catch (error) {
        if (!axios.isCancel(error) && error.name !== "AbortError")
          console.error(error);
      }
    };

    if (shouldUpdateCount)
      updateUnreadMessageCount();

    prevLocationRef.current = currentPath;

    return () => {
      abortController.abort();
    };
  }, [location]);

  const handleSettigsClick = (event) => {
    setAnchorSettings(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorSettings(null);
  };

  const isCmsAllowedForUser =
    (role.name === roles.Admin || role.name === roles.Researcher) &&
    (rights.includes(RIGHTS.CMS_READ) || rights.includes(RIGHTS.CMS_EDIT));

  return (
    <Box className={classes.top_bg}>
      <Box sx={parentBlockStyles}>
        <Box display="flex" alignItems="center">
          {isAuthenticated && <DisplayUsernameAndRole />}
        </Box>
        <Box
          display={{ xs: "none", lg: "flex" }}
          alignItems="center"
          gap="1rem"
        >
          <DisplayTextIncreaseDecrease />
          <LanguageDropdown />
          {isAuthenticated && (
            <>
              <ButtonBase
                sx={{ color: "black", display: "flex", gap: ".2rem" }}
                size="small"
              >
                <HelpIcon fontSize="small" />
                <Link component={RouterLink} to={"/faq"}>
                  {" "}
                  {MainMenuFaq}
                </Link>
              </ButtonBase>
              <ButtonBase
                size="small"
                sx={{ color: "black", display: "flex", gap: ".2rem" }}
              >
                <AnimatedMessageIcon />
                <Link
                  component={RouterLink}
                  to={"/notifications/receivedMessages"}
                >
                  {" "}
                  {MainMenuNotifications} ({numberOfUnreadMessages})
                </Link>
              </ButtonBase>
              <ButtonBase
                onClick={handleSettigsClick}
                aria-expanded={openSettings ? true : false}
                id="settings-options"
                aria-controls="settings"
                sx={{ display: "flex", gap: ".2rem" }}
              >
                <AnimatedSettingsIcon fontSize="small" />
                <span>
                  <Typography variant="h6">{MainMenuSettings}</Typography>
                </span>
                <ArrowDropDown />
              </ButtonBase>
              <Menu
                id="settings"
                aria-labelledby="settings-options"
                anchorEl={anchorSettings}
                open={openSettings}
                onClose={handleSettingsClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Link
                  color="black"
                  component={RouterLink}
                  to="/userprofile"
                  onClick={handleSettingsClose}
                >
                  <MenuItem> {MainMenuSettingsMyProfile} </MenuItem>
                </Link>
                {role.name === ROLE.Admin &&
                  rights.includes(RIGHTS.USER_ADM_READ) && (
                    <div>
                      <Link
                        color="black"
                        component={RouterLink}
                        to="/admin/settings"
                      >
                        <MenuItem onClick={handleSettingsClose}>
                          {MainMenuSettingsUserAdm}
                        </MenuItem>
                      </Link>
                      {(rights.includes(RIGHTS.CLASSIFIER_READ) ||
                        rights.includes(RIGHTS.CLASSIFIER_EDIT)) && (
                          <Link
                            color="black"
                            component={RouterLink}
                            to="/classifiers"
                          >
                            <MenuItem onClick={handleSettingsClose}>
                              {MainMenuSettingsClList}
                            </MenuItem>
                          </Link>
                        )}
                      <Link
                        color="black"
                        component={RouterLink}
                        to="/admin/auditList"
                      >
                        <MenuItem onClick={handleSettingsClose}>
                          Auditācijas pieraksti
                        </MenuItem>
                      </Link>
                      {rights.includes(RIGHTS.CMS_READ) && (
                        <Link
                          color="black"
                          component={RouterLink}
                          to="/cms/translations"
                        >
                          <MenuItem onClick={handleSettingsClose}>
                            Tulkojumi
                          </MenuItem>
                        </Link>
                      )}
                      {isCmsAllowedForUser && (
                        <Link
                          color="black"
                          component={RouterLink}
                          to="/cms/home"
                        >
                          <MenuItem onClick={handleSettingsClose}>
                            {MainMenuSettingsCms}
                          </MenuItem>
                        </Link>
                      )}
                    </div>
                  )}
                <Link color="black" component={RouterLink} to="/changerole">
                  <MenuItem onClick={handleSettingsClose}>
                    {MainMenuSettingsSwitchRole}
                  </MenuItem>
                </Link>
              </Menu>
              <LogoutButton />
              <ShowCountdown />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
