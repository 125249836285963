import referralService from "../../services/referral-service";

// action types
export const LOAD_REFERRALS = 'LOAD_REFERRALS';
export const FETCH_LABORATORIES = 'FETCH_LABORATORIES';
export const FETCH_LABORATORIES_SUCCESS = 'FETCH_LABORATORIES_SUCCESS';
export const FETCH_LABORATORIES_ERROR = 'FETCH_LABORATORIES_ERROR';
export const RESET_NEW_REFERRAL_ID = 'RESET_NEW_REFERRAL_ID';
export const REFERRAL_UPDATED = 'REFERRAL_UPDATED';
export const FETCH_REFERRAL_SUCCESS = 'FETCH_REFERRAL_SUCCESS';
export const FETCH_REFERRAL_ERROR = 'FETCH_REFERRAL_ERROR';
export const SAVE_REFERRAL_SUCCESS = 'SAVE_REFERRAL_SUCCESS';
export const SAVE_REFERRAL_ERROR = 'SAVE_REFERRAL_ERROR';
export const ADD_REFERRAL_SUCCESS = 'ADD_REFERRAL_SUCCESS';
export const ADD_REFERRAL_FAILURE = 'ADD_REFERRAL_FAILURE';
export const FETCH_LABORATORY_SUCCESS = 'FETCH_LABORATORY_SUCCESS';
export const FETCH_LABORATORY_ERROR = 'FETCH_LABORATORY_ERROR';

// Action creators
export const setReferralUpdated = (updated) => ({
    type: REFERRAL_UPDATED,
    payload: updated
});

export const loadReferrals = referrals => ({
    type: LOAD_REFERRALS,
    payload: referrals
});

export const fetchReferral = (id) => async (dispatch) => {
    try {
        const response = await referralService.getReferralById(id);
        dispatch({ type: FETCH_REFERRAL_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_REFERRAL_ERROR, payload: error });
    }
};

export const saveReferral = (referralData) => async dispatch => {
    try {
        await referralService.updateReferral(referralData);
        dispatch({ type: SAVE_REFERRAL_SUCCESS });
    } catch (error) {
        dispatch({ type: SAVE_REFERRAL_ERROR, payload: error });
    }
};

export const createReferral = (data) => async dispatch => {
    try {
        const response = await referralService.createReferral(data);
        dispatch({ type: ADD_REFERRAL_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: ADD_REFERRAL_FAILURE, payload: error });
    }
};

export const fetchLaboratories = () => async dispatch => {
    try {
        const response = await referralService.getLaboratories();
        dispatch({ type: FETCH_LABORATORIES_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_LABORATORIES_ERROR, payload: error });
    }
};

export const fetchLaboratory = (laboratoryId) => async (dispatch) => {
    try {
        const response = await referralService.getLaboratoryById(laboratoryId);
        dispatch({ type: FETCH_LABORATORY_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: FETCH_LABORATORY_ERROR, payload: error });
    }
};

export const resetNewReferralId = () => ({
    type: RESET_NEW_REFERRAL_ID,
});
