import { useEffect, useState } from "react";
import { Grid, Typography, Grow, Tooltip } from "@mui/material";
import { useTheme } from "@emotion/react";
import { NotificationImportant, Info, Error } from "@mui/icons-material";
import CONSTANTS from "../../constants";

const NotificationType = Object.freeze({
  Info: "info",
  Warning: "warning",
  Error: "error"
});

const NotificationCard = ({ type = "info", text }) => {
  const theme = useTheme();

  const notificationStyles = {
    [NotificationType.Warning]: {
      icon: <NotificationImportant size="small" style={{ color: theme.text.dark_yellow }} />,
      backgroundColor: theme => CONSTANTS.SYSTEM_NOTIFICATION_COLORS.CAUTION,
      textColor: theme => theme.text.dark_yellow,
      tooltipTitle: "Brīdīnājums",
    },
    [NotificationType.Info]: {
      icon: <Info size="small" color="primary" />,
      backgroundColor: theme => {return CONSTANTS.SYSTEM_NOTIFICATION_COLORS.INFORMATIONAL},
      textColor: theme => theme.text.dark_color,
      tooltipTitle: "Informācija",
    },
    [NotificationType.Error]: {
      icon: <Error size="small" style={{ color: theme.palette.error.contrastText }}/>,
      backgroundColor: theme => theme.palette.error.light,
      textColor: theme => theme.palette.error.contrastText,
      tooltipTitle: "Uzmanību",
    }
  };

  const [notificationStyle, setNotificationStyle] = useState(null);

  useEffect(() => {
    function initData() {
      switch (type) {
        case NotificationType.Warning:
          setNotificationStyle(notificationStyles[NotificationType.Warning]);
          break;
        case NotificationType.Info:
          setNotificationStyle(notificationStyles[NotificationType.Info]);
          break;
        case NotificationType.Error:
          setNotificationStyle(notificationStyles[NotificationType.Error]);
          break ;
        default:
          setNotificationStyle(null);
      }
    }

    initData();
  }, []);

  function notificationIcon() {
    if (!notificationStyle)
      return;

    return (
      <Tooltip title={notificationStyle?.tooltipTitle}>
        {notificationStyle?.icon}
      </Tooltip>
    );
  }
  return (
    <Grow in timeout={1000}>
      <Grid
        container
        backgroundColor={notificationStyle?.backgroundColor(theme)}
        sx={{ boxShadow: 2, minHeight: "4rem" }}
        p={1}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          <Grid
            item
            xs={1}
          >
            {notificationIcon()}
          </Grid>
          <Grid
            item
            xs={11}
          >
            <Typography
              variant="subtitle1"
              color={notificationStyle?.textColor(theme)}
            >
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grow>
  );
};

export default NotificationCard;