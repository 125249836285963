import {
  LOAD_REFERRALS,
  FETCH_LABORATORIES,
  FETCH_LABORATORIES_SUCCESS,
  FETCH_LABORATORIES_ERROR,
  RESET_NEW_REFERRAL_ID,
  REFERRAL_UPDATED,
  FETCH_REFERRAL_SUCCESS,
  FETCH_REFERRAL_ERROR,
  SAVE_REFERRAL_SUCCESS,
  SAVE_REFERRAL_ERROR,
  ADD_REFERRAL_SUCCESS,
  ADD_REFERRAL_FAILURE,
  FETCH_LABORATORY_SUCCESS,
  FETCH_LABORATORY_ERROR
} from '../actions/referral';

const initialState = {
  referrals: [],
  currentReferral: {},
  currentLaboratory: {},
  laboratories: [],
  isLoadingLaboratories: false,
  newReferralId: null,
  referralUpdated: false
};

export const Referral = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LABORATORIES:
      return { ...state, isLoadingLaboratories: true };
    case FETCH_LABORATORIES_SUCCESS:
      return { ...state, isLoadingLaboratories: false, laboratories: action.payload };
    case FETCH_LABORATORIES_ERROR:
      return { ...state, isLoadingLaboratories: false };
    case LOAD_REFERRALS:
      return { ...state, referrals: action.payload };
    case FETCH_REFERRAL_SUCCESS:
      return { ...state, currentReferral: action.payload };
    case FETCH_REFERRAL_ERROR:
      return { ...state }; // Handle error as needed
    case SAVE_REFERRAL_SUCCESS:
      return { ...state }; // Handle success as needed
    case SAVE_REFERRAL_ERROR:
      return { ...state }; // Handle error as needed
    case ADD_REFERRAL_SUCCESS:
      return { ...state, currentReferral: action.payload, newReferralId: action.payload.id };
    case ADD_REFERRAL_FAILURE:
      return { ...state, newReferralId: null };
    case FETCH_LABORATORY_SUCCESS:
      return { ...state, currentLaboratory: action.payload };
    case FETCH_LABORATORY_ERROR:
      return { ...state }; // Handle error as needed
    case RESET_NEW_REFERRAL_ID:
      return {
        ...state,
        newReferralId: null,
      };
    case REFERRAL_UPDATED:
      return {
        ...state,
        referralUpdated: action.payload
      };
    default:
      return state;
  }
};
