export const SET_FOOTER_USEFUL_LINKS = "[cms] set useful links";
export const SET_FOOTER_CONTENT = "[cms] set footere content";
export const SET_HOMEPAGE_IMAGE_SRC_ARRAY = "[cms] set homepage image src array";
export const SET_MOREINFORMATION_GROUPS = "[cms] set more information groups";
export const SET_MOREINFORMATION_GROUPS_UPDATE_DATE = "[cms] set more information groups update date";
export const SET_FAQ_UPDATE_DATE = "[cms] set faq update date";
export const SET_FAQ_GROUPS = "[cms] set faq groups";

export const setFaqUpdateDate = (payload) => ({
    type: SET_FAQ_UPDATE_DATE,
    payload: payload,
});

export const setFaqGroups = (payload) => ({
    type: SET_FAQ_GROUPS,
    payload: payload,
});

export const setMoreInformationGroupsUpdateDate = (payload) => ({
    type: SET_MOREINFORMATION_GROUPS_UPDATE_DATE,
    payload: payload,
});

export const setMoreInformationGroups = (payload) => ({
    type: SET_MOREINFORMATION_GROUPS,
    payload: payload,
});

export const setHomePageImageSrcArray = (payload) => ({
  type: SET_HOMEPAGE_IMAGE_SRC_ARRAY,
  payload: payload,
});

export const setFooterUsefulLinks = (payload) => ({
  type: SET_FOOTER_USEFUL_LINKS,
  payload: payload,
});

export const setFooterContent = (payload) => ({
  type: SET_FOOTER_CONTENT,
  payload: payload,
});
