import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import http from "../api/http";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "../hooks/useTranslation";

const ResearchSelect = ({ name, required, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [topics, setTopics] = React.useState([]);
  const { ValidationErrorMandatoryField } =
    useTranslation() || "Lauks ir obligāts";

  const fetchTopics = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/public/researchPublications"
      );
      setTopics(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <FormControl size="small" error={errors.hasOwnProperty(name)} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        rules={{ required: required ? ValidationErrorMandatoryField : false }}
        control={control}
        defaultValue={""}
        name={name}
        render={({ field }) => (
          <Select fullWidth label={label} defaultValue={""} {...field}>
            {topics.map((option) => {
              return (
                <MenuItem key={option.id} value={option.code}>
                  {option.TitleLv}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
      <FormHelperText error>
        {errors.hasOwnProperty(name) ? errors[name].message : ""}
      </FormHelperText>
    </FormControl>
  );
};

export default ResearchSelect;
