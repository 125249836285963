const SystemNotificationTypeEnum = Object.freeze({
  Informational: 1,
  Caution: 2,
});

const SystemNotificationTypeEnumMapLV = new Map([
  [SystemNotificationTypeEnum.Informational, "Informatīvs paziņojums"],
  [SystemNotificationTypeEnum.Caution, "Brīdinošs paziņojums"],
]);

export { SystemNotificationTypeEnum, SystemNotificationTypeEnumMapLV };
