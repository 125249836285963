import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { SystemNotificationTypeEnum, SystemNotificationTypeEnumMapLV } from "../enums";

const SysNotificationTypeDropdown = ({
  name,
  label,
  defaultValue,
  required,
}) => {
  const {
    formState: { errors },
    setValue,
    register,
    clearErrors
  } = useFormContext({ [name]: "" });

  const [valueOption, setOptionValue] = useState(null);
  const [options, setOptions] = useState([]);

  const handleChange = (event, optionValue) => {
    if (!optionValue) return;
    setOptionValue(optionValue);
    setValue(name, optionValue.id);
    clearErrors(name);
  };


  useEffect(() => {
    const notificationTypes = Object.values(SystemNotificationTypeEnum).map(value => {
         return {
           id: value,
           label: SystemNotificationTypeEnumMapLV.get(value)
         }
       });

    setOptions(notificationTypes);
    if (defaultValue) {
      const defaultOption = notificationTypes.find((x) => x.id === defaultValue);
      setOptionValue(defaultOption);
      setValue(name, defaultValue);
    }
    register(name, {
      required: required ? "Lauks ir obligāts" : false,
    });
  }, []);

  return (
    <>
      <Autocomplete
        disablePortal
        id="sys-notification-types-combo-box"
        size="small"
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label || ""}
        defaultValue={valueOption}
        options={options}
        value={valueOption}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={errors[name] ? true : false}
          />
        )}
      />
      <FormHelperText error>
        {errors[name] ? errors[name].message : ""}
      </FormHelperText>
    </>
  );
};

export default SysNotificationTypeDropdown;
