export const INCREASE_FONT = "[font] font increase";
export const DECREASE_FONT = "[font] font decrease";
export const SET_STEP = "[font] set step";

export const setStep = (payload) => ({
  type: SET_STEP,
  payload: payload,
});

export const increaesFont = (fonts) => ({
  type: INCREASE_FONT,
  payload: fonts,
});

export const decreaseFont = (fonts) => ({
  type: DECREASE_FONT,
  payload: fonts,
});
