import DateAdapter from "@date-io/moment";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LocalizationProvider } from "@mui/lab";
import { PrivateRoute } from "./PrivateRote";
import { HomePrivateRoute } from "./HomePrivateRoute";
import { PrivateRoleRoute } from "./PrivateRoleRoute";
import * as Components from "./components";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "./theme";
import {
  About,
  AdminSettings,
  TermsOfUse,
  UsersDashboardView,
  Moreinformation,
  PersonAdd,
  PersonEditView,
  PersonList,
  FAQ,
  LatvijaLv,
  Notifications,
  ResearchAdd,
  ResearchView,
  ResearchDetail,
  ParticipantAdd,
  ClassifierDetail,
  ClassifierList,
  PublicResearchView,
  Role,
  PasswordRefresh,
  PasswordReset,
  PasswordSet,
  UserCreate,
  UserEdit,
  Home,
  UserProfile,
  UserProfileEdit,
  SysNotificationAddView,
  SysNotificationEditView,
  PermissionListView,
  ConsentVersions,
  ContentManagement,
  ConsentResearchAdd,
  ConsentBiobankAdd,
  PermissionUpsertView,
  ConsentFillFormView,
  ParticipantResearchTabs,
  UsefulLinksCms,
  ContactsCms,
  SystemManagerCms,
  TermsOfUseCms,
  TermsOfUseCmsList,
  SysNotificationsView,
  TranslationsView,
  BiobankCms,
  TermsOfUseCmsAdd,
  Contacts,
  FAQCms,
  FAQCmsList,
  ReferralList,
  ReferralEdit,
  ReferralAdd,
  ReferralView,
  PublicResearchDetailsView,
  QuestionView,
  SurveyView,
  ParticipantSurveyView,
  SurveyAdminUpsertView,
  ConsentUpsertView,
  BiobankHomeView,
  SurveyQuestionUpsertView,
  UserSubmittedConsentsView
} from "./pages";
//import ConsentForm from "./pages/research/InformedConsent/ConsentForm";
//currently unsure about naming of those components as business requriements aren't exactly specified
import ConsentFilledBiobank from "./pages/consent/ConsentFilledBiobank";
import ConsentBiobankFilling from "./pages/consent/ConsentBiobankFilling";
import ConsentResearchNurseFill from "./pages/consent/ConsentResearchNurseFill";
import { userAccessToken } from "./redux/selectors/authSelector";
import { RefreshAccessToken } from "./api/auth/auth";
import "./style/App.scss";
import { MoreInformationCmsAddGroup } from "./pages/cms/moreInformation/AddGroup";
import HomeCms from "./pages/cms/home/HomeCms";
import AboutUsCms from "./pages/cms/aboutUs/AboutUsCms";
import { MoreInformationCmsDraggableList } from "./pages/cms/moreInformation/DraggableList";
import { MoreInformationAddGroupItem } from "./pages/cms/moreInformation/AddGroupItem";
import { MoreInformationEditGroupItem } from "./pages/cms/moreInformation/EditGroupItem";
import { MoreInformationCmsEditGroup } from "./pages/cms/moreInformation/EditGroup";
import { MoreInformationCms } from "./pages/cms/moreInformation/MoreInformationCms";
import FAQAddGroup from "./pages/cms/faq/AddGroup";
import FAQAddGroupItem from "./pages/cms/faq/AddGroupItem";
import FAQCmsEditGroup from "./pages/cms/faq/EditGroup";
import FAQEditGroupItem from "./pages/cms/faq/EditGroupItem";
import { SelectedRowProvider } from './components/SelectedRowProvider';

const restrictedComponentsForTokenRefresh = [
  "/",
  "/latvijalv",
  "/resetpassword",
  "/setPassword",
  "/role",
  "/moreinformation",
  "/contacts",
  "/participants/add",
];

const App = () => {
  const { customTheme } = useTheme();
  //Cannot be used since useNavigate can be used only within Router Context
  //const { token, RefreshAccessToken } = useToken();

  const token = useSelector(userAccessToken);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const allowTokenRefresh = !restrictedComponentsForTokenRefresh.includes(
      window.location.pathname
    );
    if (allowTokenRefresh && token === "") dispatch(RefreshAccessToken());
  }, [dispatch, token]);

  return (
    <SelectedRowProvider>
      <ThemeProvider theme={customTheme}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <BrowserRouter>
            <Routes>
              <Route element={<Components.Layout />}>
                <Route index element={<HomePrivateRoute><Home /></HomePrivateRoute>} />
                <Route path="/about" element={<About />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/moreinformation" element={<Moreinformation />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/termsofuse" element={<TermsOfUse />} />
                <Route path={`/public/research`} element={<PublicResearchView />} />

                <Route path="/consent/consent-fill" element={<ConsentFillFormView />} />
                <Route path="/consent/edit/:consentId" element={<ConsentUpsertView />} />
                <Route path="/consent/consent-create" element={<ConsentUpsertView />} />
                <Route path="/consent/consent-create/:consentId" element={<ConsentUpsertView />} />
                <Route path="/consent/informed-consent-fill" element={<ConsentFillFormView />}/>
                <Route path="/consent/user-submitted-consents" element={<UserSubmittedConsentsView />}/>
               
                <Route path="/permission/list" element={<PermissionListView />} />
                <Route path="/permission/create" element={<PermissionUpsertView />} />
                <Route path="/permission/edit/:id" element={<PermissionUpsertView />} />
                <Route path="/participant/research/details/:researchId" element={<ParticipantResearchTabs />} />
                <Route path="/public/research/details/:researchId" element={<PublicResearchDetailsView />} />
                <Route path="/public/research/details/:researchId/:personId" element={<PublicResearchDetailsView />} />
                <Route path="/biobank" element={<BiobankHomeView />} />
                <Route path="/biobank/biobank-consent-create" element={<ConsentUpsertView />} />

                <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<UsersDashboardView />} />
                  <Route path="/userprofile/edit" element={<UserProfileEdit />} />
                  <Route path="/userprofile" element={<UserProfile />} />
                  <Route path="/users/:userId" element={<UserEdit />} />
                  <Route path="/referral/list" element={<ReferralList />} />
                  <Route path="/referral/add" element={<ReferralAdd />} />
                  <Route path="/referral/edit/:referralId" element={<ReferralEdit />} />
                  <Route path="/referral/view/:referralId" element={<ReferralView />} />
                  <Route path="/persons/list" element={<PersonList />} />
                  <Route path="/persons/add" element={<PersonAdd />} />
                  <Route path="/persons/edit/:personId" element={<PersonEditView />} />
                  <Route path="/classifier/:name" element={<ClassifierDetail />} />
                  <Route path="/classifiers" element={<ClassifierList />} />
                  <Route path="/changerole" element={<Role />} />
                  {/*     Admin view      */}
                  <Route path="/research/list" element={<ResearchView />} />
                  <Route path="/research/edit/:researchId" element={<ResearchAdd />} />
                  <Route path="/research/details/:researchId" element={<ResearchDetail />} />
                  <Route path="/research/consents/:researchId" />
                  <Route path="/research/add/" element={<ResearchAdd />} />
                  <Route path="/notifications" element={<Notifications />} />
                  <Route path="/notifications/read/:id" element={<Notifications />} />
                  <Route path="/notifications/receivedMessages" element={<Notifications />} />
                  <Route path="/notifications/sentMessages" element={<Notifications />} />
                  <Route path="/notifications/archivedMessages" element={<Notifications />} />
                  <Route path="/notifications/newMessage" element={<Notifications />} />
                  <Route path="/contentmanagement" element={<ContentManagement />} />
                  <Route path="/contentmanagement/sysnotification/add" element={<SysNotificationAddView />} />
                  <Route path="/contentmanagement/sysnotification/edit/:id" element={<SysNotificationEditView />} />
                  <Route path="/consent/research/:researchId/add" element={<ConsentResearchAdd />} />
                  <Route path="/consent/biobank/add" element={<ConsentBiobankAdd />} />
                  <Route path="/consent/versions" element={<ConsentVersions />} />
                  <Route path="/consent/biobank/fill" element={<ConsentBiobankFilling />} />
                  <Route path="/consent/biobank/filled/:id" element={<ConsentFilledBiobank />} />
                  <Route path="/consent/research/nurse" element={<ConsentResearchNurseFill />} />
                  <Route path="/question/question-list" element={< QuestionView />} />
                  <Route path="/survey/survey-list" element={< SurveyView />} />
                  <Route path="/survey/my-surveys" element={< ParticipantSurveyView />} />
                  <Route path="/survey/admin-add" element={< SurveyAdminUpsertView />} />
                  <Route path="/survey/question-add" element={< SurveyQuestionUpsertView />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/admin/settings" element={<AdminSettings />} />
                  <Route path="/admin/userlist" element={<AdminSettings />} />
                  <Route path="/admin/rightlist" element={<AdminSettings />} />
                  <Route path="/admin/grouplist" element={<AdminSettings />} />
                  <Route path="/admin/grouprightlist" element={<AdminSettings />} />
                  <Route path="/admin/auditlist" element={<AdminSettings />} />
                  <Route path="/admin/usercreate" element={<UserCreate />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route element={<ContentManagement />}>
                    <Route path="/cms/home" element={<HomeCms />} />
                    <Route path="/cms/about" element={<AboutUsCms />} />
                    <Route element={<MoreInformationCms />}>
                      <Route path="/cms/moreinformation" element={<MoreInformationCmsDraggableList />} />
                      <Route path="/cms/moreinformation/group/add" element={<MoreInformationCmsAddGroup />} />
                      <Route path="/cms/moreinformation/group/:groupId/item/add" element={<MoreInformationAddGroupItem />} />
                      <Route path="/cms/moreinformation/group/:groupId/edit" element={<MoreInformationCmsEditGroup />} />
                      <Route path="/cms/moreinformation/group/:groupId/item/:itemId/edit" element={<MoreInformationEditGroupItem />} />
                    </Route>
                    <Route element={<FAQCms />}>
                      <Route path="/cms/faq" element={<FAQCmsList />} />
                      <Route path="/cms/faq/group/add" element={<FAQAddGroup />} />
                      <Route path="/cms/faq/group/:groupId/item/add" element={<FAQAddGroupItem />} />
                      <Route path="/cms/faq/group/:groupId/edit" element={<FAQCmsEditGroup />} />
                      <Route path="/cms/faq/group/:groupId/item/:itemId/edit" element={<FAQEditGroupItem />} />
                    </Route>
                    <Route path="/cms/contacts" element={<ContactsCms />} />
                    <Route path="/cms/faq" element={<FAQCms />} />
                    <Route element={<TermsOfUseCms />}>
                      <Route path="/cms/termsofuse" element={<TermsOfUseCmsList />} />
                      <Route path="/cms/termsofuse/add" element={<TermsOfUseCmsAdd />} />
                    </Route>
                    <Route path="/cms/usefullinks" element={<UsefulLinksCms />} />
                    <Route path="/cms/notifications" element={<SysNotificationsView />} />
                    <Route path="/cms/translations" element={<TranslationsView />} />
                    <Route path="/cms/biobankconsent" element={<BiobankCms />} />
                    <Route path="/cms/systemmanager" element={<SystemManagerCms />} />
                  </Route>
                </Route>
              </Route>
              <Route element={<Components.ProfileLayout />}>
                <Route path="/role" element={<PrivateRoleRoute><Role /></PrivateRoleRoute>} />
                <Route path="/participants/add" element={<ParticipantAdd />} />
                <Route path="/participants/add/:personId" element={<ParticipantAdd />} />
                <Route path="/refreshpassword" element={<PasswordRefresh />} />
                <Route path="/resetpassword" element={<PasswordReset />} />
                <Route path="/setpassword" element={<PasswordSet />} />
                <Route path="latvijalv" element={<LatvijaLv />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </SelectedRowProvider>
  );
};

export default App;
