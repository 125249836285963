import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import useRole from "../../hooks/useRole";

export const DisplayUsernameAndRole = () => {
  const fullName = sessionStorage.getItem("username");

  const { role } = useRole();

  return (
    <>
      <Box display="flex">
        <Typography variant="h6">{fullName}</Typography>
        <Divider
          light
          variant="fullWidth"
          flexItem
          sx={{ margin: "0 .2rem", borderColor: "#103A5D" }}
          orientation="vertical"
        />
        <Typography color="#103A5D" variant="h6">
          {role?.name}
        </Typography>
      </Box>
    </>
  );
};
