import {
  LOAD_LATVIJALV_CRED,
  LOAD_PARTICIPANT,
  UNLOAD_LATVIJALV_CRED,
  UNLOAD_PARTICIPANT,
} from "../actions/participant";

const latvijaLvDefaultState = {
  name: "",
  surname: "",
  personCode: "",
}

const initialState = {
  participant: {},
  latvijaLvCred: latvijaLvDefaultState,
  error: null,
};

export const Participant = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARTICIPANT:
      return { ...initialState, participant: action.payload };
    case UNLOAD_PARTICIPANT:
      return { ...initialState, participant: {} };
    case LOAD_LATVIJALV_CRED:
      return { ...state, latvijaLvCred: action.payload };
    case UNLOAD_LATVIJALV_CRED:
      return { ...state, latvijaLvCred: { personId: "", name: "", surname: "", personCode: "" } };
    default:
      return state;
  }
};
