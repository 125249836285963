import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Grow from "@mui/material/Grow";
import React from "react";
import ControlledTextEditorTinyMce from "../../../components/richTextEditor/ControlledTextEditorTinyMce";
import { TextInputField } from "../../../MUI_components";
import { HomeImageUpload } from "./HomeImageUpload";
import { useFormContext } from "react-hook-form";

export const HomeCmsCard = ({
  id,
  count,
  lang,
  handleDelete,
  defaultImageSrc,
  defaultTitle,
  defaultContent,
  imageUpload
}) => {

  //console.log(count, title, content, lang);

  const { register } = useFormContext();

  const handleDeleteCard = () => {
    return handleDelete(id, count);
  }

  return (
    <Grow in={true} exit={true}>
      <Grid container direction={{ xs: "column", md: "row" }}>
        <Grid container my={2}>

          <input {...register(`${lang}.${count}.id`, { shouldUnregister: false, value: id || null })} defaultValue={id || null} hidden />

          <input {...register(`${lang}.${count}.isDeleted`, { setValueAs: v => Boolean(v), value: false, shouldUnregister: false })} defaultValue={false} hidden />

          <Grid item xs={12} md={4} lg={3} xl={2}>
            <HomeImageUpload id={id} lang={lang} count={count} name={"imageLink"} defaultSrc={defaultImageSrc} imageUpload={imageUpload} />
          </Grid>
          <Grid item md={7} lg={6} xs={10}>
            <Grid>
              <TextInputField
                name={`${lang}.${count}.title`}
                shouldUnregister={false}
                label={"Virsraksts"}
                maxCharLength={50}
                isRequired={true}
                defaultValue={defaultTitle}
              />
            </Grid>
            <Grid pb={2}>
              <Grid mt={2}>
                <InputLabel htmlFor={`${lang}.${count}.content`}>
                  Apraksts *
                </InputLabel>
              </Grid>
              <ControlledTextEditorTinyMce
                hasImageUpload={false}
                defaultValue={defaultContent}
                required={true}
                name={`${lang}.${count}.content`}
                shouldUnregister={false}
                maxCharLen={500}
              />
            </Grid>
          </Grid>
          {count > 0 && (
            <Grid
              item
              my={1}
              md={1}
              xs={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              <DeleteOutline onClick={handleDeleteCard} />
            </Grid>
          )}
        </Grid>
        <Grid item py={2} xs={11} lg={9} xl={8}>
          <Divider flexItem />
        </Grid>
      </Grid>
    </Grow>
  );
};
