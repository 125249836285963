import { SET_LOADING_OFF, SET_LOADING_ON } from "../actions/persons";

const initialState = {
  isLoading: false,
};

export const LoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ON:
    case SET_LOADING_OFF:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
