import { Button } from "@mui/material";

const BaseButton = ({ children, ...rest }) => {
  return (
    <Button {...rest} sx={{ height: "40px", maxHeight: "40px" }}>
      {children}
    </Button>
  );
};

export default BaseButton;
