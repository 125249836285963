import {Button, CircularProgress }from "@mui/material";


const ButtonWithLoading = ({ children, loading, ...rest }) => {
  return (
    <Button {...rest}
    sx={{height: "40px", maxHeight: "40px"}}>
      {children}
      {loading && (
        <CircularProgress
          size={30}
          color="secondary"
          style={{ position: "absolute" }}
        />
      )}
    </Button>
  );
};

export default ButtonWithLoading;