export const ResearchParticipantTypeEnum = Object.freeze({
  None: "NONE",
  All: "ALL",
  Others: "OTHERRES",
  List: "LIST",
});

export const ResearchParticipantTypeMapLV = new Map([
  [ResearchParticipantTypeEnum.None, "Nevar pieteikties"],
  [ResearchParticipantTypeEnum.All, "Visi dalībnieki"],
  [ResearchParticipantTypeEnum.Others, "Cita pētījuma dalībnieki"],
  [ResearchParticipantTypeEnum.List, "Sarakstā norādītie dalībnieki"],
]);