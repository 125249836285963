export const LOAD_PARTICIPANT = "[participant] load";
export const UNLOAD_PARTICIPANT = "[participant] unload";
export const POST_FAILURE = "[participant] failure at posting";
export const LOAD_LATVIJALV_CRED = "[participant] load latvijaLv data";
export const UNLOAD_LATVIJALV_CRED = "[participant] unload latvijaLv data";

export const loadParticipant = (participant) => ({
  type: LOAD_PARTICIPANT,
  payload: participant,
});

export const unloadParticipant = () => ({
  type: UNLOAD_PARTICIPANT,
});

export const setParticipantFailure = (error) => ({
  type: POST_FAILURE,
  payload: error,
});

export const loadLatvijaLvCredentials = (data) => ({
  type: LOAD_LATVIJALV_CRED,
  payload: data,
});

export const unloadLatvijaLvCredentials = () => ({
  type: UNLOAD_LATVIJALV_CRED,
});
