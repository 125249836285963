const ClSurveyStatusEnum = Object.freeze({
  Draft: 1,
  SubmittedForPublication: 2,
  Published: 3,
  Open: 4,
  Closed: 5,
});

const ClSurveyStatusMapLV = new Map([
  [ClSurveyStatusEnum.Draft, "Sagatave"],
  [ClSurveyStatusEnum.SubmittedForPublication, "Nosūtīta publicēšanai"],
  [ClSurveyStatusEnum.Published, "Publicēta"],
  [ClSurveyStatusEnum.Open, "Atvērta"],
  [ClSurveyStatusEnum.Closed, "Slēgta"],
]);

export { ClSurveyStatusEnum, ClSurveyStatusMapLV };