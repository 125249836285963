import CONSTANTS from "../constants";

const serviceUtil = {
  isObject: (valueToTest) => {
    return typeof valueToTest === "object" && valueToTest && !Array.isArray(valueToTest);
  },
  assignPageSizeAndPageNumberIfNotPresent: (queryParams) => {
    if (!("pageNumber" in queryParams)) queryParams["pageNumber"] = CONSTANTS.GRID.DEFAULT_PAGE;
    if (!("pageSize" in queryParams)) queryParams["pageSize"] = CONSTANTS.GRID.DEFAULT_PAGE_SIZE;

    return queryParams;
  },
};

export default serviceUtil;
