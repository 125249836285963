import {
  LOGIN_FAILED_PASSWORD_EXPIRED,
  LOGIN_LOADING_OFF,
  LOGIN_LOADING_ON,
  LOGIN_SUCCESS_SET_ROLE,
  SET_AUTHENTICATED,
  SET_AUTH_LOGGED_IN,
  SET_SINGLE_USER_ROLE,
  SET_USERNAME,
  UNLOAD_PERSON_DATA,
  LOGIN_FAILURE,
  UNLOAD_LOGIN_ERORS,
  SET_ACCESS_TOKEN,
  SET_ACCESS_RIGHTS,
  SET_TOKEN_LIFETIME,
  SET_ASSIGNED_GROUPS_CODE,
  SET_LOGOUT_DIALOG_OPEN,
} from "../actions/auth";

const initialState = {
  userId: "",
  isLoading: false,
  isPasswordExpired: false,
  isConsentGiven: false,
  roles: [],
  assignedGroupsCode: [],
  role: { code: "PART", name: "Participant" },
  roleName: "Participant",
  username: "",
  isAuthenticated: false,
  error: null,
  accessToken: "",
  accessRights: [],
  tokenLifeTime: "",
  logoutDialogOpen: false
};

export const Auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING_ON:
    case LOGIN_LOADING_OFF:
      return { ...state, isLoading: action.payload };
    case SET_LOGOUT_DIALOG_OPEN:
        return { ...state, logoutDialogOpen: action.payload };
    case LOGIN_SUCCESS_SET_ROLE:
      return { ...state, roles: action.payload };
    case SET_AUTH_LOGGED_IN:
      return { ...state, user: action.payload };
    case UNLOAD_PERSON_DATA:
      return { ...state, ...action.payload };
    case LOGIN_FAILED_PASSWORD_EXPIRED:
      return { ...state, isPasswordExpired: action.payload };
    case SET_SINGLE_USER_ROLE:
      return { ...state, role: action.payload };
    case SET_USERNAME:
      return { ...state, username: action.payload };
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload };
    case LOGIN_FAILURE:
      return { ...state, error: action.payload };
    case UNLOAD_LOGIN_ERORS:
      return { ...state, error: null };
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SET_ACCESS_RIGHTS:
      return { ...state, accessRights: action.payload };
    case SET_TOKEN_LIFETIME:
      return { ...state, tokenLifeTime: action.payload };
    case SET_ASSIGNED_GROUPS_CODE:
      return { ...state, assignedGroupsCode: action.payload };
    default:
      return state;
  }
};
