const ResearchReferralParticipantValidationMessageEnum = Object.freeze({
  Valid: 0,
  ResearchNotFound: 1,
  PersonNotFound: 2,
  NotAValidResearchParticipant: 3,
  AlreadyInResearchReferral: 4,
  OtherError: 5
});

const ResearchReferralParticipantValidationMessageMapLV = new Map([
  [ResearchReferralParticipantValidationMessageEnum.Valid, "Dalībnieks ir derīgs"],
  [ResearchReferralParticipantValidationMessageEnum.ResearchNotFound, "Pētījums nav atrasts"],
  [ResearchReferralParticipantValidationMessageEnum.PersonNotFound, "Persona nav atrasta DECIDE sistēmā."],
  [ResearchReferralParticipantValidationMessageEnum.NotAValidResearchParticipant, "Šī persona nav dalībnieks konkrētajā pētījumā."],
  [ResearchReferralParticipantValidationMessageEnum.AlreadyInResearchReferral, "Dalībnieks jau ir pētījuma norīkojumā"],
  [ResearchReferralParticipantValidationMessageEnum.OtherError, "Nezināma kļūda"]
]);

export {
  ResearchReferralParticipantValidationMessageEnum,
  ResearchReferralParticipantValidationMessageMapLV
};
