import { AppBar } from "@mui/material";
import { HeaderDown } from "./headerDown";
import { HeaderTop } from "./headerTop";

const Header = () => {
  return (
    <>
      <AppBar position="sticky" color="transparent" elevation={0}>
        <HeaderTop />
        <HeaderDown />
      </AppBar>
    </>
  );
};

export default Header;
