import React, { useEffect, useState } from "react";
import BaseButton from "./buttons/BaseButton";
import Grid from "@mui/material/Grid";
import { get, useFormContext } from "react-hook-form";
import { IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { v4 } from "uuid";
import FormHelperText from "@mui/material/FormHelperText";
import useFileDownloader from "../hooks/useFileDownloader";
import fileValidators from "../validators/file-validators";

const ResearchFileUploadInput = ({
    name,
    defaultFiles = [],
    buttonLabel = "Pievienot pielikumu",
}) => {
    const {
        setValue,
        setError,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const [files, setFiles] = useState([]);

    const { downloadFile } = useFileDownloader();

    const error = get(errors, name);

    const handleOnChange = (event) => {
        if (event.target.files.length === 0) return;

        const validationResponse = fileValidators.validate.maxFileSize(event.target.files[0]);

        if (validationResponse !== true) {
            setError(name, { type: "manual", message: validationResponse });
            return;
        } else {
            clearErrors(name);
        }

        const newFile = { id: v4(), file: event.target.files[0] };
        setFiles((prevState) => [...prevState, newFile]);
        setValue(name, [...files.map((item) => item.file), event.target.files[0]], {
            shouldTouch: true,
        });
    };

    //this event handler is necessary to handle same file not getting selected issue
    //https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
    const handleClick = (event) => {
        event.target.value = null;
    };

    const handleRemove = (index) => {
        const filteredFiles = files.filter((_, idx) => idx !== index);

        setFiles(filteredFiles);
        setValue(
            name,
            filteredFiles.map((item) => item.file),
            { shouldTouch: true }
        );
        setValue(
            "attachmentIds",
            filteredFiles.length ? filteredFiles.map((item) => item.id) : [],
            { shouldTouch: true }
        );
    };

    useEffect(() => {
        if (defaultFiles?.length) {
            const mappedFiles = defaultFiles.map((item) => {
                if (item instanceof File) {
                    return {
                        id: v4(),
                        file: { name: item.name, id: v4() },
                    };
                } else {
                    return {
                        id: item.id,
                        file: {
                            name: item.fileName || item.name,
                            id: item.fileId || item.id,
                        }, // On submit the name and id values arrive in the 2nd way
                    };
                }
            });
            setFiles(mappedFiles);
            setValue(
                name,
                mappedFiles.map((item) => item.file),
                { shouldTouch: true }
            );
        }
    }, [defaultFiles, setValue, name]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <BaseButton color="inherit" variant="contained" component={"label"}>
                    <input
                        type="file"
                        hidden
                        onChange={handleOnChange}
                        onClick={handleClick}
                    />
                    {buttonLabel}
                </BaseButton>
                {error?.message && (
                    <FormHelperText error>{error.message}</FormHelperText>
                )}
            </Grid>
            <Grid item xs={12} display={"flex"} gap={2} my={1}>
                {files?.map((item, index) => (
                    <div key={item.id}>
                        <Grid display={"flex"}>
                            <BaseButton
                                variant="text"
                                onClick={() => {
                                    if (item.file instanceof File || item.file.type) {
                                        downloadFile(item.file, item.file.name);
                                    } else {
                                        downloadFile(item.file.id, item.file.name);
                                    }
                                }}
                            >
                                {item.file.name}
                            </BaseButton>
                            <IconButton onClick={() => handleRemove(index)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    </div>
                ))}
            </Grid>
        </Grid>
    );
};

export default ResearchFileUploadInput;
