import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const DoubleDivider = ({ variant, ...props }) => {
  return (
    <Box {...props}>
      <Divider
        variant={variant ? variant : "middle"}
        sx={{ marginBottom: ".5rem" }}
      />
      <Divider variant={variant ? variant : "middle"} />
    </Box>
  );
};

export default DoubleDivider;
