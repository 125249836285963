import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Divider, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import History from "@mui/icons-material/History";

const ConsentBlock = ({ consent }) => {
  const {
    text: { dark_color, link_color },
  } = useTheme();

  const navigate = useNavigate();

  const handleClickViewConsentVersions = () => {
    navigate("/consent/versions?isLatest=true");
  };

  return (
    <>
      <Grid container component={"Box"} boxShadow={3} p={3}>
        <Grid
          container
          display={"flex"}
          justifyContent="space-between"
          item
          xs={12}
        >
          <Grid item xs={8}>
            <Typography variant="h4">{consent.title}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} my={2}>
          <Divider flexItem />
        </Grid>
        <Grid direction={"column"} xs={3} item display={"flex"} gap="1rem">
          <Typography color={dark_color} variant="h5">
            Datums -
          </Typography>
          <Typography color={dark_color} variant="h5">
            Aizpildītājs -
          </Typography>
          <Typography color={dark_color} variant="h5">
            Versija -
          </Typography>
        </Grid>
        <Grid item xs={9} display="flex" justifyContent={"flex-end"}>
          <Grid>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleClickViewConsentVersions}
            >
              Apskatīt
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          my={2}
          alignItems={"center"}
          gap=".5rem"
          display="flex"
        >
          <IconButton>
            <History color="primary" />
          </IconButton>
          <Link
            color={link_color}
            component={RouterLink}
            to={"/consent/versions"}
          >
            Iesniegto piekrišanu vēsture
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default ConsentBlock;
