export const SET_FIRST_STAGE = "SET_FIRST_STAGE";
export const SET_FIRST_STAGE_ERROR = "SET_FIRST_STAGE_ERROR";
export const SET_SECOND_STAGE = "SET_SECOND_STAGE";
export const SET_THIRD_STAGE = "SET_THIRD_STAGE";
export const SET_FOURTH_STAGE = "SET_FOURTH_STAGE";
export const SET_CURRENT_STAGE = "SET_CURRENT_STAGE";
export const SET_RESEARCH_ID = "SET_RESEARCH_ID";
export const UNSET_RESEARCH_STAGES = "UNSET_RESEARCH_STAGES";
export const SET_SUBMIT_SUCCESSFUL = "SET_SUBMIT_SUCCESSFUL";
export const RESET_SUBMIT_SUCCESSFUL = "RESET_SUBMIT_SUCCESSFUL";
export const SET_PUBLIC_RESEARCH_TO_NAVIGATE_TO = "SET_PUBLIC_RESEARCH_TO_NAVIGATE_TO";
export const SET_RESEARCH_MODE = "SET_RESEARCH_MODE";



export const unsetResearchStages = () => ({
  type: UNSET_RESEARCH_STAGES,
});

export const setResearchId = (payload) => ({
  type: SET_RESEARCH_ID,
  payload: payload,
});

export const setSubmitSuccessful = (payload) => ({
  type: SET_SUBMIT_SUCCESSFUL,
  payload: payload,
});

export const resetSubmitSuccessful = (payload) => ({
  type: RESET_SUBMIT_SUCCESSFUL,
  payload: payload
})

export const setFirstStage = (payload) => ({
  type: SET_FIRST_STAGE,
  payload: payload,
});

export const setFirstStageError = (payload) => ({
  type: SET_FIRST_STAGE_ERROR,
  payload: payload
});

export const setSecondStage = (payload) => ({
  type: SET_SECOND_STAGE,
  payload: payload,
});

export const setThirdStage = (payload) => ({
  type: SET_THIRD_STAGE,
  payload: payload,
});

export const setFourthStage = (payload) => ({
  type: SET_FOURTH_STAGE,
  payload: payload,
});

export const setCurrentStage = (payload) => ({
  type: SET_CURRENT_STAGE,
  payload: payload,
});

export const setPublicReserachToNavigateTo = (publicResearch) => ({
  type: SET_PUBLIC_RESEARCH_TO_NAVIGATE_TO,
  payload: publicResearch
});

export const setResearchMode = (isAddMode) => ({
  type: SET_RESEARCH_MODE,
  payload: isAddMode,
});
