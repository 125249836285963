const ConsentResponsesTypeEnum = Object.freeze({
  NoOption: "NO_OPTION",
  MultipleOptions: "MULTIPLE_OPTIONS",
  OneResponse: "ONE_RESPONSE",
});

const ConsentResponseTypeMapLV = new Map([
  [ConsentResponsesTypeEnum.NoOption, "Bez atbildes"],
  [ConsentResponsesTypeEnum.MultipleOptions, "Vairākas atbildes"],
  [ConsentResponsesTypeEnum.OneResponse, "Viena atbilde"],
]);

export { ConsentResponsesTypeEnum, ConsentResponseTypeMapLV };