import http from "../api/http";

const messageService = {
  createMessage: async (formData) => {
    const response = await http.backendInstance.post("/api/message", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  },
  getMessageByReceiversUsersId: async (messageReceiversUsersId, signal) => {
    const response = await http.backendInstance.get(
      `/api/message/${messageReceiversUsersId}`,
      { signal }
    );

    return response.data;
  },
  getMessagesByStatus: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/message", {
      params: { ...queryParams },
      signal,
    });

    return response.data;
  },
  modifyMessageArchiveStatus: async (messagesToModify, signal) => {
    const response = await http.backendInstance.put(
      "/api/message/archive-status",
      { ...messagesToModify },
      signal
    );

    return response.data;
  },
  getUnreadMessageCount: async (signal) => {
    const response = await http.backendInstance.get(
      "/api/message/unread-message-count",
      {
        signal,
      }
    );

    return response.data;
  },
  getUnreadMessages: async (queryParams, signal) => {
    const response = await http.backendInstance.get("/api/message/unread-messages", {
      params: { ...queryParams },
      signal,
    });

    return response.data;
  },
  markMessageAsRead: async (messageId, signal) => {
    const response = await http.backendInstance.put(
      `/api/message/mark-as-read/${messageId}`,
      signal
    );

    return response.data;
  },
};

export default messageService;
