import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import React from "react";
import { useLocation } from "react-router-dom";

const DropdownMenu = ({
  menuName,
  ariaControls,
  ariaLabelledBy,
  id,
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { pathname } = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        className={"dropdown-link"}
        style={{ marginLeft: "2rem", position: "relative" }}
      >
        <Link
          className={pathname.indexOf(id) !== -1 ? "dropdown-link--active" : ""}
          sx={{
            textDecoration: "none",
            color: "black",
            textTransform: "uppercase",
          }}
          aria-controls={ariaControls}
          aria-expanded={open ? true : false}
          id={ariaLabelledBy}
          onClick={handleClick}
        >
          {menuName}
        </Link>
      </div>
      <Menu
        id={ariaControls}
        aria-labelledby={ariaLabelledBy}
        anchorEl={anchorEl}
        sx={{ zIndex: 100000001 }}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {children({ handleClose })}
      </Menu>
    </div>
  );
};

export default DropdownMenu;
