import { DatePicker } from "@mui/lab";
import React from "react";
import moment from "moment";
import { useFormContext, Controller } from "react-hook-form";
import CONSTANTS from "../constants";
import { Box, Grid, FormHelperText, FormControl, TextField, Typography  } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const CustomDatePicker = ({
  title = "",
  label,
  name,
  defaultValue,
  required,
  rules,
  fullWidth,
  disablePast = false,
  ...props
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const theme = useTheme();

  const primaryTextColor = theme.palette.text.primary;
  const errorColor = theme.palette.error.main;

  const defaultMinDate = moment(CONSTANTS.MIN_DATE);

  return (
    <>
      <FormControl size="small" fullWidth={fullWidth ? fullWidth : false} {...props}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rules={{ required: required ? "Lauks ir obligāts" : false, ...rules }}
          render={({ field: { onBlur, onChange, value } }) => (
            <Box>
              <Grid
                container
                direction="column"
              >
                {title &&
                  <Grid item >
                    <Typography sx={{ color: errors[name] ? errorColor : primaryTextColor }}>{title}</Typography>
                  </Grid>
                }
                <Grid item xs={12} xl={4}>
                  <DatePicker
                    disablePast = {disablePast ? disablePast : false}
                    label={label}
                    minDate={defaultMinDate}
                    onChange={(e) => {
                        onChange(e);
                        onBlur(e);
                    }}
                    mask={"__.__.____"}
                    value={value}
                    inputFormat={CONSTANTS.DATE_FORMAT}
                    {...props}
                    renderInput={(params) => (
                      <TextField
                        fullWidth={fullWidth ? fullWidth : false}
                        size="small"
                        {...params}
                        error={errors[name] ? true : false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        />
        <FormHelperText error>
          {errors[name] ? errors[name].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default CustomDatePicker;
