import { lazy } from "react";
//Route-based code-splitting
export const About = lazy(() => import("./about/about"));
export const AdminSettings = lazy(() => import("./adminPanel/AdminSettingsTabPanel"));
export const TermsOfUse = lazy(() => import("./termsOfUse/Termsofuse"));
export const UsersDashboardView = lazy(() => import("./dashboard/UsersDashboardView"));
export const Moreinformation = lazy(() => import("./moreinfo/moreinfo"));
export const FAQ = lazy(() => import("./faq/FAQ"));
export const LatvijaLv = lazy(() => import("./latvijaLv/LatvijaLv"));
export const PersonAdd = lazy(() => import("./persons/personAdd"));
export const PersonEditView = lazy(() => import("./persons/PersonEditView"));
export const PersonList = lazy(() => import("./persons/personList"));
export const Notifications = lazy(() => import("./notifications/NotificationTabs"));
export const Home = lazy(() => import("./home/homeView"));
export const ResearchView = lazy(() => import("./research/adminView/ResearchView/ResearchView"));
export const ResearchAdd = lazy(() => import("./research/form/AddResearch"));
export const ResearchDetail = lazy(() => import("./research/adminView/ResearchTabs"));
export const ParticipantAdd = lazy(() => import("./participant/participantAdd"));
export const ClassifierDetail = lazy(() => import("./classifier/Classifier"));
export const ClassifierList = lazy(() => import("./classifier/ClassifierList"));
export const PublicResearchView = lazy(() => import("./research/participantView/PublicResearchView"));
export const Contacts = lazy(() => import("./contacts/Contacts"));
export const Role = lazy(() => import("./role/role"));
export const PasswordRefresh = lazy(() => import("./password_reset/passwordRefresh"));
export const PasswordReset = lazy(() => import("./password_reset/passwordReset"));
export const PasswordSet = lazy(() => import("./password_reset/passwordSet"));
export const UserCreate = lazy(() => import("./adminPanel/UserCreate"));
export const UserEdit = lazy(() => import("./adminPanel/UserEdit"));
export const UserProfile = lazy(() => import("./user/userProfile"));
export const UserProfileEdit = lazy(() => import("./user/userProfileEdit"));
export const SysNotificationAddView = lazy(() => import("./notifications/SysNotificationAddView"));
export const SysNotificationEditView = lazy(() => import("./notifications/SysNotificationEditView"));
export const UsefulLinksCms = lazy(() => import("./cms/usefulLinks/usefulLinksTabs"));
export const ContentManagement = lazy(() => import("./cms/ContentManagementTabPanel"));
export const FAQCms = lazy(() => import("./cms/faq/FAQCms"));
export const FAQCmsList = lazy(() => import("./cms/faq/DraggableList"));
export const FAQCmsGroupAdd = lazy(() => import("./cms/faq/AddGroup"));
export const FAQCmsGroupEdit = lazy(() => import("./cms/faq/EditGroup"));
export const FAQCmsGroupItemAdd = lazy(() => import("./cms/faq/AddGroupItem"));
export const FAQCmsGroupItemEdit = lazy(() => import("./cms/faq/EditGroupItem"));
export const ContactsCms = lazy(() => import("./cms/contacts/ContactsCms"));
export const SystemManagerCms = lazy(() => import("./cms/systemManager/SystemManager"));
export const SysNotificationsView = lazy(() => import("./cms/SysNotificationsView"));
export const TermsOfUseCms = lazy(() => import("./cms/termsOfUse/TermsOfUse"));
export const TermsOfUseCmsList = lazy(() => import("./cms/termsOfUse/TermsOfUseCmsList"));
export const TermsOfUseCmsAdd = lazy(() => import("./cms/termsOfUse/TermsOfUseCmsAdd"));
export const TranslationsView = lazy(() => import("./cms/TranslationsView"));
export const BiobankCms = lazy(() => import("./cms/biobank/BiobankCms"));
export const PermissionListView = lazy(() => import("./permissions/PermissionListView"));
export const ConsentVersions = lazy(() => import("./research/adminView/InformedConsent/ConsentVersions"));
export const ConsentResearchAdd = lazy(() => import("./consent/ConsentResearchAdd"));
export const ConsentBiobankAdd = lazy(() => import("./consent/ConsentBiobankAdd"));
export const PermissionUpsertView = lazy(() => import("./permissions/PermissionUpsertView"));
export const ParticipantResearchTabs = lazy(() => import("./research/participantView/ResearchTabs"));
export const ReferralList = lazy(() => import("./referral/ReferralList"));
export const ReferralEdit = lazy(() => import("./referral/ReferralEdit"));
export const ReferralAdd = lazy(() => import("./referral/ReferralAdd"));
export const ReferralView = lazy(() => import("./referral/ReferralView"));
export const ReferralDetails = lazy(() => import("./referral/ReferralDetails"))
export const ConsentFillFormView = lazy(() => import("./research/adminView/new-informed-consent-view/ConsentFillFormView"));
export const PublicResearchDetailsView = lazy(() => import("./research/public-views/PublicResearchDetailsView"));
export const QuestionView = lazy(() => import("./questions/QuestionView"));
export const SurveyView = lazy(() => import("./survey/SurveyView"));
export const ResearchSurveyView = lazy(() => import("./research/ResearchSurveyView"));
export const SurveyParticipant = lazy(() => import("./research/participantView/surveyView/SurveyParticipant"));
export const SurveyAdmin = lazy(() => import("./research/adminView/surveyView/SurveyAdmin"));
export const ParticipantSurveyView = lazy(() => import("./survey/ParticipantSurveyView"));
export const ConsentUpsertView = lazy(() => import("./research/adminView/new-informed-consent-view/ConsentUpsertView"));
export const SurveyAdminUpsertView = lazy(() => import("./research/adminView/surveyView/SurveyAdminUpsertView"));
export const BiobankHomeView = lazy(() => import("./biobank/BiobankHomeView"));
export const SurveyQuestionUpsertView = lazy(() => import("./research/adminView/surveyView/SurveyQuestionUpsertView"));
export const UserSubmittedConsentsView = lazy(() => import("./research/adminView/new-informed-consent-view/UserSubmittedConsentsView"));
export const ResearchResultsView = lazy(() => import("./research/ResearchResultsView"));