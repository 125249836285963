import { Grid, Typography } from "@mui/material";

const EmptyMessageStub = ({ messageText }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item>
        <Typography>
          {messageText}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default EmptyMessageStub;