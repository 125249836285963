export const SET_ERROR = "[error] set error";
export const UNLOAD_ERROR = "[error] unload error";

export const setError = (code) => ({
  type: SET_ERROR,
  code,
});

export const unloadError = () => ({
  type: UNLOAD_ERROR,
});
