import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Snackbar, Alert, Typography } from "@mui/material";
const Toast = ({
  autoHideDurationMiliSeconds,
  showToast,
  message,
  failure,
  handleClose
}) => {
  const duration = autoHideDurationMiliSeconds || 5000;

  const handleSnackBarClose = () => {
    if (!handleClose)
      return;
    
    handleClose();
  }

  return (
    <>
      <Snackbar open={showToast} autoHideDuration={duration} onClose={handleSnackBarClose}>
          <Alert severity={failure ? "error": "success"} sx={{ width: "100%" }}>
          {message}
          </Alert>
      </Snackbar>
    </>
  );
};

export default Toast;
