import { DECREASE_FONT, INCREASE_FONT, SET_STEP } from "../actions/font";

const initialState = {
  step: 0,
  h1: 64,
  h2: 48,
  h3: 36,
  h4: 24,
  h5: 16,
  h6: 12,
};

export const Font = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.payload };
    case INCREASE_FONT:
      return {
        ...state,
        h1: action.payload.h1,
        h2: action.payload.h2,
        h3: action.payload.h3,
        h4: action.payload.h4,
        h5: action.payload.h5,
        h6: action.payload.h6,
      };
    case DECREASE_FONT:
      return {
        ...state,
        h1: action.payload.h1,
        h2: action.payload.h2,
        h3: action.payload.h3,
        h4: action.payload.h4,
        h5: action.payload.h5,
        h6: action.payload.h6,
      };
    default:
      return state;
  }
};
