import {
  SET_SYSTEM_NOTIFICATIONS,
  UNSET_SYSTEM_NOTIFICATIONS,
  SET_SYTEM_NOTIFICATOIN_ERROR,
} from "../actions/sysNotification";

const initialState = {
  notifications: [],
  error: false,
};

export const SysNotification = (state = initialState, action) => {
  switch (action.type) {
    case SET_SYSTEM_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case UNSET_SYSTEM_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(
          (x) => x.id !== action.payload
        ),
      };
    case SET_SYTEM_NOTIFICATOIN_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
