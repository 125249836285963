const LanguageEnum = Object.freeze({
  LV: 0,
  EN: 1,
  RU: 2,
});

const LanguageMap = new Map([
  [LanguageEnum.LV, "LV"],
  [LanguageEnum.EN, "EN"],
  [LanguageEnum.RU, "RU"]
]);

const LanguageMapCodes = new Map([
  [LanguageEnum.LV, "Lv"],
  [LanguageEnum.EN, "En"],
  [LanguageEnum.RU, "Ru"]
])

export { LanguageEnum, LanguageMap, LanguageMapCodes };
