import moment from "moment";
import CONSTANTS from "../constants";

const timeUtil = {
  date: (dateToFormat) => {
    if (moment(dateToFormat).isValid())
      return moment.utc(dateToFormat).format(CONSTANTS.DATE_FORMAT);

    return dateToFormat;
  },
  time: (timeToFormat, withSeconds = false, withUtc = true) => {
    const formatter = withUtc ? moment.utc : moment;
    const formatString = withSeconds
      ? `${CONSTANTS.TIME_FORMAT}:${CONSTANTS.SECONDS_FORMAT}`
      : CONSTANTS.TIME_FORMAT;

    if (moment(timeToFormat).isValid()) {
      return formatter(timeToFormat).format(formatString);
    }

    return timeToFormat;
  },
};

export default timeUtil;
