import React, { forwardRef } from "react";
import {
  Formik,
  Form
} from "formik";
import ConsentPermissionElement from "./ConsentPermissionElement";

const PermissonForm = forwardRef(({ consent, handleGetConsentAnswers, disabled = false, responsesJson = null, showAllRemarks = false}, ref) => {
  const consentPermissions = consent.consentPermissions.sort(
    (x, y) => x.position - y.position
  );
  const savedData = responsesJson ? JSON.parse(responsesJson) : constructInitialValues(consentPermissions);

  const radioRemarkFieldErrorMessage = "Jāaizpilda obligāti.";
  const checkboxRemarkFieldErrorMessage = "Jāaizpilda obligāti.";
  const radioFieldErrorMessage = "Jāaizpilda obligāti.";

  function constructInitialValues(consentPermissions) {
    let initialValues = {};

    for (const consentPermission of consentPermissions) {
      if (consentPermission.multipleResponsesAllowed) {
        // checkboxes
        consentPermission.consentPermissionResponses.forEach((response) => {
          initialValues[`checkbox_${consentPermission.id}_${response.id}`] = false;
          if (response.withRemark) {
            initialValues[`checkbox_${consentPermission.id}_${response.id}_remark`] = "";
          }
        });
      } else if (!consentPermission.multipleResponsesAllowed) {
        // radio
        initialValues[`radio_${consentPermission.id.toString()}`] = "";
        consentPermission.consentPermissionResponses.forEach((response) => {
          if (response.withRemark)
            initialValues[`radio_${consentPermission.id}_${response.id}_remark`] = "";
        });
      }
    }

    return initialValues;
  }

  function validateForm(values) {
    let errors = {};

    const isAtLeastOneCheckboxChecked = (consentPermission) => {
      return consentPermission.consentPermissionResponses.some(response => {
        return values[`checkbox_${consentPermission.id}_${response.id}`];
      });
    };

    // 1. First loop: Validate checkboxes
    for(const consentPermission of consentPermissions) {
      if(consentPermission.multipleResponsesAllowed && !isAtLeastOneCheckboxChecked(consentPermission))
       errors[consentPermission.id] = "Jāizvēlas vismaz viena atbilde.";
    }

    // 2. Second loop: Validate remark fields and other question types
    Object.keys(savedData).forEach((key) => {
      const [type, permissionId, responseId, remark] = key.split("_");
      const permission = consentPermissions.find(permission => permission.id.toString() === permissionId);

      if (!permission) {
        console.error(`No permission found for permission Id: ${permissionId}`);
        return;
      }

      // Check if the key is for a remark
      if (remark) {
         // radio
        if (!permission.multipleResponsesAllowed) {
          const radioValue = values[`radio_${permissionId.toString()}`];

          // Only validate the remark if the associated radio option is selected
          if (radioValue === responseId && (!values[key] || values[key].length === 0))
            errors[key] = radioRemarkFieldErrorMessage;

        // checkbox
        } else {
          const associatedOptionKey = `${type}_${permissionId}_${responseId}`;
          // Validate the remark for checkboxes (and other potential types if they exist in the future)
          if (values[associatedOptionKey] && (!values[key] || values[key].length === 0)) {
            errors[key] = checkboxRemarkFieldErrorMessage;
          }
        }
      }
      // For radio fields, validate if they are not selected
      else if (!permission.multipleResponsesAllowed && (!values[key] || values[key].length === 0))
        errors[key] = radioFieldErrorMessage;
    });

    return errors;
  };


  function handleSubmitClick(data) {
    handleGetConsentAnswers(data);
  };

  return (
    <>
      <Formik
        initialValues={savedData}
        innerRef={ref}
        validate={validateForm}
        onSubmit={(values)=>handleSubmitClick(values)}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            {(consentPermissions.length ? true : false) &&
              consentPermissions.map((consentPermission) => (
                <ConsentPermissionElement
                  key={consentPermission.id}
                  consentPermission={consentPermission}
                  disabled={disabled}
                  errors={formik.errors}
                  touched={formik.touched}
                  values={formik.values}
                  showAllRemarks={showAllRemarks}
                />
              ))}
          </Form>
        )}
      </Formik>
    </>
  );
});

export default PermissonForm;
