import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CountdownModal = ({
  isModalOpen,
  handleModalClose,
  handleRotateToken,
  handleLogout,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <>
      <Dialog
        maxWidth={"md"}
        fullWidth
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Jūsu lietotāja sesija drīz beigsies!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Vai vēlaties turpināt?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLogout}>
            Iziet
          </Button>
          <Button onClick={handleRotateToken} autoFocus>
            Turpināt
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CountdownModal;
