export const LOAD_PERSONS = "[persons] load";
export const LOAD_PERSONS_SUCCESS = "[persons] load success";
export const LOAD_PERSONS_FAILURE = "[persons] load failure";
export const SET_PERSONS = "[persons] set";
export const SET_LOADING_ON = "[persons] loading";
export const SET_LOADING_OFF = "[persons] loaded";
export const SET_PERSON_POST = "[persons] post data";
export const SET_PERSON_SUCCESS = "[persons] post data successfully";
export const SET_PERSON_FAILURE = "[persons] post data fail";
export const SET_SINGLE_PERSON_DATA = "[person] single data";
export const UNLOAD_PERSON_DATA = "[person] unload";
export const PERSONS_LOADING = "[persons] loading";
export const UNLOAD_PERSON_LIST = "[persons] unload person list";
export const SET_PERSON_REGCODE = "[persons] reg code load";
export const SET_PERSON_REGCODES = "[persons] reg codes load";
export const UNLOAD_PERSON_REGCODE = "[persons] reg code unload";
export const SET_PERSON_REGCODE_INUSE_ERROR = "[persons] reg code error";
export const SET_PERSON_REGCODE_NOTFOUND_ERROR =
  "[persons] reg code not found error";
export const CLEAR_PERSON_REGCODE = "[persons] reg code clear";
export const SET_LINKING_SUCCESS = "[persons] reg code linking status";
export const REMOVE_RECODE = "[persons] reg code remove";

export const removeRegCode = (id) => ({
  type: REMOVE_RECODE,
  payload: id,
});

export const unloadPersonRegCode = () => ({
  type: UNLOAD_PERSON_REGCODE,
});

export const setRegCodes = (regCodes) => ({
  type: SET_PERSON_REGCODES,
  payload: regCodes,
});

export const setLinkingSuccess = (status) => ({
  type: SET_LINKING_SUCCESS,
  payload: status,
});

export const clearRegCode = () => ({
  type: CLEAR_PERSON_REGCODE,
});

export const setRegCodeInUseError = (error) => ({
  type: SET_PERSON_REGCODE_INUSE_ERROR,
  payload: error,
});

export const setRegCodeNotFoundError = (error) => ({
  type: SET_PERSON_REGCODE_NOTFOUND_ERROR,
  payload: error,
});

export const setPersonData = (personData) => ({
  type: SET_PERSON_POST,
  payload: personData,
});

export const setPersonsLoading = (isLoading) => ({
  type: PERSONS_LOADING,
  payload: isLoading,
});

export const unloadPersonData = () => ({
  type: UNLOAD_PERSON_DATA,
});

export const unloadPersonList = () => ({
  type: UNLOAD_PERSON_LIST,
});

export const setSinglePersonData = (personData) => ({
  type: SET_SINGLE_PERSON_DATA,
  payload: personData,
});

export const setPersonDataFailure = (error) => ({
  type: SET_PERSON_FAILURE,
  payload: error,
});

export const setPersonSuccess = () => ({
  type: SET_PERSON_SUCCESS,
});

export const loadPersons = (persons) => ({
  type: LOAD_PERSONS,
  payload: persons,
});

export const loadPersonsSuccess = (persons) => ({
  type: LOAD_PERSONS_SUCCESS,
  payload: persons,
});

export const loadPersonsFailure = (error) => ({
  type: LOAD_PERSONS_FAILURE,
  payload: error,
});

export const setPersons = (persons) => ({
  type: SET_PERSONS,
  payload: persons,
});

export const setLoading = (isLoading) => ({
  type: isLoading ? SET_LOADING_ON : SET_LOADING_OFF,
  payload: isLoading,
});

export const setPersonRegCode = (regCode) => ({
  type: SET_PERSON_REGCODE,
  payload: regCode,
});
