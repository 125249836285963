const ClQuestionTypeEnum = Object.freeze({
  Simple: 1,
  Complex: 2,
});

const ClQuestionTypeMapLV = new Map([
  [ClQuestionTypeEnum.Simple, "Vienkāršs"],
  [ClQuestionTypeEnum.Complex, "Salikts"],
]);

const ClQuestionTypeMapEN = new Map([
  [ClQuestionTypeEnum.Simple, "Simple"],
  [ClQuestionTypeEnum.Complex, "Complex"],
]);

const ClQuestionTypeMapRU = new Map([
  [ClQuestionTypeEnum.Simple, "Простой"],
  [ClQuestionTypeEnum.Complex, "Композитный"],
]);

export { ClQuestionTypeEnum, ClQuestionTypeMapLV, ClQuestionTypeMapEN, ClQuestionTypeMapRU };
