export const SNACKBAR_OPEN = "[snackbar] open";
export const SNACKBAR_CLOSE = "[snackbar] close";
export const SET_SNACKBAR_MESSAGE = "[snackbar] set snackbar message";
export const SET_SNACKBAR_MESSAGE_AND_OPEN =
  "[snackbar] set snackbar message and open";

export const setSnackbarMessage = (payload) => ({
  type: SET_SNACKBAR_MESSAGE,
  payload: payload,
});

export const openSnackbar = () => ({
  type: SNACKBAR_OPEN,
});

export const closeSnackbar = () => ({
  type: SNACKBAR_CLOSE,
});

export const setSnackbarMessageAndOpen = (payload) => ({
  type: SET_SNACKBAR_MESSAGE_AND_OPEN,
  payload: payload,
});
