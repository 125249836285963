import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import React from "react";
import { ConsentResponseTypeMapLV } from "../enums/consent-response-type";

const consentResponseSelectListItems = Array.from(ConsentResponseTypeMapLV).map(([name, value]) => ({ name, value }));

const ResponseSelect = ({ name, defaultValue, required }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormControl size="small" error={errors.hasOwnProperty(name)} fullWidth>
        <InputLabel>Atbilžu varianti</InputLabel>
        <Controller
          rules={{ required: required ? "Lauks ir obligāts" : false }}
          control={control}
          defaultValue={defaultValue ?? ""}
          name={name}
          render={({ field }) => (
            <Select autoWidth label={"Response"} defaultValue={""} {...field}>
              {consentResponseSelectListItems.map(({ name, value }) => (
                <MenuItem key={name} value={name}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText error>
          {errors.hasOwnProperty(name) ? errors[name].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default ResponseSelect;
