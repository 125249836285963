import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Box,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import FileListItem from "./FileListItem";
import CONSTANTS from "../../constants";
import BaseButton from "../buttons/BaseButton";

const FileUpload = ({
  value,
  onChange,
  sx,
  title,
  buttonText,
  typographyProps,
  buttonProps,
  disabled,
  allowedFileFormats,
  ...options
}) => {
  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    ...options,
    disabled,
    onDropAccepted: onChange,
    noClick: true,
    maxSize: CONSTANTS.MAX_FILE_SIZE_IN_BYTES,
    noKeyboard: true,
    validator: fileExtensionValidator,
    maxFiles: 1,
    multiple: false
  });
  const fileSizeInMB = CONSTANTS.MAX_FILE_SIZE_IN_BYTES / 1024 ** 2;
  
  function fileExtensionValidator(file) {
    if (!allowedFileFormats?.length || !file?.name) return null;
  
    const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
    if (!fileExtension)
      return {
        code: "file-does-not-have-extension",
      };

      if (allowedFileFormats.some((fileFormat) => fileFormat === fileExtension))
        return null;

      return {
        code: "file-extension-wrong",
      };
  }

  function remove(index) {
    const files = [...value];
    files.splice(index, 1);
    onChange(files);
  };

  const files = value?.map((file, i) => (
    <FileListItem key={file.name} name={file.name} onDelete={() => remove(i)} />
  ));

  function errorText(errorCode) {
    switch (errorCode) {
      case "file-too-large":
        return `Atļauts augšupielādēt datnes, kuru lielums nepārsniedz ${fileSizeInMB}MB!`
        case "file-extension-wrong": {
          let validationErrorText = "Atļauts augšupielādēt datnes formātā";
  
          const fileExtensionTexts = allowedFileFormats.map(
            (format) => `*.${format}`
          );
          if (fileExtensionTexts.length < 2)
            validationErrorText = `${validationErrorText} ${fileExtensionTexts[0]}!`;
          else if (fileExtensionTexts.length === 2)
            validationErrorText = `${validationErrorText} ${fileExtensionTexts[0]} un ${fileExtensionTexts[1]}!`;
          else
            validationErrorText = `${validationErrorText} ${fileExtensionTexts
              .slice(0, -1)
              .join(",")} un ${fileExtensionTexts.pop()}!`;

            return validationErrorText;
        }
        case "file-does-not-have-extension" :
          return "Datnei jābūt faila formātam!";

          case "too-many-files" :
            return "Parāk daudz failu";
      default:
        return "Nezināma kļūda"
    }
  }
  function isSelectButtonDisabled() {
    return disabled || files?.length ? true : false;
  }
  function dragAndDropTitle() {
    return "Velciet un nometiet failu šeit vai noklikšķiniet, lai atlasītu failu";
  }
  return (
    <Box
      {...getRootProps()}
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "rgba(0, 0, 0, 0.23)",
        paddingY: 3,
        paddingX: 1,
        "&:hover": {
          borderColor: disabled ? undefined : "text.primary",
        },
        "&:focus-within": {
          borderColor: "primary.main",
          borderWidth: 2,
        },
        ...sx,
      }}
    >
      <FormControl
        error={fileRejections[0]?.errors[0] ? true : false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon
          sx={{ fontSize: 40 }}
          color={disabled ? "disabled" : "primary"}
        />
        <Typography
          variant="caption"
          textAlign="center"
          sx={{ paddingY: 1 }}
          {...typographyProps}
        >
          {dragAndDropTitle()}
        </Typography>
        <BaseButton
          variant="contained"
          onClick={open}
          disabled={isSelectButtonDisabled()}
          sx={{ marginBottom: 1 }}
          {...buttonProps}
        >
          {"Atlasīt datni"}
        </BaseButton>
        {fileRejections[0]?.errors[0] &&
          <FormHelperText error>
            {errorText(fileRejections[0]?.errors[0]?.code)}
          </FormHelperText>
        }
      </FormControl>
      <Box
        component="ul"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
      >
        {files}
      </Box>
    </Box>
  );
};

export default FileUpload;
