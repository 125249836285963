import { useDispatch, useSelector } from "react-redux";
import { openSnackbar, setSnackbarMessage } from "../redux/actions/snackbar";

const useSnackbar = () => {
  const dispatch = useDispatch();

  const { open, message } = useSelector((state) => state.Snackbar);

  const setMessageAndOpenSnackbar = (message) => {
    dispatch(setSnackbarMessage(message));
    dispatch(openSnackbar());
  };

  const closeSnackbar = () => {
    dispatch(closeSnackbar());
  };

  return { setMessageAndOpenSnackbar, closeSnackbar, open, message };
};

export default useSnackbar;
