import {
  SET_FOOTER_USEFUL_LINKS,
  SET_FOOTER_CONTENT,
  SET_HOMEPAGE_IMAGE_SRC_ARRAY,
  SET_MOREINFORMATION_GROUPS,
  SET_MOREINFORMATION_GROUPS_UPDATE_DATE,
  SET_FAQ_UPDATE_DATE,
  SET_FAQ_GROUPS
} from "../actions/cms";

const initialState = {
  homePageImageSrcArray: {},
  footerUsefulLinks: [],
  footerContent: {
    contentEn: "",
    contentRu: "",
    contentLv: "",
    dateUpdated: null,
  },
  moreInformationGroups: [],
  moreInformationUpdateDate: new Date(),
  faqUpdateDate: new Date(),
  faqGroups: [],
};

export const Cms = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAQ_GROUPS:
      return { ...state, faqGroups: action.payload }
    case SET_FAQ_UPDATE_DATE:
      return { ...state, faqUpdateDate: action.payload }
    case SET_MOREINFORMATION_GROUPS_UPDATE_DATE:
      return { ...state, moreInformationUpdateDate: action.payload };
    case SET_MOREINFORMATION_GROUPS:
      return { ...state, moreInformationGroups: action.payload };
    case SET_HOMEPAGE_IMAGE_SRC_ARRAY:
      return { ...state, homePageImageSrcArray: action.payload };
    case SET_FOOTER_USEFUL_LINKS:
      return { ...state, footerUsefulLinks: action.payload };
    case SET_FOOTER_CONTENT:
      return { ...state, footerContent: action.payload };
    default:
      return state;
  }
};
