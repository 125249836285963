import React, { useEffect, useState } from "react";
import BaseButton from "./buttons/BaseButton";
import { Grid, Tooltip} from "@mui/material";
import { get, useFormContext } from "react-hook-form";
import { IconButton } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import { v4 } from "uuid";
import FormHelperText from "@mui/material/FormHelperText";
import useFileDownloader from "../hooks/useFileDownloader";

const FileUploadInput = ({
  name,
  defaultFiles = [],
  buttonLabel = "Pievienot pielikumu",
}) => {
  const {
    setValue,
    setError,
    formState: { errors },
    clearErrors
  } = useFormContext();

  const [attachedFiles, setAttachedFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesToUploadToShow, setFilesToUploadToShow] = useState([]);

  const { downloadFile } = useFileDownloader();

  const error = get(errors, name);

  const errorMessage = "Pārsniegts atļautais maksimālais faila izmērs";

  const handleOnChange = (event) => {
    if (event.target.files.length === 0) return;

    if (fileSizeExceeds(event.target.files[0])) {
      setError(name, { type: "manual", message: errorMessage });
      return;
    }
    setFilesToUploadToShow((prevState) => [
      ...prevState,
      { 
        key: v4(),
        name: event.target.files[0].name,
       },
    ]);

    const newUploadFiles = [...filesToUpload, event.target.files[0]];
    setValue(
      name,
      newUploadFiles,
      { shouldTouch: true }
    );
    setFilesToUpload(newUploadFiles);

    clearErrors(name);
  };

  //this event handler is necessary to handle same file not getting selected issue
  //https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
  const handleClick = (event) => {
    event.target.value = null;
  };
  const fileSizeExceeds = (file) => {
    const fileSizeInBytes = file.size;

    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    return fileSizeInMB > 50;
  };

  function handleRemoveFileToUpload(uploadedFileKeyToRemove) {
    const fileToRemove = filesToUploadToShow.find(file => file.key === uploadedFileKeyToRemove);
    const newUploadFilesToShow = filesToUploadToShow.filter(file => file.key !== uploadedFileKeyToRemove);
    setFilesToUploadToShow(newUploadFilesToShow);


    const newUploadFiles = filesToUpload.filter(file => file.name !== fileToRemove?.name);

    setValue(
      name,
      newUploadFiles,
      { shouldTouch: true }
    );
    setFilesToUpload(newUploadFiles);
  };

 function handleRemoveAttachedFiles(attachedFileIdToRemove) {
  const newAttachedFiles = attachedFiles.filter(attachedFile => attachedFile.id !== attachedFileIdToRemove);
  setAttachedFiles(newAttachedFiles);
  setValue("attachmentIds", newAttachedFiles.length ? newAttachedFiles.map((item) => item.id) : null, {
    shouldTouch: true,
  });
  };

  useEffect(() => {
    //if we do have default files then we need to map their ideas and send to server as those that should stay
    if (defaultFiles?.length) {
      const filesToShow = defaultFiles.map((item) => ({
        id: item.id,
        name: item.fileName,
        fileId: item.fileId
      }));
      setAttachedFiles(filesToShow);
      setValue("attachmentIds", filesToShow.map(item => item.id), {
        shouldTouch: true
      });
      setValue(name, [], {
        shouldTouch: true
      });
    }
  }, [defaultFiles, setValue, name]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <BaseButton color="inherit" variant="contained" component={"label"}>
          <input
            type="file"
            hidden
            onChange={handleOnChange}
            onClick={handleClick}
          />
          {buttonLabel}
        </BaseButton>
        {error?.message && (
          <FormHelperText error>{error.message}</FormHelperText>
        )}
      </Grid>
      <Grid item xs={12} display={"flex"} gap={2} my={1}>
        {attachedFiles?.map(attachedFile => (
          <div key={attachedFile.id}>
            <Grid display={"flex"}>
              <BaseButton
                variant="text"
                onClick={() => downloadFile(attachedFile.id)}
              >
                {attachedFile.name}
              </BaseButton>
              <Tooltip title="Noņemt">
                <IconButton onClick={() => handleRemoveAttachedFiles(attachedFile.id)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
        ))}
        {filesToUploadToShow?.map(fileToUpload => (
          <div key={fileToUpload.key}>
            <Grid display={"flex"}>
              <BaseButton
                variant="text"
              >
                {fileToUpload.name}
              </BaseButton>
              <Tooltip title="Noņemt">
                <IconButton onClick={() =>handleRemoveFileToUpload(fileToUpload.key)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default FileUploadInput;
