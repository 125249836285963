import React, { useRef } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "@tinymce/tinymce-react";
import { Controller, useFormContext, get } from "react-hook-form";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.scss";
import FormHelperText from "@mui/material/FormHelperText";
import { RichUtils } from "draft-js";
import { useTranslation } from "../../hooks/useTranslation";
import CircularProgress from "@mui/material/CircularProgress";

const convertStringToEditorState = (stringValue) => {
  const blocksFromHtml = htmlToDraft(stringValue);
  const state = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  );

  return EditorState.createWithContent(state);
};

const tinyMCEKey = process.env.REACT_APP_TINY_MCE;

const TextEditor = ({
  id,
  name,
  defaultValue,
  required,
  toolbarOptions,
  size,
  uploadCallback,
  shouldUnregister,
  maxCharLen,
  onChange
}) => {
  const [state, setState] = React.useState(EditorState.createEmpty());

  const {
    control,
    setValue,
    formState: { errors },
    unregister,
    getValues,
  } = useFormContext();

  const error = get(errors, name);

  const { ValidationErrorMandatoryField } =
    useTranslation() || "Lauks ir obligāts";
  const value = getValues(name);

  const [editorValue, setEditorValue] = React.useState(defaultValue || value);
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      onEditorStateChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  React.useEffect(() => {
    const hasDefaultValue =
      defaultValue !== null &&
      defaultValue !== undefined &&
      defaultValue !== "";
    if (hasDefaultValue || value) {
      setEditorValue(defaultValue || value);
      const editorState = convertStringToEditorState(defaultValue || value); //htmlToDraft(defaultValue || value); ;
      setState(editorState);
      setValue(name, defaultValue || value);
    }
    return () => shouldUnregister === true && unregister(name);
  }, [defaultValue, unregister]);

  const onEditorStateChange = (editorState) => {
    if (maxCharLen) {
      const charLen = editorState.getCurrentContent().getPlainText();
      if (maxCharLen < charLen.length) return;
    }
    setState(editorState);
  };

  const onEditorChange = (content, editor) => {
    setEditorValue(content);
    onChange && onChange(content);
  };

  const onBlur = (e) => {
    setValue(name, editorValue);
  };

  return (
    <div id={id}>
      <Controller
        name={name}
        rules={{
          required: required ? ValidationErrorMandatoryField : false,
          shouldUnregister: shouldUnregister ? true : false,
        }}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <Editor
              apiKey={tinyMCEKey}
              editorStyle={error ? { border: "2px solid red" } : {}}
              uploadCallback={uploadCallback}
              handleKeyCommand={handleKeyCommand}
              handlePastedText={() => false}
              editorState={state}
              onEditorStateChange={onEditorStateChange}
              editorClassName={
                size === "small" ? "small-editor" : "demo-editor"
              }
              wrapperClassName={
                size === "small" ? "small-wrapper" : "demo-wrapper"
              }
              value={editorValue}
              onEditorChange={onEditorChange}
              onBlur={onBlur}
              init={{
              }}
            />
          );
        }}
      />
      {error && (
        <FormHelperText error>{error.message || "Lauks ir obligāts"}</FormHelperText>
      )}
    </div>
  );
};
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const TextEditorTinyMce = React.forwardRef(
  ({ name, defaultValue, width, height, hasImageUpload, hasFormattingOptions = true }, ref) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const formattingOptions = hasFormattingOptions ? `undo redo | bold italic underline link | numlist bullist | fontsize ${hasImageUpload ? "| image" : ""}` : false;

    const safeRef = useRef(null);

    const [isLoading, setIsLoading] = React.useState(true);

    if (!ref) ref = safeRef;

    const { ValidationErrorMandatoryField } =
      useTranslation() || "Lauks ir obligāts";

    const error = get(errors, name);

    const onInit = (evt, editor) => {
      ref.current = editor;
      setIsLoading(false);
    }

    React.useEffect(() => {
      register(name);
    }, []);

    return (
      <>
        {isLoading ? (
          <CircularProgress />
        ) : null}
        <Editor
          onInit={onInit}
          apiKey={
            process.env.REACT_APP_TINY_MCE ||
            "hcxdei8hc39pu4eens0gy6b8o9gkty84hvnsb7buh67buolp"
          }
          initialValue={defaultValue}
          init={{
            height: height || 200,
            width: width || undefined,
            image_uploadtab: true,
            menubar: false,
            toolbar: formattingOptions,
            plugins:
              "preview autoresize importcss searchreplace autolink save directionality visualblocks visualchars fullscreen image link media pagebreak nonbreaking anchor lists",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } img { max-width: 100%; max-height: 370px; } }",
            file_picker_types: "image",
            image_title: true,
            automatic_uploads: true,
            images_reuse_filename: true,
            images_upload_url: `${backendUrl}/api/public/file`,
            images_replace_blob_uris: true,
            quickbars_insert_toolbar: "quickimage",
            relative_urls: false,
            convert_urls: false,
          }}
        />
        {error ? (
          <FormHelperText error>{ValidationErrorMandatoryField}</FormHelperText>
        ) : null}
      </>
    );
  }
);

export default TextEditor;
