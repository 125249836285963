import { CustomLink } from "./";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import AppsIcon from "@mui/icons-material/Apps";

const CustomBreadCrumbs = ({ links, sx }) => {
  return (
    <>
      <Breadcrumbs
        sx={{ ...sx, marginBottom: "2rem" }}
        separator={"›"}
        aria-label="breadcrumb"
      >
        <CustomLink
          sx={{ display: "flex", alignItems: "center" }}
          to="/dashboard"
        >
          <AppsIcon fontSize="inherit" />
          Sākums
        </CustomLink>
        {links.map((item, _) => {
          return (
            <CustomLink key={item.to} to={item.to}>
              <Typography>{item.text}</Typography>
            </CustomLink>
          );
        })}
      </Breadcrumbs>
    </>
  );
};

export default CustomBreadCrumbs;
