import { Navigate, useLocation, Outlet } from "react-router-dom";
import useToken from "./hooks/useToken";

export const HomePrivateRoute = ({ children }) => {
  const location = useLocation();

  const { token } = useToken();

  const isAuthenticatedStorage = sessionStorage.getItem("isAuthenticated");

  if (location.pathname === "/" && isAuthenticatedStorage == true && token) {
    return <Navigate to="/dashboard" />;
  }

  return children ? children : <Outlet />;
};
