import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { translation } from "../redux/selectors/translation";

const SaveButton = ({ type, disabled }) => {
  const translations = useSelector(translation);
  const saveButtonTr = translations.find((x) => x.key === "ButtonSave")?.value;

  return (
    <Button type={type} color="success" variant="contained" disabled={disabled}>
      {saveButtonTr}
    </Button>
  );
};

export default SaveButton;
