import CONSTANTS from "../constants";
const fileSizeInMB = CONSTANTS.MAX_FILE_SIZE_IN_BYTES / 1024 ** 2;
const fileValidators = {
  validate: {
    maxFileSize: (file) => {
      return file?.size > CONSTANTS.MAX_FILE_SIZE_IN_BYTES
        ? `Atļauts augšupielādēt datnes, kuru lielums nepārsniedz ${fileSizeInMB}MB!`
        : true;
    },
  },
};

export default fileValidators;
