import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import React from "react";
import Add from "@mui/icons-material/Add";
import { useFormContext } from "react-hook-form";
import PhoneCountryCodeSelect from "./PhoneCountryCodeSelect";
import TextInputField from "./TextInputField";

const MultiplePhoneField = ({ forwardPhones = [] }) => {
  const { register } = useFormContext();

  const [secondPhonePresent, setSecondPhonePresent] = React.useState(
    forwardPhones?.length > 1 ? true : false
  );

  const handleAddingNewPhone = () => {
    setSecondPhonePresent(true);
  };

  const handleRemovePhone = () => {
    setSecondPhonePresent(false);
  };

  return (
    <>
      <Box display="flex" width={{ md: "70%", xs: "100%" }} gap="1rem">
        <Box display="flex" flex={.3}>
          <PhoneCountryCodeSelect
            name={"phones.0.phoneCountry"}
            required={true}
            label={"Telefona nr.*"}
            defaultValue={forwardPhones.length > 0 ? forwardPhones[0]?.phoneCountry : "+371"}
            fullWidth={true}
          />
        </Box>
        <Box display="flex">
          <TextInputField
            name={"phones.0.telNr"}
            isRequired={true}
            defaultValue={forwardPhones.length > 0 ? forwardPhones[0]?.telNr : ""}
          />
          <input hidden {...register("phones.0.primary", { value: true })} />
        </Box>
      </Box>
      {secondPhonePresent && (
        <>
          <Box
            marginTop="1.5rem"
            display="flex"
            width={{ md: "70%", xs: "100%" }}
            gap="1rem"
          >
            <Box display="flex" flex={.5}>
              <PhoneCountryCodeSelect
                name={"phones.1.phoneCountry"}
                required={true}
                fullWidth={true}
                label={"Papildu telefona nr.*"}
                shouldUnregister={true}
                defaultValue={
                  forwardPhones.length > 1
                    ? forwardPhones[1] && forwardPhones[1]?.phoneCountry
                    : "+371"
                }
              />
            </Box>
            <Box display="flex">
              <TextInputField
                name={"phones.1.telNr"}
                isRequired={true}
                shouldUnregister={true}
                defaultValue={forwardPhones.length > 1 ? forwardPhones[1]?.telNr: ""}
              />
              <input
                hidden
                {...register("phones.1.primary", {
                  value: false,
                  shouldUnregister: true,
                })}
              />
            </Box>
            <IconButton onClick={handleRemovePhone} sx={{ alignItems: "flex-start" }}>
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </>
      )}
      {!secondPhonePresent && (
        <Button
          sx={{ marginTop: "1rem" }}
          variant="outlined"
          onClick={handleAddingNewPhone}
          endIcon={<Add />}
          color="secondary"
        >
          Papildu telefona nr.
        </Button>
      )}
    </>
  );
};

export default MultiplePhoneField;
