const MessageStatusEnum = Object.freeze({
  Received: 1,
  Sent: 2,
  Archived: 3,
});

const MessageStatusMapLV = new Map([
  [MessageStatusEnum.Received, "Saņemts"],
  [MessageStatusEnum.Sent, "Nosūtīts"],
  [MessageStatusEnum.Archived, "Arhivēts"],
]);

export { MessageStatusEnum, MessageStatusMapLV };
