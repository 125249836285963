import http from "../api/http";

const userService = {
  getCurrentUserInfo: async () => {
    const response = await http.authInstance.get("/api/user/userinfo");

    return response.data;
  },
  getCurrentUserRoles: async () => {
    const response = await http.authInstance.get("/api/user/current-user-roles");

    return response.data;
  },

  isUserNameUnique: async (userNameToCheck) => {
    const response = await http.authInstance.get("/api/user/is-username-unique", {
      params: {userNameToCheck}
    });

    return response.data;
  },
};

export default userService;
