export const SET_CLASSIFIER = "[classifier] set classifier";
export const SET_CLASSIFIER_FAILURE = "[classifier] set classifier failure";
export const SET_CLSEX = "[classifier] set cl_sex";
export const SET_CLSEX_FAILURE = "[classifier] set cl_sex failure";
export const SET_CLSINGLE = "[classifier] set single cl";
export const CLEAR_CLSINGLE = "[classifier] unlaod single cl";
export const SET_CLSTATUS = "[classifier] set cl_status";
export const CLEAR_CLSTATUS = "[classifier] unload cl_status";
export const SET_CLCONSENTRESPONSE = "[classifier] set cl_consentresponse";
export const CLEAR_CLCONSENTRESPONSE = "[classifier] unload cl_consentresponse";
export const SET_CONSENT_CLASSIFIER = "[classifier] set consent classifier";
export const CLEAR_CONSENT_CLASSIFIER = "[classifier] clear consent classifier";
export const SET_CONSENT_CLASSIFIER_DROPDOWN_IDS =
  "[classifier] set consent classifier ids";
export const SET_CONSENT_CLASSIFIER_NUMBER =
  "[classifier] set consent classifier number";
export const SET_CLTESTGROUPS = "[classifier] set cl_test_groups";
export const SET_CLTESTS = "[classifier] set cl_tests";
export const SET_CLQUESTIONTYPES = "[classifier] set cl_questiontypes";
export const CLEAR_CLQUESTIONTYPES = "[classifier] unload cl_questiontypes";
export const SET_CLANSWERTYPES = "[classifier] set cl_answertypes";
export const CLEAR_CLANSWERTYPES = "[classifier] unload cl_answertypes";
export const SET_CLREFERRALSTATUS = "[classifier] set cl_referral_status";
export const SET_CLREFERRALSTATUS_FAILURE = "[classifier] set cl_referral_status failure";
export const CLEAR_CLREFERRALSTATUS = "[classifier] clear cl_referral_status";


export const setConsentClassifierDropdownIds = (payload) => ({
  type: SET_CONSENT_CLASSIFIER_DROPDOWN_IDS,
  payload: payload,
});

export const setConsentClassifierNumber = (payload) => ({
  type: SET_CONSENT_CLASSIFIER_NUMBER,
  payload: payload,
});

export const setConsentResponseClassifier = (data) => ({
  type: SET_CLCONSENTRESPONSE,
  payload: data,
});

export const unloadClConsentResponseClassifier = () => ({
  type: CLEAR_CLCONSENTRESPONSE,
});

export const setConsentClassifier = (payload) => ({
  type: SET_CONSENT_CLASSIFIER,
  payload: payload,
});

export const clearConsentClassifier = () => ({
  type: CLEAR_CONSENT_CLASSIFIER,
});

export const unloadSingleClassifier = () => ({
  type: CLEAR_CLSINGLE,
});

export const setSingleClassifier = (data) => ({
  type: SET_CLSINGLE,
  payload: data,
});

export const setClassifier = (data) => ({
  type: SET_CLASSIFIER,
  payload: data,
});

export const setClSex = (data) => ({
  type: SET_CLSEX,
  payload: data,
});

export const setClSexFailure = (error) => ({
  type: SET_CLSEX_FAILURE,
  payload: error,
});

export const setClStatus = (data) => ({
  type: SET_CLSTATUS,
  payload: data,
});

export const unloadClStatus = () => ({
  type: CLEAR_CLSTATUS,
});

export const setClassifierError = (data) => ({
  type: SET_CLASSIFIER_FAILURE,
  payload: data,
});

export const setClTestGroups = (data) => ({
  type: SET_CLTESTGROUPS,
  payload: data,
});

export const setClTests = (data) => ({
  type: SET_CLTESTS,
  payload: data,
});

export const setClQuestionTypes = (data) => ({
  type: SET_CLQUESTIONTYPES,
  payload: data,
});

export const unloadClQuestionTypes = () => ({
  type: CLEAR_CLQUESTIONTYPES,
});

export const setClAnswerTypes = (data) => ({
  type: SET_CLANSWERTYPES,
  payload: data,
});

export const unloadClAnswerTypes = () => ({
  type: CLEAR_CLANSWERTYPES,
});

export const setClReferralStatus = (data) => ({
  type: SET_CLREFERRALSTATUS,
  payload: data,
});

export const setClReferralStatusFailure = (error) => ({
  type: SET_CLREFERRALSTATUS_FAILURE,
  payload: error,
});

export const clearClReferralStatus = () => ({
  type: CLEAR_CLREFERRALSTATUS,
});


