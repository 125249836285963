import Typography from "@mui/material/Typography";
import { useCountdown } from "../hooks/useCountDown";
import React from "react";
import { useDispatch  } from "react-redux";
import { RefreshAccessToken, Logout } from "../api/auth/auth";
import CountdownModal from "./header/CountdownModal";
import useToken from "../hooks/useToken";
import { setLogoutDialogOpen } from "../redux/actions/auth";

const tokenExp = (token) => {
  if (!token) return;
  const payloadBase64 = token.split(".")[1];
  const decodedJson = Buffer.from(payloadBase64, "base64").toString();
  const decoded = JSON.parse(decodedJson);
  const exp = decoded.exp;
  return exp * 1000;
};

export const ShowCountdown = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const { token } = useToken();

  const dispatch = useDispatch();

  const { minutes, seconds } = useCountdown(tokenExp(token || ""));

  const handleRotateToken = () => {
    dispatch(RefreshAccessToken());
    setIsModalOpen(false);
    sessionStorage.setItem("time", tokenExp(token));
  };

  const handleLogout = () => {
    dispatch(Logout());
    setIsModalOpen(false);
  };

  const handleModalClose = () => setIsModalOpen(false);

  React.useEffect(() => {
    const time = sessionStorage.getItem("time");
    if (minutes === 0 && seconds <= 59 && time !== null) setIsModalOpen(true);

    if (minutes <= 0 && seconds <= 0 && time != null) {
      handleLogout();
      dispatch(setLogoutDialogOpen(true));
    }
  }, [minutes]);

  return (
    <>
      <CountdownModal
        handleLogout={handleLogout}
        handleRotateToken={handleRotateToken}
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
      />
      <Typography variant="h6">
        {minutes} : {seconds}
      </Typography>
    </>
  );
};
