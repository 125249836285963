import http from "../api/http";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setClassifierError,
  setSingleClassifier,
  setClassifier,
  setClSex,
  setClSexFailure,
  setClStatus,
  setConsentResponseClassifier,
  setClTestGroups,
  setClTests,
  setClQuestionTypes,
  setClAnswerTypes, setClReferralStatusFailure, setClReferralStatus
} from "../redux/actions/classifier";
import serviceUtil from "../utils/service-util";

const useClassifier = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = React.useState(false);

  const [editModal, setEditModal] = React.useState(false);

  const { error, singleCl } = useSelector((x) => x.Classifier);

  const classifiers = useSelector((x) => x.Classifier.data);

  const genderClassifiers = useSelector((x) => x.Classifier.clSex);

  const statusClassifiers = useSelector((x) => x.Classifier.clStatus);

  const groupClassifiers = statusClassifiers.filter((x) => x.code === "ACTIVE" || x.code === "DELETED");

  const consentResponseClassifiers = useSelector((x) => x.Classifier.clConsentResponse);

  const clTestGroups = useSelector(state => state.Classifier.clTestGroups);
  const clTests = useSelector(state => state.Classifier.clTests);

  const clQuestionTypes = useSelector((x) => x.Classifier.clQuestionTypes);
  const clAnswerTypes = useSelector((x) => x.Classifier.clAnswerTypes);

  const referralStatusClassifiers = useSelector((x) => x.Classifier.clReferralStatus);

  const GetClassifiers = async (queryParams) => {
    try {
      queryParams =
          serviceUtil.assignPageSizeAndPageNumberIfNotPresent(queryParams);
      const { data } = await http.backendInstance.get("/api/classifier/list", {
        params: queryParams
      });
      dispatch(setClassifier(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const AddClassifier = async (body) => {
    try {
      await http.backendInstance.post("/api/classifier/cldata", body);
      setEditModal(false);
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const UpdateClassifier = async (body) => {
    try {
      await http.backendInstance.put("/api/classifier/clData", body);
      GetSingleClassifier(body.clTableName);
      setEditModal(false);
    } catch (error) {
      console.log(error);
      dispatch(setClassifierError(error));
    }
  };

  const GetSingleClassifier = useCallback(async (classifierName) => {
    try {
      const { data } = await http.backendInstance.get(`/api/classifier/${classifierName}`);
      dispatch(setSingleClassifier(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  }, [dispatch]);

  const GetGenderClassifier = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clSex");
      dispatch(setClSex(data));
    } catch (error) {
      dispatch(setClSexFailure(error));
    }
  };

  const GetStatusClassifier = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/classifier/clstatus"
      );
      dispatch(setClStatus(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetClTestGroups = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clTestGroups");
      dispatch(setClTestGroups(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetClTests = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clTests");
      dispatch(setClTests(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetClQuestionTypes = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clQuestionType");
      dispatch(setClQuestionTypes(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetClAnswerTypes = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clAnswerType");
      dispatch(setClAnswerTypes(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetConsentResponseClassifier = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/classifier/clconsentresponse"
      );
      dispatch(setConsentResponseClassifier(data));
    } catch (error) {
      dispatch(setClassifierError(error));
    }
  };

  const GetReferralStatusClassifier = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/classifier/clStatusReferrals");
      console.log(data);
      dispatch(setClReferralStatus(data));
    } catch (error) {
      dispatch(setClReferralStatusFailure(error));
    }
  };

  return {
    AddClassifier,
    UpdateClassifier,
    GetSingleClassifier,
    GetClassifiers,
    GetGenderClassifier,
    GetStatusClassifier,
    GetConsentResponseClassifier,
    GetClQuestionTypes,
    GetClAnswerTypes,
    groupClassifiers,
    genderClassifiers,
    classifiers,
    singleCl,
    statusClassifiers,
    consentResponseClassifiers,
    clQuestionTypes,
    clAnswerTypes,
    error,
    openModal,
    editModal,
    setOpenModal,
    setEditModal,
    GetClTestGroups,
    GetClTests,
    clTestGroups,
    clTests,
    GetReferralStatusClassifier,
    referralStatusClassifiers,
  };
};

export default useClassifier;
