import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import { useTranslation } from "../hooks/useTranslation";

const AddButton = ({ withIcon, label, ...props }) => {
  const translations = useTranslation();
  const buttonText = label || translations.ButtonAdd;
  return (
    <Button
      {...props}
      startIcon={withIcon ? <Add /> : null}
      color="primary"
      variant="contained"
    >
      {buttonText}
    </Button>
  );
};

export default AddButton;
