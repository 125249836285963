const UserRoleNameEnum = Object.freeze({
  Participant: "Participant",
  Admin: "Administrator",
  Researcher: "Researcher",
  Medic: "Doctor/Nurse",
});

const UserRoleNameEnumMapLV = new Map([
  [UserRoleNameEnum.Participant, "Dalībnieks"],
  [UserRoleNameEnum.Admin, "Administrators"],
  [UserRoleNameEnum.Researcher, "Pētnieks"],
  [UserRoleNameEnum.Medic, "Ārsts/Medmāsa"],
]);

export { UserRoleNameEnum, UserRoleNameEnumMapLV };
