import {
  Grid,
  FormHelperText,
  Box,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { Field } from "formik";

const ConsentPermissionElement = ({
  consentPermission,
  disabled,
  errors,
  touched,
  values,
  showAllRemarks,
}) => {
  function consentPermissionText(consentPermission) {
    return `${consentPermission.position + 1}) ${consentPermission.permissionLv}`;
  }

  return (
    <>
      <Grid container direction="column" item gap={1}>
        <Grid item>
          <Typography
            sx={{ mb: 0, textWrap: "balance", whiteSpace: "normal", align: "justify"}}
            paragraph
          >
            {consentPermissionText(consentPermission)}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          {renderResponses(
            consentPermission,
            disabled,
            errors,
            touched,
            values,
            showAllRemarks
          )}
          {errors[consentPermission.id.toString()] && (
            <FormHelperText error>
              {errors[consentPermission.id.toString()]}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ConsentPermissionElement;

function renderResponses(
  consentPermission,
  disabled,
  errors,
  touched,
  values,
  showAllRemarks
) {
  function showRadioGroupRemarkInputField(permissionId, responseId) {
    return (
      showAllRemarks ||
      values[`radio_${permissionId.toString()}`] === responseId.toString()
    );
  }

  function getRemarkErrorText(permissionId, responseId, prefix) {
    return errors[
      `${prefix}_${permissionId.toString()}_${responseId.toString()}_remark`
    ];
  }

  function isRemarkHaveErrors(permissionId, responseId, prefix) {
    return Boolean(
      errors[
        `${prefix}_${permissionId.toString()}_${responseId.toString()}_remark`
      ] &&
        touched[
          `${prefix}_${permissionId.toString()}_${responseId.toString()}_remark`
        ]
    );
  }

  function getRadioGroupPermission() {
    return (
      <Box>
        <Field
          as={RadioGroup}
          name={`radio_${consentPermission.id.toString()}`}
          key={consentPermission.id}
        >
          {consentPermission.consentPermissionResponses.sort((x ,y) => x.position - y.position).map((response) => (
            <div
              key={response.id}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    value={response.id.toString()}
                    control={<Radio disabled={disabled} />}
                    label={response.nameLv}
                  />
                </Grid>
                <Grid item>
                  {response.withRemark &&
                    showRadioGroupRemarkInputField(
                      consentPermission.id,
                      response.id
                    ) && (
                      <Box>
                        <Field
                          as={TextField}
                          name={`radio_${consentPermission.id}_${response.id}_remark`}
                          placeholder={""}
                          disabled={disabled}
                          error={isRemarkHaveErrors(
                            consentPermission.id,
                            response.id,
                            "radio"
                          )}
                          helperText={
                            isRemarkHaveErrors(
                              consentPermission.id,
                              response.id,
                              "radio"
                            )
                              ? getRemarkErrorText(
                                  consentPermission.id,
                                  response.id,
                                  "radio"
                                )
                              : ""
                          }
                          size="small"
                          values={
                            values[
                              `radio_${consentPermission.id}_${response.id}_remark`
                            ]
                          }
                        />
                      </Box>
                    )}
                </Grid>
              </Grid>
            </div>
          ))}
        </Field>
      </Box>
    );
  }

  function getCheckBoxGroupPermission() {
    return (
      <Box>
        {consentPermission.consentPermissionResponses.sort((x ,y) => x.position - y.position).map((response) => {
          return (
            <Grid container key={response.id} direction="column" gap={1}>
              <Grid item container spacing={2}>
                <Grid item sx={{ mb: 1 }}>
                  <FormControlLabel
                    control={
                      <Field
                        type="checkbox"
                        name={`checkbox_${consentPermission.id}_${response.id}`}
                        as={Checkbox}
                      />
                    }
                    label={response.nameLv}
                    disabled={disabled}
                  />
                </Grid>
                {response.withRemark &&
                  (showAllRemarks ||
                    values[
                      `checkbox_${consentPermission.id}_${response.id}`
                    ]) && (
                    <Grid item>
                      <Field
                        as={TextField}
                        name={`checkbox_${consentPermission.id}_${response.id}_remark`}
                        placeholder={""}
                        disabled={disabled}
                        error={isRemarkHaveErrors(
                          consentPermission.id,
                          response.id,
                          "checkbox"
                        )}
                        helperText={
                          isRemarkHaveErrors(
                            consentPermission.id,
                            response.id,
                            "checkbox"
                          )
                            ? getRemarkErrorText(
                                consentPermission.id,
                                response.id,
                                "checkbox"
                              )
                            : ""
                        }
                        size="small"
                      />
                    </Grid>
                  )}
              </Grid>
            </Grid>
          );
        })}
      </Box>
    );
  }
  function placePermissionElement() {
    if (consentPermission.withResponses && consentPermission.multipleResponsesAllowed) {
      // checkboxes
      return <>{getCheckBoxGroupPermission()}</>;
    } else if (consentPermission.withResponses && !consentPermission.multipleResponsesAllowed) {
      // radio
      return <>{getRadioGroupPermission()}</>;
    }

    return;
  }
  return <>{placePermissionElement()}</>;
}
