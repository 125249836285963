import { SET_ERROR, UNLOAD_ERROR } from "../actions/error";

const initialState = {
  code: "",
};

export const Error = (state = initialState, action) => {
  const { code } = action;
  switch (action.type) {
    case SET_ERROR:
      return { ...state, code: code };
    case UNLOAD_ERROR:
      return { ...state, code: "" };
    default:
      return state;
  }
};
