import { useState } from "react";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { BaseButton, ButtonWithLoading } from "../../components";
import { TitleForm } from "../../MUI_components";
import termsOfUseService from "../../services/terms-of-use-service";
import timeUtil from "../../utils/time-util";

import { Logout } from "../../api";

const TermsOfUseDialog = ({
  open,
  handleClose,
  termsOfUse,
  user = null,
  readOnly = false,
}) => {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function showTermsOfUseSubmitActions() {
    return !readOnly;
  }

  function handleChange(event) {
    setChecked(event.target.checked);
  }

  async function handleContinueClick() {
    if (!user?.id)
      return;
    try {
      await termsOfUseService.acceptTermsOfUse(user.id, termsOfUse.id);
      handleClose();
    } catch (error) {
      console.log(error?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDialogClose = (event, reason) => {
    if (readOnly)
      handleClose();

    if (reason && reason === "backdropClick")
      return;
  };
  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      scroll="paper"
      disableEscapeKeyDown={!readOnly}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <TitleForm
          title={"Lietošanas noteikumi"}
          titleCode={"FRM.TERMS"}
          noDivider
        />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" gap={2}>
          {/*Terms of use title */}
          <Grid container item justifyContent="center" alignItems="center">
            <Grid item>
              <Typography variant="h4">{termsOfUse?.titleLv}</Typography>
            </Grid>
          </Grid>
          {/* current date */}
          <Grid item>
            <Typography>{`Spēkā no: ${timeUtil.date(
              termsOfUse?.validFrom
            )}`}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          {termsOfUse?.descriptionLv && parse(termsOfUse?.descriptionLv)}
        </Grid>
      </DialogContent>
      <DialogActions>
        {showTermsOfUseSubmitActions() ? (
          <Grid container direction="column" gap={2}>
            {/*agreement */}
            <Grid item>
              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Esmu izlasījis(-usi) un piekrītu Lietošanas noteikumiem"
              />
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={3}>
                <ButtonWithLoading
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ height: "40px" }}
                  disabled={!checked}
                  loading={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    handleContinueClick();
                  }}
                >
                  Turpināt
                </ButtonWithLoading>
              </Grid>
              <Grid item xs={3}>
                <BaseButton
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    dispatch(Logout());
                  }}
                >
                  Atcelt
                </BaseButton>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <BaseButton
            variant="outlined"
            fullWidth
            onClick={handleClose}
          >
            {"Aizvērt"}
          </BaseButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default TermsOfUseDialog;
