import {get, useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {v4} from "uuid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/Icon";
import Delete from "@mui/icons-material/Delete";

const EditGroupFileUpload = ({ name, defaultFiles }) => {

    const { register, getValues, setValue, setError, formState: { errors }, clearErrors } = useFormContext();

    const [files, setFiles] = useState([]);

    const error = get(errors, name);

    const errorMessage = "Pārsniegts atļautais maksimālais faila izmērs";

    const handleOnChange = (event) => {
        if (event.target.files.length === 0) return;
        if (fileSizeExceeds(event.target.files[0]))  {
            setError(name, { type: "manual", message: errorMessage });
            return;
        }
        setFiles(prevState => [...prevState, {id: v4(), file: event.target.files[0] }]);
        setValue(name, [...files?.map(item => item.file), event.target.files[0]], { shouldTouch: true });
        clearErrors(name);
    };

    //this event handler is necessary to handle same file not getting selected issue
    //https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
    const handleClick = (event) => {
        event.target.value = null;
    };
    const fileSizeExceeds = (file) => {

        const fileSizeInBytes = file.size;

        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

        return fileSizeInMB > 50;
    }

    const handleRemove = (index) => {
        const arrayWithRemovedFile = [...files].filter(item => item.id !== index);
        const removedAttachmentIds = getValues("attachmentIds");
        setFiles(arrayWithRemovedFile);
        setValue(name, arrayWithRemovedFile);
        removedAttachmentIds.push(index);
        setValue("attachmentIds", removedAttachmentIds, { shouldTouch: true });
    }

    useEffect(() => {
        //if we do have default files then we need to map their ideas and send to server as those that should stay
        if (defaultFiles?.length > 0) {
            setFiles(defaultFiles.map(item => ({id: item.id, file: { name: item.name }})))
        }

    },[defaultFiles])

    useEffect(() => {
        register("attachmentIds", { value: [] });
    },[])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button color="inherit" variant="contained" component={"label"}>
                    <input
                        type="file"
                        hidden
                        onChange={handleOnChange}
                        onClick={handleClick}
                    />
                    Pievienot pielikumu
                </Button>
                { error?.message && (
                    <FormHelperText error>
                        {error.message}
                    </FormHelperText>
                ) }
            </Grid>
            <Grid item xs={12} display={"flex"} gap={2} my={1}>
                { files?.map((item, index) => (
                    <div key={item.id}>
                        <Grid display={"flex"}>
                            <Button variant="text">{item.file.name}</Button>
                            <IconButton onClick={() => handleRemove(item.id)}>
                                <Delete />
                            </IconButton>
                        </Grid>
                    </div>
                ))
                }
            </Grid>
        </Grid>
    );
};

export default EditGroupFileUpload;
