import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import { TitleForm } from "../../MUI_components";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomDatePicker from "../../MUI_components/CustomDatePicker";
import { FormProvider, useForm } from "react-hook-form";
import useRole from "../../hooks/useRole";
import roles from "../../roles/roles";

const ConsentFilledBiobank = () => {
  const [open, setOpen] = React.useState(false);

  //const { id } = useParams();

  const { role } = useRole();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    //fetch Biobank classifier
  }, []);

  return (
    <>
      <Container disableGutters maxWidth={"xl"}>
        <FormProvider {...useForm()}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                mattis, lacus id viverra
              </DialogContentText>
              <CustomDatePicker label={"Atsoukšanas datums"} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Turpināt
              </Button>
              <Button onClick={handleClose}>Atcelt</Button>
            </DialogActions>
          </Dialog>
        </FormProvider>
        <Grid container p={3} rowSpacing={2}>
          <Grid item xs={12}>
            <TitleForm
              title={"Biobankas informētā piekrišana"}
              titleCode={"FRM.CONS"}
            />
          </Grid>
          <Grid container>
            <Grid container my={2} direction={"column"} xs={3} item>
              <Grid display="flex">
                <Typography variant="h5">Iesniegēnas datums:</Typography>
                <Typography variant="h5">17.12.2021</Typography>
              </Grid>
              <Grid display="flex" xs={3} item>
                <Typography variant="h5">Aizpildītājs:</Typography>
                <Typography variant="h5">Personīgi</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography variant="h4">Piekrišanas nosaukums</Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            mattis, lacus id viverra dignissim, neque dui accumsan metus, id
            tempor ipsum ipsum ut nibh. Nulla sed convallis nisi. Nullam id
            nulla magna. Proin ut eleifend dui, ut egestas libero. Nulla
            vehicula, mauris sed consectetur lobortis, augue libero gravida
            enim, id mattis felis metus quis lorem. Aliquam erat volutpat.
            Mauris volutpat eros in urna accumsan, ut hendrerit turpis
            convallis. Quisque lobortis tortor quis viverra porta. Vestibulum
            nisl odio, rutrum mollis lobortis sit amet, facilisis et dolor.
            Quisque erat lorem, auctor sed nulla ac, dignissim ultrices quam.
            Nulla nulla quam, aliquam quis nunc eu, porta commodo ante. Ut sit
            amet orci imperdiet, convallis dolor vitae, luctus urna. Quisque
            sodales ultricies dolor, quis luctus ante feugiat quis. Praesent
            bibendum, leo tempus congue commodo, metus augue suscipit metus, nec
            rutrum erat lacus pretium nibh. Morbi tellus tellus, commodo ac
            magna ut, condimentum hendrerit lectus. Nam sagittis nulla vel
            ornare tincidunt. Nulla facilisi. Integer tincidunt felis nunc, at
            semper sapien semper maximus. Pellentesque at risus libero. In
            finibus velit ut felis ullamcorper, at vulputate augue ornare.
            Mauris tristique cursus nisi vel consequat. Praesent sed dolor a
            tortor luctus vehicula. Duis nec rhoncus nisi, in tempus mauris.
            Duis imperdiet eget nunc id mollis. Vivamus laoreet dolor dui, in
            vehicula arcu tempus et. Maecenas vitae quam sit amet orci faucibus
            gravida eget at nulla. Quisque sed gravida orci, a convallis felis.
            Nam non odio ut tortor dapibus viverra et at lectus. Donec mi enim,
            congue id commodo quis, fringilla sit amet dui. In quis rhoncus
            nunc, vel lacinia justo. In non urna id ligula blandit consectetur.
            Aenean id dignissim eros. In egestas est eget efficitur placerat. Ut
            non auctor purus, sagittis viverra leo. Nunc et mi sed ante maximus
            finibus et quis velit. Donec erat turpis, viverra non tellus in,
            molestie accumsan ipsum. Nullam laoreet velit odio, sit amet semper
            sem vulputate sit amet. Proin ac malesuada felis. Etiam nec
            porttitor leo, at efficitur felis. Sed dignissim tincidunt enim quis
            varius. Morbi nec ex ultrices orci congue ultrices nec in felis.
            Aenean sollicitudin augue ac porttitor sodales. Duis tincidunt
            viverra ipsum ut hendrerit. Mauris dignissim elementum dapibus.
          </Grid>
        </Grid>
        <Grid direction={"column"} mx={2} item xs={12}>
          <FormGroup>
            <FormControlLabel
              checked={true}
              control={<Checkbox defaultChecked />}
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FormControlLabel
              checked={true}
              disabled
              control={<Checkbox />}
              label="Disabled"
            />
            <FormControlLabel
              checked={true}
              control={<Checkbox defaultChecked />}
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FormControlLabel
              checked={true}
              disabled
              control={<Checkbox />}
              label="Disabled"
            />
            <FormControlLabel
              checked={true}
              control={<Checkbox defaultChecked />}
              label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FormControlLabel
              checked={true}
              disabled
              control={<Checkbox />}
              label="Disabled"
            />
          </FormGroup>
        </Grid>
        {role.name === roles.Admin && (
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Button
              onClick={handleOpenDialog}
              variant="contained"
              color="error"
            >
              Atsoukt pieknšonu
            </Button>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default ConsentFilledBiobank;
