import { useSelector } from "react-redux";

const useRightTranslation = () => {
  const currentLanguage = useSelector((x) => x.Translation.language);

  const GetRightTranslation = (lv, en, ru) => {
    if (currentLanguage === "Lv") return lv || "";
    if (currentLanguage === "En") return en || "";
    return ru || "";
  };

  return { GetRightTranslation };
};

export default useRightTranslation;
