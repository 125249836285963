import {
  LOAD_INVOLVED_RESEARCHERS,
  LOAD_LEADING_RESEARCHERS,
  LOAD_RESEARCHERS,
  SET_RESEARCH_ID,
  UNSET_INVOLVED_RESEARCHER,
  SET_SUBMIT_SUCCESSFUL,
  RESET_SUBMIT_SUCCESSFUL
} from "../actions/researcher";

const initialState = {
  researchId: null,
  researchers: [],
  leadingResearchers: [],
  involvedResearchers: [],
};

export const Researcher = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_RESEARCHERS:
      return { ...state, researchers: action.payload };
    case LOAD_LEADING_RESEARCHERS:
      return { ...state, leadingResearchers: action.payload };
    case LOAD_INVOLVED_RESEARCHERS:
      return { ...state, involvedResearchers: action.payload };
    case SET_RESEARCH_ID:
      return { ...state, researchId: action.payload };
    case SET_SUBMIT_SUCCESSFUL:
      return { ...state, isSubmitSuccessful: action.payload };
    case RESET_SUBMIT_SUCCESSFUL:
      return { ...state, isSubmitSuccessful: action.payload };
    case UNSET_INVOLVED_RESEARCHER:
      return { ...state, involvedResearchers: [] };
    default:
      return state;
  }
};
