const UserStatusEnum = Object.freeze({
  Active: "ACTIVE",
  Blocked: "BLOCKED",
  Deleted: "DELETED",
});

const UserStatusEnumMapLV = new Map([
  [UserStatusEnum.Active, "Aktīvs"],
  [UserStatusEnum.Blocked, "Bloķēts"],
  [UserStatusEnum.Deleted, "Dzēsts"],
]);

export { UserStatusEnum, UserStatusEnumMapLV };
