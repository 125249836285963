import React from "react";
import Grid from "@mui/material/Grid";
import { TextInputField } from "../../../MUI_components";

export const MoreInformationCmsAddGroupForm = ({ name }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextInputField
          label={"Grupas nosaukums"}
          name={name}
          isRequired={true}
          maxCharLength={100}
        />
      </Grid>
    </Grid>
  );
};
