import { combineReducers } from "redux";
import { LoadingReducer } from "./loading";
import { Person } from "./persons";
import { UserGroup } from "./userGroup";
import { Participant } from "./participant";
import { Auth } from "./auth";
import { Password } from "./password";
import { User } from "./user";
import { Translation } from "./translation";
import { Classifier } from "./classifier";
import { Font } from "./font";
import { Error } from "./error";
import { Snackbar } from "./snackbar";
import { Message } from "./message";
import { SysNotification } from "./sysNotification";
import { Notification } from "./notification";
import { Researcher } from "./researcher";
import { Research } from "./research";
import { Cms } from "./cms";
import { Referral } from "./referral";

export default combineReducers({
  LoadingReducer,
  Notification,
  SysNotification,
  Message,
  Researcher,
  Translation,
  Snackbar,
  Person,
  Research,
  Font,
  Participant,
  Classifier,
  UserGroup,
  Password,
  Error,
  User,
  Auth,
  Cms,
  Referral
});
