import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

const CustomLink = ({ children, to, sx }) => {
  return (
    <Link component={RouterLink} to={to} sx={sx}>
      {children}
    </Link>
  );
};

export default CustomLink;
