import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import http from "../../../api/http";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { CustomBreadCrumbs, TabPanel, TitleForm, EditGroupFileUpload } from "../../../MUI_components";
import { useForm } from "react-hook-form";
import { TabContext } from "@mui/lab";
import { FormProvider } from "react-hook-form";
import { useState } from "react";
import { a11yProps } from "./AddGroup";
import { FAQCmsGroupItemEditForm } from "./EditGroupItemForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { setFaqUpdateDate } from "../../../redux/actions/cms";

const FAQEditGroupItem = () => {

  const form = useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { groupId, itemId } = useParams();

  const [groupItem, setGroupitem] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const links = [
    { to: "/cms/faq", text: "Satura pārvaldība - Biežāk uzdotie jautājumi" },
    { to: `/cms/faq/group/${groupId}/item/${itemId}/edit`, text: "Labot ierakstu" }
  ];

  const contentLvRef = useRef(null);

  const contentEnRef = useRef(null);

  const contentRuRef = useRef(null);

  const prepareFormData = (data) => {

    const { titleLv, titleEn, titleRu } = data;

    const formData = new FormData();
    formData.append("titleLv", titleLv);
    formData.append("titleEn", titleEn);
    formData.append("titleRu", titleRu);
    formData.append("contentLv", contentLvRef.current.getContent() || "");
    formData.append("contentEn", contentEnRef.current.getContent() || "");
    formData.append("contentRu", contentRuRef.current.getContent() || "");
    formData.append("id", itemId);

    if (data?.attachmentField)
      for (const file of data?.attachmentField)
        formData.append("attachmentField", file);

    if (data?.attachmentIds)
        for (const fileId of data?.attachmentIds)
            formData.append("attachmentIds", fileId)

    return formData;
  }

  const handleSubmitForm = async (data, _) => {
    try {
      const formData = prepareFormData(data);
      dispatch(setFaqUpdateDate(Date.now()));
      await http.backendInstance.put("/api/cms/faqgroupitem", formData);
      navigate("/cms/faq");
    }
    catch (error) {
      console.log(error);
      handleErrors(error);
    }
  };

  const theme = useTheme();

  const { main } = theme.palette.error;

  const { formState: { errors } } = form;
  const hasFirstTabError = () => {
      return errors["titleLv"] !== undefined || errors["contentlv"] !== undefined;
  };

  const hasSecondTabError = () => {
      return errors["titleEn"] !== undefined || errors["contenten"] !== undefined;
  };

  const hasThirdTabError = () => {
      return errors["titleRu"] !== undefined || errors["contentru"] !== undefined;
  };

  const getIndicatorColor = () => {
      const tabIndicatorColor = theme.palette.error.main;
      switch (index) {
          case 0:
             if (hasFirstTabError()) return tabIndicatorColor;
              break;
          case 1:
             if (hasSecondTabError()) return tabIndicatorColor;
                break;
          case 2:
              if (hasThirdTabError()) return tabIndicatorColor;
                break;
          default:
              return "";
        }
    };

  const handleIndex = (_, idx) => {
    setIndex(idx);
  };

  const handleErrors = (error) => {
      const errorFields = error.response.data.errors;
      for (const field in errorFields) {
          form.setError(field.toLowerCase());
      }
  }

  const [index, setIndex] = useState(0);

  const updateDate = useSelector(state => state.Cms.faqUpdateDate);
  const fetchFAQGroupItem = async () => {
      try {
          const { data } = await http.backendInstance.get("/api/public/faqitem/" + itemId);
          setGroupitem(data);
      } catch (error) {
          console.log(error);
      }
      finally {
          setIsLoading(false);
      }
  }

  useEffect(() => {
      fetchFAQGroupItem();
  }, [])

  const { titleLv, titleEn, titleRu, contentLv, contentEn, contentRu, attachments } = groupItem || {};

  if (isLoading)  {
      return <CircularProgress />
  }

  return (
      <>
        <Grid item xs={12}>
          <CustomBreadCrumbs links={links} />
        </Grid>
        <Grid item xs={12}>
          <TitleForm
              title={"Labot ierakstu"}
              titleCode={"FRM.INFOCONT.IL"}
              dateChanged={`${moment(updateDate).format("DD.MM.YYYY. HH:mm:ss")}`}
          />
        </Grid>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmitForm)}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabContext value={String(index)}>
                  <Tabs
                      TabIndicatorProps={{
                          style: { backgroundColor: getIndicatorColor() },
                      }}
                      value={index}
                      onChange={handleIndex}
                      aria-label="language tabs diff"
                  >
                    <Tab
                        label={ <Typography color={hasFirstTabError() ? main : ""}>Lv</Typography> }
                        {...a11yProps(0)}
                    />
                    <Tab
                        label={ <Typography color={hasSecondTabError() ? main : ""}>EN</Typography> }
                        {...a11yProps(1)}
                    />
                    <Tab
                        label={ <Typography color={hasThirdTabError() ? main : ""}>RU</Typography> }
                        {...a11yProps(2)}
                    />
                  </Tabs>
                </TabContext>
              </Box>
              <TabPanel value={index} index={0}>
                <FAQCmsGroupItemEditForm
                    ref={contentLvRef}
                    titleName={"titleLv"}
                    contentName={"contentlv"}
                    defaultTitle={titleLv}
                    defaultContent={contentLv}
                />
              </TabPanel>
              <TabPanel value={index} index={1}>
                <FAQCmsGroupItemEditForm
                    ref={contentEnRef}
                    titleName={"titleEn"}
                    contentName={"contenten"}
                    defaultTitle={titleEn}
                    defaultContent={contentEn}
                />
              </TabPanel>
              <TabPanel value={index} index={2}>
                <FAQCmsGroupItemEditForm
                    ref={contentRuRef}
                    titleName={"titleRu"}
                    contentName={"contentru"}
                    defaultTitle={titleRu}
                    defaultContent={contentRu}
                />
              </TabPanel>
              <Grid item xs={12} my={2}>
                <EditGroupFileUpload name={`attachmentField`} defaultFiles={attachments} />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent={"center"} gap={".5rem"}>
                <Button variant="contained" type={"submit"} color="success" onClick={() => form.clearErrors("attachmentField")}>
                  Saglabāt
                </Button>
                <Button variant="text" component={Link} to="/cms/faq">
                  Atcelt
                </Button>
              </Grid>
          </form>
        </FormProvider>
      </>
  );
};

export default FAQEditGroupItem;