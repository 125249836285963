import { ButtonBase, Box, Link, Container } from "@mui/material";
import Logo from "./img/Decide_Logo.png";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import { Footer } from "../footer/footer";
import { Outlet, Link as RouterLink } from "react-router-dom";
import { LanguageDropdown } from "../language/LanguageDropdown";
import { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Fallback = () => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <CircularProgress />
    </div>
  );
};

export const ProfileLayout = () => {
  return (
    <>
      <div
        style={{
          background: "rgba(244, 244, 244, 1)",
          width: "100%",
          padding: "0 100px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex"></Box>
          <Box display="flex" alignItems="center" gap="1rem">
            <ButtonBase>
              <TextIncreaseIcon />
            </ButtonBase>
            <ButtonBase>
              <TextDecreaseIcon />
            </ButtonBase>
            <LanguageDropdown />
          </Box>
        </Box>
      </div>
      <Box
        sx={{
          alignItems: "center",
          height: "60px",
          backgroundColor: "#FFFFFF",
          borderBottom: "1px solid #E3E3E3",
          display: "flex",
          justifyContent: "center",
        }}
        component="header"
      >
        <Box display="flex" justifyContent="center">
          <Link component={RouterLink} to="/">
            <img style={{ height: "32px" }} src={Logo} />
          </Link>
        </Box>
      </Box>
      <Container sx={{ minHeight: "800px" }}>
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </Container>
      <Footer />
    </>
  );
};
