import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useFormContext } from "react-hook-form";
import http from "../api/http";
import React from "react";
import { useTranslation } from "../hooks/useTranslation";
import FormHelperText from "@mui/material/FormHelperText";
import useToken from "../hooks/useToken";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiRoleSelect = ({ inputName, label, defaultValue, requried }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [items, setItems] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  const { ValidationErrorMandatoryField } = useTranslation();

  const { token } = useToken();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItems(typeof value === "string" ? value.split(",") : value);
    setValue(inputName, value, { shouldValidate: true });
  };

  const fetchRoles = async () => {
    const { data } = await http.authInstance.get("/api/user/roles");
    setRoles(data);
  };

  React.useEffect(() => {
    if (defaultValue) fetchRoles();
    setItems(defaultValue);
    setValue(inputName, defaultValue);
  }, [token]);

  return (
    <>
      <FormControl
        size="small"
        fullWidth
        error={errors[inputName] ? true : false}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          {...register(inputName, {
            required: requried ? ValidationErrorMandatoryField : false,
          })}
          fullWidth
          value={items}
          defaultValue={defaultValue}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => {
                const role = roles.find((item) => item.code === value);
                let label = `${role?.nameLv} (${value})`;
                if (value === "PART") label = `Dalībnieks (${value})`;
                return (
                  //Participant role should stay disabled and cannot be modified
                  //If Any Other roles should be disabled it can extracted into dictionary of its own
                  <Chip
                    disabled={value === "PART" ? true : false}
                    key={value}
                    label={label}
                  />
                );
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {roles.map((item) => (
            <MenuItem key={item.id} value={item.code}>
              {`${item.nameLv} (${item.code})`}
            </MenuItem>
          ))}
        </Select>

        <FormHelperText error>
          {errors[inputName] ? errors[inputName].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default MultiRoleSelect;
