import React, { useRef } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import http from "../../../api/http";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import Typography from "@mui/material/Typography";
import { CustomBreadCrumbs, TabPanel, TitleForm, FileUploadInput } from "../../../MUI_components";
import { TabContext } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { a11yProps } from "./AddGroup";
import { MoreInformationCmsAddGroupItemForm } from "./AddGroupItemForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { setMoreInformationGroupsUpdateDate } from "../../../redux/actions/cms";

export const MoreInformationAddGroupItem = () => {

  const form = useForm();

  const { groupId } = useParams();

  const links = [
    { to: "/cms/moreinformation", text: "Satura pārvaldība - Vairāk informācijas" },
    { to: `/cms/moreinformation/group/${groupId}/item/add`, text: "Pievienot ierakstu" }
  ];

  const navigate = useNavigate();

  const updateDate = useSelector(state => state.Cms.moreInformationUpdateDate);

  const dispatch = useDispatch();

  const contentLvRef = useRef(null);
  const contentEnRef = useRef(null);
  const contentRuRef = useRef(null);

  const handleSubmitForm = async (data, event) => {

    const { titlelv, titleen, titleru } = data;

    const formData = new FormData();
    formData.append("titleLv", titlelv);
    formData.append("titleEn", titleen);
    formData.append("titleRu", titleru);
    formData.append("contentLv", contentLvRef.current.getContent() || "");
    formData.append("contentEn", contentEnRef.current.getContent() || "");
    formData.append("contentRu", contentRuRef.current.getContent() || "");
    formData.append("moreInfoGroupId", groupId);

    if (data?.attachmentField)
      for (const file of data?.attachmentField)
        formData.append("attachmentField", file);

    try {
      await http.backendInstance.post("/api/cms/moreinfoitem", formData);
      dispatch(setMoreInformationGroupsUpdateDate(Date.now()));
      navigate("/cms/moreinformation");
    } catch (error) {
      handleErrors(error)
      console.log(error);
    }
  };

  const handleErrors = (error) => {
    const errorFields = error.response.data.errors;
    for (const field in errorFields) {
      form.setError(field.toLowerCase(), { type: 'custom', message: 'Lauks ir obligāts' });
    }
  }

  const [index, setIndex] = useState(0);

  const handleIndexChange = (_, idx) => {
    setIndex(idx);
  };

  const theme = useTheme();

  const { main } = theme.palette.error;

  const { formState: { errors } } = form;
  const hasFirstTabError = () => {
    return errors["titlelv"] !== undefined || errors["contentlv"] !== undefined;
  };

  const hasSecondTabError = () => {
    return errors["titleen"] !== undefined || errors["contenten"] !== undefined;
  };

  const hasThirdTabError = () => {
    return errors["titleru"] !== undefined || errors["contentru"] !== undefined;
  };

  const getIndicatorColor = () => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (index) {
      case 0:
        if (hasFirstTabError()) return tabIndicatorColor;
        break;
      case 1:
        if (hasSecondTabError()) return tabIndicatorColor;
        break;
      case 2:
        if (hasThirdTabError()) return tabIndicatorColor;
        break;
      default:
        return "";
    }
  };

  return (
      <>
        <Box display={'flex'} alignItems={'flex-start'}>
          <CustomBreadCrumbs links={links}  />
        </Box>
        <Grid item xs={12}>
          <TitleForm
              title={"Pievienot ierakstu"}
              titleCode={"FRM.INFOCONT.IL"}
              dateChanged={`${moment(updateDate).format("DD.MM.YYYY. HH:mm:ss")}`}
          />
        </Grid>
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <FormProvider {...form}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabContext value={String(index)}>
                <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: getIndicatorColor() },
                    }}
                    value={index}
                    onChange={handleIndexChange}
                    aria-label="language tabs diff"
                >
                  <Tab
                      label={ <Typography color={hasFirstTabError() ? main : ""}>Lv</Typography> }
                      {...a11yProps(0)} />
                  <Tab
                      label={ <Typography color={hasSecondTabError() ? main : ""}>En</Typography> }
                      {...a11yProps(1)} />
                  <Tab
                      label={ <Typography color={hasThirdTabError() ? main : ""}>Ru</Typography> }
                      {...a11yProps(2)} />
                </Tabs>
              </TabContext>
            </Box>
            <input hidden {...form.register("moreInfoGroupId")} value={groupId} />
            <TabPanel value={index} index={0}>
              <MoreInformationCmsAddGroupItemForm
                  titleName={"titlelv"}
                  contentName={"contentlv"}
                  contentRef={contentLvRef}
              />
            </TabPanel>
            <TabPanel value={index} index={1}>
              <MoreInformationCmsAddGroupItemForm
                  titleName={"titleen"}
                  contentName={"contenten"}
                  contentRef={contentEnRef}
              />
            </TabPanel>
            <TabPanel value={index} index={2}>
              <MoreInformationCmsAddGroupItemForm
                  titleName={"titleru"}
                  contentName={"contentru"}
                  contentRef={contentRuRef}
              />
            </TabPanel>
            <Grid item xs={12} my={2}>
              <FileUploadInput name={"attachmentField"} />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"center"} gap={"1rem"}>
              <Button variant="contained" type={"submit"} color="success" onClick={() => form.clearErrors("attachmentField")}>
                Saglabāt
              </Button>
              <Button variant="text" component={Link} to="/cms/moreinformation">
                Atcelt
              </Button>
            </Grid>
          </FormProvider>
        </form>
      </>
  );
};
