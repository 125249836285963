import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import * as PropTypes from "prop-types";

const DeleteGroupItemDialog = ({onClick, onClose, open}) => {
    return <Dialog
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle variant="h4" id="alert-dialog-title">
            {"Dzēst?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {"Vai tiešām velaties dzēst šo ierakstu?"}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClick}>Turpināt</Button>
            <Button onClick={onClose} autoFocus>
                Atcelt
            </Button>
        </DialogActions>
    </Dialog>;
};

DeleteGroupItemDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func
};

export default DeleteGroupItemDialog;