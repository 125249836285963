import { Navigate, useLocation, Outlet } from "react-router-dom";

export const PrivateRoute = ({ children, userRole, allowedRole }) => {
  const location = useLocation();

  const isAuthenticatedStorage = sessionStorage.getItem("isAuthenticated");

  if (!isAuthenticatedStorage) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (userRole?.name !== allowedRole)
    return <Navigate to="/" state={{ from: location }} replace />;

  return children ? children : <Outlet />;
};
