import { useFormContext, get } from "react-hook-form";
import { useSelector } from "react-redux";
import { translation } from "../redux/selectors/translation";
import React from "react";
import { TextField, Box, Grid, Typography, FormHelperText }from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TextInputField = ({
  name,
  defaultValue,
  disabled,
  label,
  labelProps,
  isRequired,
  validationRules,
  shrink,
  value,
  type,
  inputProps,
  rows,
  testId,
  shouldUnregister,
  maxCharLength,
  onInput,
  autocomplete,
  title,
  ...props
}) => {
  const { register, formState } = useFormContext();
  const theme = useTheme();
  const primaryTextColor = theme.palette.text.primary;
  const errorColor = theme.palette.error.main;

  const [readonly, setReadonly] = React.useState(true);

  const error = get(formState.errors, name);

  const translations = useSelector(translation);

  const requiredErrorMessage =
    translations.find((x) => x.key === "ValidationErrorMandatoryField")
      ?.value || "Lauks ir obligāts";

  //there is only one way to disable autocomplete in MUI TextField - set readonly to true on blur
  //and then set it to false onFocus
  //https://stackoverflow.com/questions/72044394/how-do-i-disable-textfield-input-with-mui-autocomplete
  //read this thread to better understand why this is needed
  const handleOnFocus = () => {
    if (autocomplete === "off")
      setReadonly(false);
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
      >
        {title &&
          <Grid item >
            <Typography sx={{ color: error ? errorColor : primaryTextColor }}>
              {title}
            </Typography>
          </Grid>
        }
        <Grid item>
          <TextField
            autoComplete={autocomplete}
            size="small"
            label={label}
            error={error ? true : false}
            onInput={onInput}
            disabled={disabled}
            defaultValue={defaultValue}
            fullWidth
            InputLabelProps={{ ...labelProps, shrink: shrink }}
            onFocus={handleOnFocus}

            inputProps={{
              ...inputProps,
              "data-testid": testId,
              maxLength: maxCharLength ? maxCharLength : "524288",
              readOnly: autocomplete === "off" ? readonly : false
            }}
            //neccessary to simulate textarea
            multiline={rows > 1}
            rows={rows}
            {...register(name, {
              ...validationRules,
              required: isRequired === true ? requiredErrorMessage : false,
              shouldUnregister: shouldUnregister ? shouldUnregister : false,
              disabled: disabled ? disabled : false
            })}
            type={type}
            value={value}
            {...props}
            helperText={
              error ? (
                <FormHelperText error component={"span"}>
                  {error?.message}
                </FormHelperText>
              ) : null
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextInputField;
