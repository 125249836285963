import React from "react";
import Grid from "@mui/material/Grid";
import { TextInputField } from "../../../MUI_components";
import { TextEditorTinyMce } from "../../../components/richTextEditor/TextEditor";

export const MoreInformationCmsAddGroupItemForm = ({ titleName, contentName, contentRef }) => {

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextInputField
          name={titleName}
          label={"Virsraksts*"}
          maxCharLength={100}
        />
      </Grid>
      <Grid item xs={12} my={4}>
          <TextEditorTinyMce
              name={contentName}
              hasImageUpload={true}
              ref={contentRef}
          />
      </Grid>
    </Grid>
  );
};
