const MessageReceiverTypeEnum = Object.freeze({
  ListOfEmployees: "LISTOFEMPLOYEES",
  ListOfParticipants: "LISTOFPARTICIPANTS",
  All: "ALL",
  Research: "RESEARCH",
});

const MessageReceiverTypeMapLV = new Map([
  [MessageReceiverTypeEnum.ListOfEmployees, "Darbiniekiem"],
  [MessageReceiverTypeEnum.ListOfParticipants, "Dalībniekiem"],
  [MessageReceiverTypeEnum.All, "Visiem"],
  [MessageReceiverTypeEnum.Research, "Pētījuma dalībniekiem"],
]);

export { MessageReceiverTypeEnum, MessageReceiverTypeMapLV };
