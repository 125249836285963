import React from "react";
import { useLocation } from "react-router-dom";

const Hoverable = ({ pathName, children }) => {
  const location = useLocation();

  return (
    <div
      className={
        location.pathname === pathName
          ? `dropdown-link dropdown-link--active`
          : "dropdown-link"
      }
    >
      {children}
    </div>
  );
};

export default Hoverable;
