import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Controller, useFormContext, get } from "react-hook-form";
import { useTranslation } from "../hooks/useTranslation";
import publicService from "../services/public-service";

const PhoneCountryCodeSelect = ({
  name,
  defaultValue,
  required,
  fullWidth,
  shouldUnregister,
  label,
}) => {
  const { formState, control } = useFormContext();

  const { ValidationErrorMandatoryField } =
    useTranslation() || "Lauks ir obligāts";

  const error = get(formState.errors, name);

  const [countryCodes, setCountryCodes] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    const fetchCountryCodes = async () => {
      try {
        const phoneCountryCodes = await publicService.getContactUsPhoneCountryCodes(abortController.signal);
        setCountryCodes(phoneCountryCodes);
      } catch (error) {
        if (error.name !== "AbortError") console.error(error?.message);
      }
    };

    fetchCountryCodes();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <FormControl
      size="small"
      error={!!error}
      fullWidth={!!fullWidth}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: required ? ValidationErrorMandatoryField : false,
          shouldUnregister: !!shouldUnregister,
        }}
        render={({ field }) => (
          <Select {...field} label={label} defaultValue={defaultValue} size="small" fullWidth>
            {countryCodes.map((code) => (
              <MenuItem key={code.id} value={code.nameLv}>
                {code.nameLv}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText>{error ? error.message : ""}</FormHelperText>
    </FormControl>
  );
};

export default PhoneCountryCodeSelect;
