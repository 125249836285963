import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const BasicModal = React.forwardRef(
  ({ modelOpen, title, body, handleSubmitData, handleModalClose }, ref) => {
    return (
      <Modal
        open={modelOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Fade in={modelOpen}>
            <Box sx={style}>
              <Box display="flex" justifyContent="center">
                <Typography id="modal-modal-title" variant="h4">
                  {title}
                </Typography>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                padding="0 1rem"
                justifyContent="center"
              >
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {body}
                </Typography>
              </Box>
              <Box mt="1rem" display="flex" gap="1rem" justifyContent="center">
                <Button
                  onClick={handleSubmitData}
                  variant="contained"
                  color="primary"
                >
                  Turpināt
                </Button>
                <Button onClick={handleModalClose} variant="outlined">
                  Atcelt
                </Button>
              </Box>
            </Box>
          </Fade>
        </div>
      </Modal>
    );
  }
);

export default BasicModal;
