const SenderTypeEnum = Object.freeze({
  Administrator: "ADM",
  Researcher: "RES",
  System: "SYSTEM",
});

const SenderTypeMapLV = new Map([
  [SenderTypeEnum.Administrator, "Administrators"],
  [SenderTypeEnum.Researcher, "Pētnieks"],
  [SenderTypeEnum.System, "Sistēma"],
]);

export { SenderTypeEnum, SenderTypeMapLV };
