import {useState, useEffect} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormContext } from "react-hook-form";
import { SystemNotificationPublicationPlaceEnum, SystemNotificationPublicationPlaceEnumMapLV } from "../enums";

const SysNotificationPublicationPlaceDropdown = ({
  name,
  label,
  defaultValue,
  required,
}) => {
  const {
    formState: { errors },
    setValue,
    register,
    clearErrors
  } = useFormContext({ [name]: "" });

  const [valueOption, setOptionValue] = useState(null);
  const [options, setOptions] = useState([]);

  const handleChange = (event, optionValue) => {
    if (!optionValue) return;
    setOptionValue(optionValue);
    setValue(name, optionValue.id);
    clearErrors(name);
  };

  //need to wrap it around <Controller ... /> component
  useEffect(() => {
    const notificationTypes = Object.values(SystemNotificationPublicationPlaceEnum).map(value => {
      return {
        id: value,
        label: SystemNotificationPublicationPlaceEnumMapLV.get(value)
      }
    });

    setOptions(notificationTypes);
    if (defaultValue) {
      const defaultOption = notificationTypes.find((x) => x.id === defaultValue);
      setOptionValue(defaultOption);
      setValue(name, defaultValue);
    }
    register(name, {
      required: required ? "Lauks ir obligāts" : false,
    });
  }, []);
  return (
    <>
      <Autocomplete
        disablePortal
        id="sys-notification-places-combo-box"
        size="small"
        onChange={handleChange}
        defaultValue={valueOption}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option.label || ""}
        options={options}
        value={valueOption}
        renderInput={(params) => (
          <TextField
            {...params}
            error={errors[name] ? true : false}
            label={label}
          />
        )}
      />
      <FormHelperText error>
        {errors[name] ? errors[name]?.message : ""}
      </FormHelperText>
    </>
  );
};

export default SysNotificationPublicationPlaceDropdown;
