import http from "../api/http";
import {
  setAccessToken,
  setAccessRights,
  setAuthenticated,
  setAssignedGroupsCode,
} from "../redux/actions/auth";
import { setBearerHeader } from "../api/auth/auth";
import { SetStorageAuthenticated, SetStorageRole } from "../api/utility";
import { setTokenLifeTime } from "../redux/actions/auth";
import { useSelector } from "react-redux";
import { userAccessToken } from "../redux/selectors/authSelector";
import useSnackbar from "./useSnackbar";

const useToken = () => {
  const token = useSelector(userAccessToken);

  const rights = useSelector((state) => state.Auth.accessRights);
  
  const groupsAssigned = useSelector((state) => state.Auth.assignedGroupsCode);

  const { setMessageAndOpenSnackbar } = useSnackbar();

  const GetAccessToken =
    (personId = null) =>
    async (dispatch, getState) => {
      const code = getState().Auth.role.code;
      const roleName = getState().Auth.role.name;
      try {
        const { data } = await http.authInstance.post("/api/auth/accessToken", {
          code,
          currentPersonId: personId ? personId : null,
        });
        dispatch(setAccessToken(data.accessToken));
        dispatch(setAccessRights(data.rights));
        dispatch(setTokenLifeTime(data.tokenLifeTime));
        dispatch(setAuthenticated(true));
        setBearerHeader(data.accessToken);
        SetStorageRole(roleName);
        SetStorageAuthenticated(true);
      } catch (error) {
        if (error.response?.status === 401) {
          setMessageAndOpenSnackbar("Session Expired");
        }
        console.error(error);
      }
    };
  const RefreshAccessToken = () => async (dispatch) => {
    try {
      const { data } = await http.authInstance.get("/api/auth/refreshToken");
      const token = data.accessToken;
      setBearerHeader(token);
      dispatch(setAccessToken(token));
      dispatch(setAccessRights(data.rights));
      dispatch(setTokenLifeTime(data.tokenLifeTime));
      dispatch(setAuthenticated(true));
      dispatch(setAssignedGroupsCode(data.assignedGroupCodes));
    } catch (error) {
      console.log(error);
    }
  };

  return { GetAccessToken, RefreshAccessToken, token, rights, groupsAssigned };
};

export default useToken;
