import { Grid, Box, Pagination, Typography} from "@mui/material";
import SysNotification from "./SysNotification";

const SysNotificationList = ({
  sysNotifications,
  notificationListTotalPageCount,
  notificationListPage,
  handlePaginationChange,
  withEmptyMessage = false,
  handleNeedUpdate,
  readOnly = false
}) => {
  function showEmptyMessage() {
    return !sysNotifications.length &&  withEmptyMessage;
  }  
  return (
      <Box sx={{borderColor: "1px solid green"}}>
        {showEmptyMessage() &&
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography>
                {"Nav paziņojumu"}
              </Typography>
            </Grid>
          </Grid>
          }
        {(sysNotifications.length? true : false) &&
          <Grid container direction="column" gap={2}>
            <Grid container item direction="column" gap={2}>
              {sysNotifications.map(sysNotification =>
                <Grid
                  item
                  key={sysNotification.id}
                >
                  <SysNotification
                    notification={sysNotification}
                    handleNeedToUpdate={handleNeedUpdate}
                    readOnly={readOnly}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Pagination
                count={notificationListTotalPageCount}
                page={notificationListPage}
                onChange={handlePaginationChange}
              />
            </Grid>
          </Grid>
        }
      </Box>
    );
}
 
export default SysNotificationList;