import React, { useState } from 'react';
export const SelectedRowContext = React.createContext({});

export const SelectedRowProvider = ({ children }) => {
  const [selectedRow, setSelectedRow] = useState();

  return (
    <SelectedRowContext.Provider value={{ selectedRow, setSelectedRow }}>
      {children}
    </SelectedRowContext.Provider>
  );
};

export default SelectedRowContext;