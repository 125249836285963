import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const OutlinedBox = ({ children, title, error }) => {
  const theme = useTheme();
  const defaultBorderColor = theme.palette.grey[500];
  const errorBorderColor = theme.palette.error.main;
  return (
    <Box
      sx={{
        border: error ? `1px solid ${errorBorderColor}` : `1px solid ${defaultBorderColor}`,
        borderRadius: 1,
        position: "relative",
        p: 2,
        mt: 2,
      }}
    >
      <Typography
        sx={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          background: "#ffffff",
          padding: "0 10px",
          color: error ? errorBorderColor : "none"
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default OutlinedBox;
