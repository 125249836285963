import { FormControlLabel, FormHelperText, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const ControlledCheckbox = ({
  name,
  label,
  required,
  defaultValue = false,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        rules={{ required: required ? true : false }}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            }
            label={
              <Typography fontSize={14} color={errors[name] ? "red" : ""}>
                {label}
              </Typography>
            }
          />
        )}
      />
      <FormHelperText error>{errors[name]?.message}</FormHelperText>
    </>
  );
};

export default ControlledCheckbox;
