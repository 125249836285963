import {
  Grid,
  Box,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography
} from "@mui/material";

const PermissionElement = ({
  permission,
  consentPermission = null
}) => {

  function isConsentPermission() {
    return Boolean(consentPermission);
  }

  function permissionQuestion() {
    return isConsentPermission() ? consentPermission?.permissionLv : permission.permissionBmcStatement.statementText;
  }

  return (
    <Box mt={2}>
      <Grid
        container
        direction="column"
        gap={2}
      >
        {/* permission text */}
        <Grid item>
          <Typography
            paragraph
            sx={{ mb: 0, textWrap: "balance", whiteSpace: "normal", align: "justify"}}
          >
            {permissionQuestion()}
          </Typography>
        </Grid>
        {/* permission responses */}
        <Grid item sx={{ ml: 2 }}>
          {renderResponses(
            isConsentPermission() ? consentPermission : permission,
            isConsentPermission() ? consentPermission.consentPermissionResponses : permission.permissionResponses,
            isConsentPermission()
          )}
        </Grid>
      </Grid>
  </Box>
  );
};

function renderResponses(permission, responses, isConsentPermission) {
  if (!permission)
    return <Typography>{"Nevar parādīt saturu"}</Typography>;
  
  if (!permission.withResponses)
    return;

  if (permission.multipleResponsesAllowed)
    return (
      <>
        {getCheckBoxGroupPermission(responses, isConsentPermission)}
      </>
    );
  else if (!permission.multipleResponsesAllowed)
    return (<>{getRadioGroupPermission(responses, isConsentPermission)}</>);
}

function getCheckBoxGroupPermission(responses, isConsentPermission) {
  return (
    <Box>
      {responses.sort((x, y) => x.position < y.position).map((response) => {
        return (
          <Grid container key={response.id} direction="column" gap={1}>
            <Grid item container spacing={2}>
              <Grid item sx={{ mb: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={false} />
                  }
                  label={isConsentPermission ?  response.nameLv : response.responseText}
                  disabled
                />
              </Grid>
              {response.withRemark && (
                <Grid item>
                  <TextField
                    placeholder={""}
                    disabled
                    size="small"
                  />
                  </Grid>
                )}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
}

function getRadioGroupPermission(responses, isConsentPermission) {
  return (
    <Box>
      <RadioGroup>
        {responses.sort((x, y) => x.position < y.position).map((response) => (
          <div
            key={response.id}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              container
              spacing={1}
            >
              <Grid item>
                <FormControlLabel
                  value={response.id.toString()}
                  control={<Radio disabled />}
                  label={isConsentPermission ?  response.nameLv : response.responseText}
                />
              </Grid>
              {response.withRemark &&
              <Grid item>
                <TextField
                  placeholder={""}
                  disabled
                  size="small"
                />
              </Grid>
              }
            </Grid>
          </div>
        ))}
      </RadioGroup>
    </Box>
  );
}

export default PermissionElement;
