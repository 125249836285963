import { BaseButton } from "../index";
import useFileDownloader from "../../hooks/useFileDownloader"

const FileAttachmentButton = ({ attachment, withTimestamp = false }) => {
  const { downloadFile } = useFileDownloader();

  return (
    <BaseButton
      onClick={() => downloadFile(attachment.fileId, attachment.fileName, withTimestamp)}
      variant="text"
      color="primary"
    >
      {attachment.fileName}
    </BaseButton>
  );
};

export default FileAttachmentButton;
