import { useSelector } from "react-redux";
import { userRole } from "../redux/selectors/authSelector";

const useRole = () => {
  const role = useSelector(userRole);

  return { role };
};

export default useRole;
