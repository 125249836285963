import MenuItem from "@mui/material/MenuItem";
import React from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { GetTranslation } from "../../api/translations/translation";
import { setLanguage } from "../../redux/actions/translation";
import LvFlag from "./img/LV_flag.svg";
import UkFlag from "./img/UK_flag.svg";
import RuFlag from "./img/RU_flag.svg";

const styles = {
  display: "flex",
  alignItems: "center",
  flagIcon: {
    marginRight: "8px",
    display: "flex",
  },
  flagIconSize: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
};

export const LanguageDropdown = () => {
  const language = useSelector((x) => x.Translation.language);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(setLanguage(event.target.value));
    dispatch(GetTranslation(event.target.value));
  };

  React.useEffect(() => {
    dispatch(GetTranslation(language));
  }, [dispatch, language]);

  return (
    <FormControl sx={{ m: 1 }}>
      <Select
        variant="standard"
        labelId="language-dropdown-label"
        id="language-dropdown"
        value={language}
        defaultValue={language}
        SelectDisplayProps={{ style: styles }}
        onChange={handleChange}
        autoWidth
      >
        <MenuItem value={"Lv"}>
          <div style={styles.flagIcon}>
            <img style={styles.flagIconSize} src={LvFlag} alt="LV-flag" />
          </div>
          <Typography variant="h6">Latviešu</Typography>
        </MenuItem>
        <MenuItem value={"En"}>
          <div style={styles.flagIcon}>
            <img style={styles.flagIconSize} src={UkFlag} alt="UK-flag" />
          </div>
          <Typography variant="h6">English</Typography>
        </MenuItem>
        <MenuItem value={"Ru"}>
          <div style={styles.flagIcon}>
            <img style={styles.flagIconSize} src={RuFlag} alt="RU-flag" />
          </div>
          <Typography variant="h6">Русский</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
