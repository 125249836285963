import Box from "@mui/material/Box";
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      <Box p={2}>
        <span>{children}</span>
      </Box>
    </div>
  );
};

export default TabPanel;
