export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NUMBER_OF_UNREAD_MESSAGES = "SET_NUMBER_OF_UNREAD_MESSAGES";

export const setNotifications = (payload) => ({
  type: SET_NOTIFICATIONS,
  payload: payload,
});

export const setNumberOfUnreadMessages = (payload) => ({
  type: SET_NUMBER_OF_UNREAD_MESSAGES,
  payload: payload,
});
