import { Grid } from "@mui/material";
import FileAttachmentButton from "../buttons/FileAttachmentButton";

const FileAttachmentsRow = ({ attachments, withTimestamp = false }) => {
  return (
    <Grid
      container
      spacing={2}
    >
      {attachments && attachments?.length &&
        attachments.map((attachment) => (
          <Grid
            item
            key={attachment.id}
          >
              <FileAttachmentButton
                attachment={attachment}
                withTimestamp={withTimestamp}
              />
          </Grid>
        ))}
    </Grid>
  );
};

export default FileAttachmentsRow;
