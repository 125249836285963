const SystemNotificationPublicationPlaceEnum = Object.freeze({
  AdminDashboard: 1,
  MedicDashboard: 2,
  ParticipantDashboard: 3,
  ResearcherDashboard: 4,
  OnlyOnHomePage: 5,
  AllPlaces: 6,
});

const SystemNotificationPublicationPlaceEnumMapLV = new Map([
  [
    SystemNotificationPublicationPlaceEnum.AdminDashboard,
    "Administratoru darba virsmā",
  ],
  [
    SystemNotificationPublicationPlaceEnum.MedicDashboard,
    "Ārstu/Medmāsu darba virsmā",
  ],
  [
    SystemNotificationPublicationPlaceEnum.ParticipantDashboard,
    "Dalībnieku darba virsmā",
  ],
  [
    SystemNotificationPublicationPlaceEnum.ResearcherDashboard,
    "Pētnieku darba virsmā",
  ],
  [SystemNotificationPublicationPlaceEnum.OnlyOnHomePage, "Sākumlapā"],
  [SystemNotificationPublicationPlaceEnum.AllPlaces, "Visur"],
]);

export {
  SystemNotificationPublicationPlaceEnum,
  SystemNotificationPublicationPlaceEnumMapLV
};
