import {
  LOAD_FAILURE,
  LOAD_USERGROUPS,
  LOAD_USERRIGHTS,
  LOAD_USER_ROLES,
  UNLOAD_USERGROUPS,
  UNLOAD_USERRIGHTS,
  LOAD_USER_SETTINGS,
  UNLOAD_USER_SETTINGS,
  LOAD_USERGROUP_RIGHTS,
  UNLOAD_USERGROUP_RIGHTS,
  LOAD_GROUPUSERS,
  UNLOAD_GROUPUSERS,
} from "../actions/userGroup";

const initialState = {
  userGroups: [],
  userRights: [],
  userRoles: [],
  userSettings: [],
  userGroupRights: [],
  groupUsers: [],
  error: null,
};

export const UserGroup = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERGROUPS:
      return { ...state, userGroups: action.payload };
    case LOAD_USER_ROLES:
      return { ...state, userRoles: action.payload };
    case UNLOAD_USERGROUPS:
      return { ...state, userGroups: [] };
    case LOAD_FAILURE:
      return { ...state, error: action.payload };
    case LOAD_USERRIGHTS:
      return { ...state, userRights: action.payload };
    case UNLOAD_USERRIGHTS:
      return { ...state, userRights: [] };
    case LOAD_USERGROUP_RIGHTS:
      return { ...state, userGroupRights: action.payload };
    case UNLOAD_USERGROUP_RIGHTS:
      return { ...state, userGroupRights: [] };
    case LOAD_USER_SETTINGS:
      return { ...state, userSettings: action.payload };
    case UNLOAD_USER_SETTINGS:
      return { ...state, userSettings: [] };
    case LOAD_GROUPUSERS:
      return { ...state, groupUsers: action.payload };
    case UNLOAD_GROUPUSERS:
      return { ...state, groupUsers: [] };
    default:
      return state;
  }
};
