import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import http from "../../api/http";
import useRightTranslation from "../../hooks/useRightTranslation";
import { TitleForm } from "../../MUI_components";
import ConsentClassifier from "./ConsentClassifier";
import Button from "@mui/material/Button";

const dummyConsentClassifiers = [
  {
    id: 1,
    type: "CHECKBOX",
    additionalText: "",
    consentNameLv: "consent lv",
    consentNameEn: "consent en",
    consentNameRu: "consent ru",
    firstChoiceLv: "f choice lv",
    firstChoiceEn: "f choice en",
    firstChoiceRu: "f choice ru",
    secondChoiceLv: "s choice lv",
    secondChoiceEn: "s choice en",
    secondChoiceRu: "s choice ru",
    thirdChoiceLv: "t choice lv",
    thirdChoiceEn: "t choice en",
    thirdChoiceRu: "t choice ru",
    obligatory: true,
  },
  {
    id: 2,
    type: "CHECKBOX",
    additionalText: "",
    consentNameLv: "consent lv 1",
    consentNameEn: "consent en 1",
    consentNameRu: "consent ru 1",
    firstChoiceLv: "f choice lv 1",
    firstChoiceEn: "f choice en 1",
    firstChoiceRu: "f choice ru 1",
    secondChoiceLv: "s choice lv 1",
    secondChoiceEn: "s choice en 1",
    secondChoiceRu: "s choice ru 1",
    thirdChoiceLv: "t choice lv 1",
    thirdChoiceEn: "t choice en 1",
    thirdChoiceRu: "t choice ru 1",
    obligatory: true,
  },
  {
    id: 3,
    type: "RADIOBUTTON",
    additionalText: "",
    consentNameLv: "consent lv 1",
    consentNameEn: "consent en 1",
    consentNameRu: "consent ru 1",
    firstChoiceLv: "f choice lv 1",
    firstChoiceEn: "f choice en 1",
    firstChoiceRu: "f choice ru 1",
    secondChoiceLv: "s choice lv 1",
    secondChoiceEn: "s choice en 1",
    secondChoiceRu: "s choice ru 1",
    thirdChoiceLv: "t choice lv 1",
    thirdChoiceEn: "t choice en 1",
    thirdChoiceRu: "t choice ru 1",
    obligatory: true,
  },
];

function ConsentResearch({
  id,
  titleLv,
  titleEn,
  titleRu,
  contentLv,
  contentEn,
  contentRu,
}) {
  const form = useForm();

  const [consentClassifiers, setConsentClassifiers] = useState([]);

  const { GetRightTranslation } = useRightTranslation();

  const fetchConsentClassifiers = async () => {
    try {
      const { data } = await http.backendInstance.get(`/api/consent/${id}/`);
      setConsentClassifiers(data);
    } catch (error) {
      console.log(error);
    }
  };

  const submitConsentResearch = (data, e) => {
    console.log(data);
  };

  React.useEffect(() => {
    //fetchConsentClassifiers();
  }, []);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitConsentResearch)}>
          <Grid xs={12} item>
            <TitleForm title={"Informācija"} />
          </Grid>
          <Grid xs={12} item justifyContent={"center"} display="flex">
            <Typography variant="h2">
              {GetRightTranslation(titleLv, titleEn, titleRu)}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography>
              {GetRightTranslation(contentLv, contentEn, contentRu)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/*                     { consentClassifiers.map(item => (
                            <ConsentClassifier {...item} key={item.id} />
                        ))} */}
            {dummyConsentClassifiers.map((item) => (
              <ConsentClassifier {...item} key={item.id} />
            ))}
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              my={3}
              gap="1rem"
              display="flex"
              justifyContent={"center"}
            >
              <Button type="submit" variant="contained" color="success">
                Iesniegt
              </Button>
              <Button>Atcelt</Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}

export default ConsentResearch;
