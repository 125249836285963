import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import { setLogoutDialogOpen } from "../../redux/actions/auth";

const LogoutDialog = () => {

    const open = useSelector(state => state.Auth.logoutDialogOpen);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setLogoutDialogOpen(false));
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogTitle id="logout-dialog-title">
                    {"Jūsu sesija ir pārtraukta!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        {"Paldies, ka izmantojāt DECIDE!" }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LogoutDialog;