import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext, Controller } from "react-hook-form";
import React from "react";
import useClassifier from "../hooks/useClassifier";
import useToken from "../hooks/useToken";

const StatusSelect = ({ name, defaultValue, required }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { GetStatusClassifier, groupClassifiers } = useClassifier();

  const { token } = useToken();

  React.useEffect(() => {
    GetStatusClassifier();
  }, [token]);

  return (
    <>
      <FormControl size="small" error={errors.hasOwnProperty(name)} fullWidth>
        <InputLabel>Statuss</InputLabel>
        <Controller
          rules={{ required: required ? "Lauks ir obligāts" : false }}
          control={control}
          defaultValue={defaultValue ?? ""}
          name={name}
          render={({ field }) => (
            <Select autoWidth label={"Statuss"} defaultValue={""} {...field}>
              {groupClassifiers.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.code}>
                    {item.nameLv}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        <FormHelperText error>
          {errors.hasOwnProperty(name) ? errors[name].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default StatusSelect;
