import React, { useEffect, useState } from "react";
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
  TextField,
  Autocomplete
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import SubtopicSelect from "./SubtopicSelect";
import ResearchSelect from "./ResearchSelect";
import { useTranslation } from "../hooks/useTranslation";
import publicService from "../services/public-service";
import researchService from "../services/research-service";
import { ResearchStatusEnum } from "../enums";

const TopicSelect = ({ name, required }) => {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    unregister,
    clearErrors,
    formState,
    watch
  } = useFormContext();
  const [topics, setTopics] = useState([]);
  const watchResearchId = watch("researchId", null);

  const [showResearchSelectField, setShowResearchSelectField] = useState(false);
  const [researchSelectListItems, setResearchSelectListItems] = useState([]);
  const [autocompleteSelectedSelectItem, setAutocompleteSelectedSelectItem] =
    useState("");
  const { ValidationErrorMandatoryField } =
    useTranslation() || "Lauks ir obligāts";

  useEffect(() => {
    const abortController = new AbortController();
    const fetchTopics = async () => {
      try {
        const contactUsTopics = await publicService.getContactUsTopics(abortController.signal);
        setTopics(contactUsTopics);
      } catch (error) {
        if (error.name !== "AbortError")
          console.error(error?.mesage);
      }
    };

    fetchTopics();

    return () => {
      abortController.abort();
    };

  }, []);

  useEffect(() => {
    if (!watchResearchId)
      setAutocompleteSelectedSelectItem("");

  }, [watchResearchId])


  async function fetchResearches(researchTitleSearchText) {
    if (!researchTitleSearchText)
      return;
    try {
      const permissionsSelectListItemsData =
        await researchService.getFromSearch({
          searchTitle: researchTitleSearchText,
          status: ResearchStatusEnum.Published
        });
      setResearchSelectListItems(permissionsSelectListItemsData);
    } catch (error) {
      console.error(error?.message);
    }
  }

  const handleResearchAutoCompleteChange = (event, research) => {
    if (!research)
      setValue("researchId", null);

    if (research?.value) {
      setValue("researchId", research.value);
      clearErrors("researchId");
    }

    setAutocompleteSelectedSelectItem(research);
  }

  const handleAutoCompleteInputChange = async (event, value, reason) => {
    if (value) await fetchResearches(value);
  }

  return (
    <>
      <Grid
        item
        container
        direction="column"
        gap={2}
      >
        <Grid item>
          <FormControl size="small" error={!!errors[name]} fullWidth>
            <InputLabel>Ziņojuma tēma*</InputLabel>
            <Controller
              name={name}
              control={control}
              defaultValue={""}
              rules={{ required: required ? ValidationErrorMandatoryField : false }}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Ziņojuma tēma*"
                  defaultValue={""}
                  onChange={(e) => {
                    field.onChange(e);
                    switch (e.target.value) {
                      case "RESEARCH":
                        register("researchId", {
                          required: "Lauks ir obligāts"
                        });
                        setShowResearchSelectField(true);
                        break;
                      case "OTHER":
                        setShowResearchSelectField(true);
                        unregister("researchId");
                        break;
                      default:
                        setShowResearchSelectField(false);
                        setValue("researchId", null);
                        unregister("researchId");
                        break;
                    }
                  }}
                  fullWidth
                >
                  {topics.map((option) => (
                    <MenuItem key={option.id} value={option.code}>
                      {option.nameLv}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            {errors[name] && (
              <FormHelperText error>{errors[name]?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item>
          <SubtopicSelect name="subTopic" />
        </Grid>
        {showResearchSelectField &&
          <Grid item>
            <FormControl
              size="small"
              fullWidth
            >
              <Autocomplete
                freeSolo
                size="small"
                onChange={handleResearchAutoCompleteChange}
                options={researchSelectListItems || ""}
                noOptionsText={"Nav ierakstu"}
                onInputChange={handleAutoCompleteInputChange}
                value={autocompleteSelectedSelectItem}
                getOptionLabel={(option) => option?.text || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors["researchId"]}
                    label="Publiska pētījuma nosaukums"
                    helperText={
                      errors["researchId"] && (
                        <FormHelperText
                          error
                          component={"span"}
                        >
                          {errors["researchId"]?.message}
                        </FormHelperText>
                      )
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
        }
      </Grid>
    </>
  );
};

export default TopicSelect;
