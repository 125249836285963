import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const TitleForm = ({
  title,
  titleCode,
  isTitleBigger,
  dateChanged,
  noDivider,
  ...props
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          ...props,
        }}
      >
        {isTitleBigger ? (
          <Typography variant="h3">{title}</Typography>
        ) : (
          <Typography variant="h4">{title}</Typography>
        )}
        <Box display="flex" gap="1rem">
          {dateChanged ? (
            <Typography variant="h6">Rediģēts: {dateChanged}</Typography>
          ) : null}
          {titleCode && <Typography color="#BDBDBD" variant="h6">
            {titleCode}
          </Typography>}
        </Box>
      </Box>
      {!noDivider && <Divider />}
    </>
  );
};

export default TitleForm;
