const ReferralStatusEnum = Object.freeze({
    Active: "ACTIVE",
    Draft: "DRAFT",
    Deleted: "DELETED"
});

const ReferralStatusMapLV = new Map([
    [ReferralStatusEnum.Active, "Aktīvs"],
    [ReferralStatusEnum.Inactive, "Sagatave"],
    [ReferralStatusEnum.Deleted, "Dzēsts"],
]);

export {
    ReferralStatusEnum,
    ReferralStatusMapLV
};
