import { Grid, CircularProgress } from "@mui/material";

const LoadingStub = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <CircularProgress size={50} color="primary" />
      </Grid>
    </Grid>
  );
};

export default LoadingStub;
