import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { BaseButton, OutlinedBox } from "../index";
import { TitleForm } from "../../MUI_components";
import timeUtil from "../../utils/time-util";

import parse from "html-react-parser";

import useRole from "../../hooks/useRole";

import {
  InformedConsentStatusMapLV,
  InformedConsentStatusEnum,
  UserRoleCodeEnum,
  PersonConsentStatusEnum,
  PersonConsentStatusMapLV,
  ConsentTypeEnum,
} from "../../enums";

import PermissonForm from "../permissions/PermissonForm";
import referralService from "../../services/referral-service";
import userService from "../../services/user-service";
import personConsentService from "../../services/person-consent-service";

const PersonInfoRow = ({ title, value }) => {
  return (
    <Grid item container direction="row" spacing={2}>
      <Grid item>
        <Typography>
          <strong>{title}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

const ConsentCard = ({
  consent,
  personConsent = null,
  handleConsentEdit = null,
  handleConsentFill = null,
  handleConsentDelete = null,
  handleConsentPublish = null,
  handlePersonConsentRefuse = null,
}) => {
  const { role } = useRole();
  const [showPrompt, setShowPrompt] = useState(false);
  const [anyReferralTasksSubmitted, setAnyReferralTasksSubmitted] =
    useState(false);
  const [showBiobankConsentDialog, setShowBiobankConsentDialog] =
    useState(false);
  const [disableConsentFillButton, setDisableConsentFillButton] =
    useState(false);

  useEffect(() => {
    const initFilledConsentCheck = async () => {
      const user = await userService.getCurrentUserInfo();
      const isPersonSubmittedConsent =
        await personConsentService.isPersonSubmittedConsent(
          user.personId,
          consent.id
        );
      const disableConsentFillButton =
        isPersonSubmittedConsent && role?.code === UserRoleCodeEnum.Participant;
      setDisableConsentFillButton(disableConsentFillButton);
    };

    initFilledConsentCheck();
  }, [consent]);

  useEffect(() => {
    const checkIfReferralTasksSubmitted = async () => {
      try {
        const referralTasksSubmittedResult =
          await referralService.areAnyReferralTasksSubmitted(
            consent.researchId
          );
        setAnyReferralTasksSubmitted(referralTasksSubmittedResult);
      } catch (error) {
        console.error(error);
      }
    };
    checkIfReferralTasksSubmitted();
  }, [consent]);

  const showEditOption = () => {
    const canUserEdit = role.code !== UserRoleCodeEnum.Participant;
    const canEditByConsentStatus =
      consent.status === InformedConsentStatusEnum.Draft;
    return canEditByConsentStatus && canUserEdit;
  };

  const showPublishOption = () => {
    return consent.status === InformedConsentStatusEnum.Draft;
  };

  const showDeleteOption = () => {
    return (
      consent.status === InformedConsentStatusEnum.Draft &&
      role.code === UserRoleCodeEnum.Admin
    );
  };

  const showFillOption = () => {
    return (
      consent.status === InformedConsentStatusEnum.Published &&
      role?.code === UserRoleCodeEnum.Participant &&
      !personConsent
    );
  };

  function cardSummaryTitle() {
    if (personConsent) return `Informētā piekrišana v${consent.version}`;

    return `Versija: ${consent.version} ; Termiņš: ${
      consent.dateFrom ? timeUtil.date(consent.dateFrom) : ""
    } - ${consent.dateTo ? timeUtil.date(consent.dateTo) : ""} ;  Statuss: ${
      InformedConsentStatusMapLV.get(consent.status) || consent.status
    }`;
  }

  const consentContent = () => {
    return consent.contentLv;
  };

  function showPersonInformationPanel() {
    return role.code === UserRoleCodeEnum.Participant && personConsent;
  }

  function handleRefuseClick() {
    if (consent.type === ConsentTypeEnum.Biobank) {
      setShowBiobankConsentDialog(true);
    } else {
      setShowPrompt(true);
    }
  }

  function showRefuseOption() {
    return (
      personConsent &&
      !anyReferralTasksSubmitted &&
      personConsent.status === PersonConsentStatusEnum.Active
    );
  }

  function cardFormCode() {
    return personConsent ? "FRM.PERS.CONS.RD" : "FRM.CONS.RD";
  }

  const BiobankConsentDialog = () => (
    <Dialog
      open={showBiobankConsentDialog}
      onClose={() => setShowBiobankConsentDialog(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        sx={{
          alignItems: "center",
          align: "center",
          fontWeight: "400",
          fontSize: "24px",
          color: "rgba(16, 58, 93, 1)",
        }}
      >
        {"Informācija"}
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {
            "Lai Jūs varētu atteikties no dalības biobankā, lūdzu, sazinieties ar mums, izmantojot epastu vigdb@biomed.lu.lv. Jums tiks izskaidrots, kā iespējams atteikties no dalības biobankā, kā arī tiks sniegta informācija par Jūsu paraugu un datu iznīcināšanu."
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowBiobankConsentDialog(false)}
          color="primary"
        >
          Aizvērt
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="#007FE3" />}
          aria-controls="consent-version-info"
          id="consent-version"
        >
          <Typography>{cardSummaryTitle()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" gap={2}>
            {/*Divider */}
            <Grid item>
              <Divider />
            </Grid>
            <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent="space-between"
            >
              {/*Person Info */}
              {showPersonInformationPanel() && (
                <Grid container item direction="row" gap={2}>
                  <Grid container item direction="column" xs={4}>
                    <PersonInfoRow
                      title={"Dalībnieka Id:"}
                      value={personConsent?.person?.uniqueId}
                    />
                    <PersonInfoRow
                      title={"Statuss:"}
                      value={PersonConsentStatusMapLV.get(personConsent.status)}
                    />
                    <PersonInfoRow
                      title={"Iesniegts:"}
                      value={timeUtil.date(personConsent.dateCreated)}
                    />
                    <PersonInfoRow
                      title={"Aizpildītājs:"}
                      value={
                        personConsent?.submitterId
                          ? personConsent.submitter.personId
                          : "Personīgi"
                      }
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container item justifyContent="flex-end">
                <Typography variant="h6">{cardFormCode()}</Typography>
              </Grid>
            </Grid>
            {/*Actions to do with Informed Consent*/}
            <Grid
              container
              item
              direction="row"
              spacing={2}
              justifyContent="space-between"
            >
              {/*actions */}
              <Grid container item xs={4} direction="row" spacing={2}>
                {showFillOption() && (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ height: "40px" }}
                      fullWidth
                      onClick={() => {
                        if (handleConsentFill) handleConsentFill(consent);
                      }}
                      disabled={disableConsentFillButton}
                    >
                      Aizpildīt
                    </Button>
                  </Grid>
                )}
                {showEditOption() && (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ height: "40px" }}
                      fullWidth
                      onClick={() => {
                        if (handleConsentEdit) handleConsentEdit(consent);
                      }}
                    >
                      Labot
                    </Button>
                  </Grid>
                )}
                {showPublishOption() && (
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ height: "40px" }}
                      onClick={() => {
                        if (handleConsentPublish) handleConsentPublish(consent);
                      }}
                      fullWidth
                    >
                      Publicēt
                    </Button>
                  </Grid>
                )}
                {showRefuseOption() && (
                  <Grid item xs={4}>
                    <Button
                      variant={"contained"}
                      color={"error"}
                      sx={{ height: "40px" }}
                      onClick={() => handleRefuseClick()}
                      fullWidth
                    >
                      {"Atteikties"}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {/*delete button */}
              <Grid container item xs={4} justifyContent="flex-end">
                {showDeleteOption() && (
                  <Grid item xs={4}>
                    <Button
                      variant={"contained"}
                      color={"error"}
                      type={"button"}
                      sx={{ height: "40px" }}
                      fullWidth
                      onClick={() => {
                        if (handleConsentDelete) handleConsentDelete(consent);
                      }}
                    >
                      Dzēst
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/*Informatin panel */}
            <Grid item>
              <OutlinedBox title={"Informācija"}>
                {parse(consentContent())}
              </OutlinedBox>
            </Grid>
            <Grid item xs={12}>
              <OutlinedBox title={"Informētā piekrišana"}>
                <Grid container direction="column" gap={1} sx={{ my: 1 }}>
                  <Grid item>
                    {!personConsent && (
                      <PermissonForm
                        consent={consent}
                        disabled
                        showAllRemarks
                      />
                    )}
                    {personConsent && (
                      <PermissonForm
                        consent={consent}
                        disabled
                        responsesJson={personConsent.responsesJson}
                      />
                    )}
                  </Grid>
                </Grid>
              </OutlinedBox>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={showPrompt}
        onClose={() => setShowPrompt(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <TitleForm
            title="Informētās piekrišanas atsaukšana"
            titleCode="CONS.WITHDRAW"
            noDivider
          />
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {
              "Vai tiešām vēlaties atsaukt Informēto piekrišanu un visas iesniegtās anketas?"
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BaseButton
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  setShowPrompt(false);
                  if (handlePersonConsentRefuse)
                    handlePersonConsentRefuse(personConsent);
                }}
              >
                {"Jā"}
              </BaseButton>
            </Grid>
            <Grid item xs={6}>
              <BaseButton
                color="error"
                variant="contained"
                fullWidth
                autoFocus
                onClick={() => setShowPrompt(false)}
              >
                {"Nē"}
              </BaseButton>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <BiobankConsentDialog />
    </>
  );
};

export default ConsentCard;
