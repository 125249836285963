import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link as RouterLink } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import footerImage from "./img/footer_image.png";
import EmailIcon from "@mui/icons-material/Email";
import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "../../hooks/useTranslation";
import http from "../../api/http";
import useRightTranslation from "../../hooks/useRightTranslation";
import parse from "html-react-parser";
import "./footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { setFooterUsefulLinks, setFooterContent } from "../../redux/actions/cms";

export const Footer = () => {
  const theme = useTheme();
  const mobileUp = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const tabletUp = useMediaQuery(theme.breakpoints.up("md"));

  const usefulLinks = useSelector((state) => state.Cms.footerUsefulLinks);

  const footerContent = useSelector((state) => state.Cms.footerContent);

  const dispatch = useDispatch();

  const { GetRightTranslation } = useRightTranslation();

  const { FormTitleFaq } = useTranslation();

  const imageWidth = () => {
    if (mobileUp) return { style: { width: "250px" } };

    if (tabletUp) return { style: { width: "400px" } };
  };

  const fetchFooterSystemLinks = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/public/systemmanagerinfo"
      );
      dispatch(setFooterContent(data));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsefulLinks = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/public/usefullinks"
      );
      dispatch(setFooterUsefulLinks(data));
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchUsefulLinks();
    fetchFooterSystemLinks();
  }, []);

  const { contentLv, contentEn, contentRu } = footerContent || {};

  return (
    <>
      <Grid
        container
        sx={{ background: "#F4F4F4" }}
        position={"relative"}
        component="footer"
      >
        <Grid
          container
          item
          padding={{ xs: ".1rem 1rem", xl: "1rem 10rem" }}
          justifyContent={"space-between"}
          rowSpacing={4}
        >
          <Grid item xs={12} lg={5} xl={3} m={{ lg: "1rem" }}>
             <Typography variant="h4">Sistēmas Pārzinis</Typography>
             <Typography component={'div'} sx={{wordBreak: "break-word"}}>
              { parse(GetRightTranslation(contentLv, contentEn, contentRu)) }
             </Typography>
          </Grid>
          <Grid item xs={12} lg={5} xl={3} m={{ lg: "1rem" }}>
            <Typography noWrap={true} variant="h4">
              Nepieciešama Palīdzība?
            </Typography>
            <Grid
              item
              xs={12}
              marginTop={"1rem"}
              gap={".5rem"}
              display={"flex"}
            >
              <HelpOutlineIcon />
              <Typography noWrap={true} paragraph>
                <Link
                  component={RouterLink}
                  color={(theme) => theme.text.link_color}
                  to="/faq"
                >
                  {FormTitleFaq}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} gap={".5rem"}>
              <EmailIcon />
              <Typography noWrap={true} paragraph>
                <Link
                  component={RouterLink}
                  color={(theme) => theme.text.link_color}
                  to="/contacts"
                >
                  Sazinies ar mums
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} m={{ lg: "1rem" }}>
            <Typography variant="h4">Noderīgas Saites</Typography>
            {usefulLinks.map((item) => (
              <Typography my={"1rem"} key={item.id}>
                <Link
                  key={item.id}
                  color={(theme) => theme.text.link_color}
                  target="_blank"
                  href={item.url}
                >
                  {GetRightTranslation(
                    item.contentLv,
                    item.contentEn,
                    item.contentRu
                  )}
                </Link>
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} display={"flex"} justifyContent="center" m={"4rem 0"}>
        <Box>
          <img {...imageWidth()} src={footerImage} alt="footer image" />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        padding={{ xs: "1rem 2rem", md: "1rem 10rem" }}
        color={"white"}
        justifyContent="center"
        textAlign={"center"}
        sx={{ background: "#333333", wordBreak:"break-word" }}
      >
        <Typography>
          Informācijas sistēma izstrādāta Eiropas Reģionālās attīstības fonda projekta Nr. 1.1.1.1/20/A/047 „DECIDE – Dinamiskās informētās piekrišanas sistēmas izveide biobankas un sabiedrības zinātnisko aktivitāšu datu pārvaldībai, kvalitātes kontrolei un integrācijai” ietvaros. 
        </Typography>
      </Grid>
    </>
  );
};
