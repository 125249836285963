import { RESET_DEFAULT } from "../actions/password";

const initialState = {
  isResetSuccessful: null,
  failureMessage: null,
};

export const Password = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DEFAULT:
      return { ...state, failureMessage: null, isResetSuccessful: null };
    default:
      return state;
  }
};
