import http from "../api/http";

const publicService = {
  getResearchPublications: async (status) => {
    const response = await http.backendInstance.get(
      `/api/public/research-publications/${status}`
    );
    return response.data;
  },
  getPublicResearchById: async (id) => {
    const response = await http.backendInstance.get(
      `/api/public/research-publication/${id}`
    );
    return response.data;
  },
  applyToResearch: async (personId, researchDraftId) => {
    const response = await http.backendInstance.post(
      "/api/public/apply-to-research",
      { personId, researchDraftId }
    );
    return response.data;
  },
  canUserApplyForResearch: async (researchDraftId) => {
    const response = await http.backendInstance.get(
      `api/public/can-user-apply-for-research/${researchDraftId}`
    );
    return response.data;
  },
  downloadFile: async (attachmentId) => {
    const response = await http.backendInstance.get(
      `/api/public/file/${attachmentId}`,
      { responseType: "blob" }
    );

    return response;
  },
  sendContactMessage: async (messageData) => {
    const response = await http.backendInstance.post(
      "/api/public/contactmessage",
      { ...messageData }
    );

    return response;
  },
  getContactUsTopics: async (signal) => {
    const response = await http.backendInstance.get(
      "/api/public/contactUsTopics",
      { signal }
    );

    return response.data;
  },
  getContactUsSubtopics: async (signal) => {
    const response = await http.backendInstance.get(
      "/api/public/contactUsSubtopics",
      { signal }
    );

    return response.data;
  },
  getContactUsPhoneCountryCodes: async (signal) => {
    const response = await http.backendInstance.get(
      "/api/public/contact-us-phone-codes",
      { signal }
    );

    return response.data;
  },
};

export default publicService;
