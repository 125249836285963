const ResearchResultStatusEnum = Object.freeze({
    Draft: 1,
    SentForPublication: 2,
    Published: 3,
});

const ResearchResultStatusMapLV = new Map([
    [ResearchResultStatusEnum.Draft, "Sagatave"],
    [ResearchResultStatusEnum.SentForPublication, "Nosūtīta publicēšanai"],
    [ResearchResultStatusEnum.Published, "Publicēta"],
]);

export { ResearchResultStatusEnum, ResearchResultStatusMapLV };