import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import DoubleDivider from "./DoubleDivider";
import LanguageDropdownMobile from "./LanguageDropdownMobile";
import { useTheme } from "@emotion/react";
import React from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { Logout } from "../api";

const HamburgerMenu = () => {
  const theme = useTheme();

  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

  const isAuthenticated = Boolean(sessionStorage.getItem("isAuthenticated"));

  const dispatch = useDispatch();

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => setHamburgerOpen(true)}
        sx={{
          display: {
            sm: "block",
            md: "none",
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={hamburgerOpen}
        onClose={() => setHamburgerOpen(false)}
        SlideProps={{ style: { width: "100%" } }}
        onOpen={() => setHamburgerOpen(true)}
      >
        {/* The inside of the drawer */}
        <Box p={2} height={1}>
          <IconButton onClick={() => setHamburgerOpen(false)} sx={{ mb: 2 }}>
            <CloseIcon />
          </IconButton>
          <Box
            sx={{ mb: 2 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={"50px"}
          >
            <Link
              component={RouterLink}
              color={theme.text.dark_color}
              to="/research/list"
            >
              PĒTĪJUMI
            </Link>
            <Link
              component={RouterLink}
              color={theme.text.dark_color}
              to="/referral/list"
            >
              NORĪKOJUMI
            </Link>
            <Link
              component={RouterLink}
              color={theme.text.dark_color}
              to="/moreinformation"
            >
              VAIRĀK INFORMĀCIJAS
            </Link>
            <Link
              component={RouterLink}
              color={theme.text.dark_color}
              to="/contacts"
            >
              KONTAKTI
            </Link>
          </Box>
          {isAuthenticated && (
            <>
              <DoubleDivider m={"50px 0"} />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={"18px"}
              >
                <LanguageDropdownMobile />
                <Link
                  component={RouterLink}
                  color={theme.text.dark_color}
                  to="/"
                >
                  Jautājumi
                </Link>
                <Link
                  component={RouterLink}
                  color={theme.text.dark_color}
                  to="/"
                >
                  Ziņas
                </Link>
                <Link
                  component={RouterLink}
                  color={theme.text.dark_color}
                  to="/"
                >
                  Iestatījumi
                </Link>
              </Box>
              <DoubleDivider m={"50px 0"} />
              <Box display="flex" alignItems="center" flexDirection="column">
                <Typography
                  variant="h5"
                  component={IconButton}
                  onClick={() => dispatch(Logout())}
                  color={theme.text.dark_color}
                >
                  Iziet
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
