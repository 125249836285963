import React, { useState, useEffect } from "react";
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "../hooks/useTranslation";
import publicService from "../services/public-service";

const SubtopicSelect = ({ name, required }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [topics, setTopics] = useState([]);
  const { ValidationErrorMandatoryField } = useTranslation();

  useEffect(() => {
    const abortController = new AbortController();
    const fetchTopics = async () => {
      try {
        const contactUsSubtopics = await publicService.getContactUsSubtopics(abortController.signal);
        setTopics(contactUsSubtopics);
      } catch (error) {
        if (error.name !== "AbortError")
          console.error(error?.mesage);
      }
    };

    fetchTopics();

    return () => {
      abortController.abort();
    };

  }, []);

  return (
    <>
      <FormControl size="small" error={errors.hasOwnProperty(name)} fullWidth>
        <InputLabel>Apakštēma</InputLabel>
        <Controller
          rules={{ required: required ? ValidationErrorMandatoryField : false }}
          control={control}
          defaultValue={""}
          name={name}
          render={({ field }) => (
            <Select fullWidth label={"Apakštēma"} defaultValue={""} {...field}>
              {topics.map((option) => {
                return (
                  <MenuItem key={option.id} value={option.code}>
                    {option.nameLv}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        <FormHelperText error>
          {errors.hasOwnProperty(name) ? errors[name].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default SubtopicSelect;
