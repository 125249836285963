export const SetStorageAuthenticated = (isAuthenticated) => {
  sessionStorage.setItem("isAuthenticated", isAuthenticated);
};

export const SetStorageRole = (role) => {
  sessionStorage.setItem("role", role);
};

export const SetStorageUsername = (username) => {
  sessionStorage.setItem("username", username);
};

export const setStorageIsRoleSelecting = (isRoleSelecting) => {
  sessionStorage.setItem("isRoleSelecting", isRoleSelecting);
};
