import {
  loadElementsTranslation,
  loadNotifications,
  loadTranslation,
  loadTranslationFailure,
  loadTranslations,
  loadTranslationsFailure,
  setTranslationsDateUpdated,
} from "../../redux/actions/translation";
import { openSnackbar } from "../../redux/actions/snackbar";
import instance from "../http";
import { setError } from "../../redux/actions/error";

export const GetFieldTranslations = () => async (dispatch) => {
  try {
    const request = await instance.backendInstance.get(
      "/api/translation/fields"
    );
    dispatch(loadTranslations(request.data.translations));
    dispatch(setTranslationsDateUpdated(request.data.dateUpdated));
  } catch (error) {
    dispatch(loadTranslationsFailure(error));
  }
};

export const GetTranslation = (language) => async (dispatch) => {
  try {
    const request = await instance.backendInstance.get(
      `/api/translation/${language}`
    );
    dispatch(loadTranslation(request.data));
  } catch (error) {
    dispatch(loadTranslationFailure(error));
    /*     dispatch(setSnackbarMessage("Unable to conenct to the server"));
    dispatch(openSnackbar()); */
    console.log(error);
  }
};

export const UpdateFieldTranslations = (data) => async (dispatch) => {
  try {
    await instance.backendInstance.put("/api/translation", data);
    dispatch(GetFieldTranslations());
    dispatch(openSnackbar());
  } catch (error) {
    console.log(error);
  }
};

export const GetElementTranslations = () => async (dispatch) => {
  try {
    const request = await instance.backendInstance.get(
      "/api/translation/elements"
    );
    dispatch(loadElementsTranslation(request.data));
  } catch (error) {
    dispatch(loadTranslationFailure(error));
  }
};

export const GetNotificationTranslations = () => async (dispatch) => {
  try {
    const request = await instance.backendInstance.get(
      "/api/translation/notifications"
    );
    dispatch(loadNotifications(request.data));
  } catch (error) {
    dispatch(loadTranslationFailure(error));
  }
};

export const UpdateTranslationsByFile = (body) => async (dispatch) => {
  try {
    let formData = new FormData();
    formData.append("importCsv", body.importCsv[0]);
    const request = await instance.backendInstance.post(
      "/api/translation/file",
      formData
    );
    dispatch(GetFieldTranslations());
    dispatch(setTranslationsDateUpdated(request.data.dateUpdated));
  } catch (error) {
    dispatch(setError(error));
    console.log(error);
  } finally {
    dispatch(openSnackbar());
  }
};
