import { useDispatch } from "react-redux";
import { Logout } from "../../api";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import { useTranslation } from "../../hooks/useTranslation";
import { styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const translations = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AnimatedExitIcon = styled(ExitToApp)({
    transition: "transform .5s ease-in",
    "&:hover": {
      transform: "rotateY(180deg)",
    },
  });
  return (
    <Button
      sx={{ color: "black" }}
      onClick={() => {
        dispatch(Logout());
        navigate("/");
      }}
      startIcon={<AnimatedExitIcon />}
    >
      {translations?.MainMenuLogout}
    </Button>
  );
};

export default LogoutButton;
