import Grid from "@mui/material/Grid";
import React from "react";
import { DoubleDivider } from "../../../MUI_components";
import InputLabel from "@mui/material/InputLabel";
import Grow from "@mui/material/Grow";
import { TextEditorTinyMce } from "../../../components/richTextEditor/TextEditor";

export const AboutUsFormFields = React.forwardRef(
  ({ name, defaultValue }, ref) => {
    return (
      <Grow in={true}>
        <Grid container>
          <Grid item xs={12}>
            <InputLabel>Apraksts*</InputLabel>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextEditorTinyMce
              hasImageUpload={true}
              ref={ref}
              defaultValue={defaultValue}
              name={name}
            />
          </Grid>
          <Grid item xs={12} md={10} my={"3rem"}>
            <DoubleDivider variant={"fullwidth"} />
          </Grid>
        </Grid>
      </Grow>
    );
  }
);
