import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormContext } from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import publicService from "../services/public-service";
import { ResearchStatusEnum } from "../enums/research-status";
import { LoadingStub } from "../components";

const PublishedResearchDropdown = ({
  defaultResearch,
  name,
  required,
  onChange,
}) => {
  const [researches, setResearches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    setValue,
    formState: { errors },
    register,
  } = useFormContext();

  const handleChange = (event, research) => {
    const selectedIds = research.map((x) => x.id);
    setValue(name, selectedIds);

    if (onChange) {
      onChange(event, research);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await publicService.getResearchPublications(ResearchStatusEnum.Published);
        const formattedData = response.map(item => ({
          id: item.id,
          label: item.titleLv
        }));
        setResearches(formattedData);
      } catch (error) {
        setError(error.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [ResearchStatusEnum]);

  useEffect(() => {
    register(name, { required });
  }, [register, name, required]);

  useEffect(() => {
    if (defaultResearch) {
      setValue(name, defaultResearch.map(r => r.id));
    }
  }, [defaultResearch, setValue, name]);

  return (
    <>
      {isLoading ? (
        <LoadingStub />
      ) : (
        <Autocomplete
          multiple
          disablePortal
          id="published-research-combo-box"
          size="small"
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.label || ""}
          options={researches}
          value={defaultResearch}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors[name] ? true : false}
              label="Pētījums"
            />
          )}
        />
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
      <FormHelperText error>
        {errors[name] ? errors[name].message : ""}
      </FormHelperText>
    </>
  );
};

export default PublishedResearchDropdown;
