import http from "../api/http";

const systemNotificationService = {
  createSystemNotification: async (systemNotificationToCreate) => {
    const response = await http.backendInstance.post(
      "/api/system-notifications/system-notification",
      {
        ...systemNotificationToCreate
      }
    );

    return response.data;
  },

  deleteSystemNotification: async (systemNotificationId) => {
    const response = await http.backendInstance.delete(
      `/api/system-notifications/system-notification/${systemNotificationId}`
    );

    return response.data;
  },

  getSystemNotificationById: async (systemNotificationId, signal) => {
    const response = await http.backendInstance.get(
      `/api/system-notifications/system-notification/${systemNotificationId}`, { signal });

    return response.data;
  },

  updateSystemNotification: async (systemNotificationToUpdate) => {
    const response = await http.backendInstance.put(
      "/api/system-notifications/system-notification",
      {
        ...systemNotificationToUpdate
      }
    );

    return response.data;
  },

  getSystemNotificationsList: async (queryParams, signal) => {
    const response = await http.backendInstance.get(
      "/api/system-notifications/system-notification-list",
      {
        params: { ...queryParams },
        signal
      });

    return response.data;
  },

  getUserSystemNotificationsList: async (queryParams, signal) => {
    const response = await http.backendInstance.get(
      "/api/system-notifications/user-system-notifications-list",
      {
        params: { ...queryParams },
        signal
      });

    return response.data;
  },

  getHomePageSystemNotificationsList: async (queryParams, signal) => {
    const response = await http.backendInstance.get(
      "/api/system-notifications/home-system-notifications-list",
      {
        params: { ...queryParams },
        signal
      });

    return response.data;
  }
};

export default systemNotificationService;
