import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

export const PrivateRoleRoute = ({ children }) => {
  const isRoleSelecting = sessionStorage.getItem("isRoleSelecting");

  if (!isRoleSelecting) {
    return <Navigate to="/" replace />;
  }

  return children ? children : <Outlet />;
};
