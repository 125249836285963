import {
  DESTROY_FIRST_STAGE_ATTACHMENT,
  SET_FIRST_STAGE_MESSAGE,
  SET_FIST_STAGE_ATTACHMENT,
  SET_SECOND_STAGE_MESSAGE,
  UNSET_FIRST_STAGE_MESSAGE,
} from "../actions/message";

const initialState = {
  firstStage: {
    subject: "",
    message: "",
    receiverType: "",
    relatedResearchIds: [],
    messageAttachments: [],
    error: false,
  },
  secondStage: {
    receiverType: "",
    personUniqueIds: [],
    participantRows: [],
    researchIdsFromWhichToGetParticipants: [],
    error: false,
  },
};

export const Message = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIRST_STAGE_MESSAGE:
      return { ...state, firstStage: action.payload };

    case SET_FIST_STAGE_ATTACHMENT:
      return {
        ...state,
        firstStage: {
          ...state.firstStage,
          messageAttachments: state.firstStage.messageAttachments.concat(action.payload),
        },
      };

    case UNSET_FIRST_STAGE_MESSAGE:
      return {
        ...state,
        firstStage: {
          subject: "",
          message: "",
          receiverType: "",
          relatedResearchIds: [],
          messageAttachments: [],
          researchIdsFromWhichToGetParticipants: [],
          error: false,
        },
        secondStage: { personUniqueIds: null, receiverType: "", error: false },
      };

    case DESTROY_FIRST_STAGE_ATTACHMENT:
      return {
        ...state,
        firstStage: {
          ...state.firstStage,
          messageAttachments: state.firstStage.messageAttachments.filter(
            (x) => x.id !== action.payload
          ),
        },
      };

    case SET_SECOND_STAGE_MESSAGE:
      return {
        ...state,
        secondStage: { ...state.secondStage, ...action.payload }
      };
    default:
      return state;
  }
};
