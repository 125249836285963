import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { TextInputField } from "../../../MUI_components";

export const FAQCmsEditGroupForm = ({ name, defaultValue }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextInputField
          label={"Grupas nosaukums"}
          name={name}
          defaultValue={defaultValue}
          isRequired={true}
          maxCharLength={100}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        my={2}
        gap={"1rem"}
      >
        <Button type="submit" variant="contained" color="success">
          Saglabāt
        </Button>
        <Button component={Link} to="/cms/faq">
          Atcelt
        </Button>
      </Grid>
    </Grid>
  );
};
