import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useFormContext } from "react-hook-form";
import http from "../api/http";
import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "../hooks/useTranslation";
import useToken from "../hooks/useToken";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiGroupSelect = ({ inputName, label, defaultValue, required }) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [items, setItems] = React.useState([]);
  const [groups, setGroups] = React.useState([]);

  const { ValidationErrorMandatoryField } = useTranslation();

  const { token } = useToken();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItems(typeof value === "string" ? value.split(",") : value);
    setValue(inputName, value, { shouldValidate: true });
  };

  const fetchGroups = async () => {
    const { data } = await http.authInstance.get("/api/usergroup");
    const activeGroups = data.filter(group => group.status === "ACTIVE");
    setGroups(activeGroups);
  };

  React.useEffect(() => {
    fetchGroups();
    setItems(defaultValue);
    setValue(inputName, defaultValue);
  }, [token]);


  return (
    <>
      <FormControl
        size="small"
        fullWidth
        error={errors[inputName] ? true : false}
      >
        <InputLabel htmlFor={inputName}>{label}</InputLabel>
        <Select
          id={inputName}
          multiple
          {...register(inputName, {
            required: required ? ValidationErrorMandatoryField : false,
          })}
          fullWidth
          value={items}
          defaultValue={defaultValue}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={`${groups.find((item) => item.code === value)?.name
                    } (${value})`}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {groups.map((item) => (
            <MenuItem key={item.id} value={item.code}>
              {`${item.name} (${item.code})`}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {errors[inputName] ? errors[inputName].message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default MultiGroupSelect;
