export const LOAD_USER = "[user] load";
export const LOAD_USER_FAILURE = "[user] load failed";
export const SET_USER_DEFAULT = "[user] user default";
export const LOAD_USERS = "[users] load users";
export const SET_USER_PAGINATION_OPTIONS = "[user] set pagination options";

export const loadUsers = (payload) => ({
  type: LOAD_USERS,
  payload: payload,
});

export const loadUser = (payload) => ({
  type: LOAD_USER,
  payload: payload,
});

export const loadUserError = (error) => ({
  type: LOAD_USER_FAILURE,
  payload: error,
});

export const setUserDefault = () => ({
  type: SET_USER_DEFAULT,
});

export const setUserPaginationOptions = (payload) => ({
  type: SET_USER_PAGINATION_OPTIONS,
  payload: payload,
});
