import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import { DoubleDivider } from "../../../MUI_components";
import { HomeCmsCard } from "./HomeCmsCard";
import { TextInputField } from "../../../MUI_components";

const maxCountOfCardsAllowed = 9;

export const HomeCmsFields = ({
  lang,
  defaultMainTitle,
  mainTitleName,
  sloganName,
  defaultSlogan,
  handleAdd,
  handleRemove,
  homePageCards,
  imageUpload
}) => {

  return (
    <>
      <Grid
        id="home"
        container
        direction={{ xs: "column", md: "row" }}
        xs={12}
        item
        my={2}
      >
        <Grid direction={"column"} container gap={2} xl={8} item lg={9} xs={11}>
          <Grid>
            <TextInputField
              name={mainTitleName}
              maxCharLength={50}
              defaultValue={defaultMainTitle}
              isRequired={true}
              shouldUnregister={false}
              label={"Sākumlapas virsraksts*"}
            />
          </Grid>
          <Grid>
            <TextInputField
              name={sloganName}
              label={"Sauklis"}
              maxCharLength={100}
              isRequired={true}
              shouldUnregister={false}
              defaultValue={defaultSlogan}
            />
          </Grid>
        </Grid>
        {homePageCards.map((card, index) => (
          <HomeCmsCard
            imageUpload={imageUpload}
            key={index}
            lang={lang}
            id={card.id}
            count={card.count}
            defaultTitle={card.defaultTitle}
            defaultImageSrc={card.defaultImageSrc}
            defaultContent={card.defaultContent}
            handleDelete={handleRemove}
          />
        ))}
        {homePageCards.length < maxCountOfCardsAllowed && (
          <Grid item xs={8} my={{ xs: 5, md: 2 }}>
            <Button
              size={"small"}
              endIcon={<Add />}
              color="primary"
              variant="contained"
              onClick={handleAdd}
            >
              Pievienot sadaļu
            </Button>
          </Grid>
        )}
      </Grid>
      <DoubleDivider variant={"fullwidth"} />
    </>
  );
};
