export const LOGIN_FAILED_PASSWORD_EXPIRED = "[login] user password expired";
export const LOGIN_LOADING_ON = "[login] loading";
export const LOGIN_LOADING_OFF = "[login] stopped loading";
export const LOGIN_SUCCESS_SET_ROLE = "[login] choosing role";
export const LOGIN_SUCCESS_CREATE_NEW_PROFILE =
  "[login] create new participant profile";
export const UNLOAD_PERSON_DATA = "[login] unload user data";
export const SET_AUTH_LOGGED_IN =
  "[login] user sucessfully logged in with appropriate role";
export const SET_SINGLE_USER_ROLE = "[login] user chose single role";
export const SET_USERNAME = "[login] set username";
export const SET_AUTHENTICATED = "[login] set authenticated";
export const LOGIN_FAILURE = "[login] error wrong credentials";
export const UNLOAD_LOGIN_ERORS = "[login] clear errors";
export const SET_ACCESS_TOKEN = "[login] set access token";
export const SET_ACCESS_RIGHTS = "[login] set access rights";
export const SET_TOKEN_LIFETIME = "[login] set token lifetime";
export const SET_PERSON_ID = "[login] set person id";
export const SET_ASSIGNED_GROUPS_CODE = "[login] set assigned groups code";
export const SET_LOGOUT_DIALOG_OPEN = "[login] set logout dialog open";

export const setLogoutDialogOpen = (payload) => ({
  type: SET_LOGOUT_DIALOG_OPEN,
  payload: payload
});

export const setAuthLoading = (isLoading) => ({
  type: isLoading ? LOGIN_LOADING_ON : LOGIN_LOADING_OFF,
  payload: isLoading,
});

export const setPersonId = (payload) => ({
  type: SET_PERSON_ID,
  payload: payload,
});

export const setTokenLifeTime = (payload) => ({
  type: SET_TOKEN_LIFETIME,
  payload: payload,
});

export const setLoginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const clearLoginErrors = () => ({
  type: UNLOAD_LOGIN_ERORS,
});

export const setUserRoles = (roles) => ({
  type: LOGIN_SUCCESS_SET_ROLE,
  payload: roles,
});

export const setSingleUserRole = (role) => ({
  type: SET_SINGLE_USER_ROLE,
  payload: role,
});

export const setPasswordExpired = (isExpired) => ({
  type: LOGIN_FAILED_PASSWORD_EXPIRED,
  payload: isExpired,
});

export const unloadAuthData = () => ({
  type: UNLOAD_PERSON_DATA,
  payload: {
    isAuthenticated: false,
    role: { name: "Participant", code: "PART" },
    username: "",
    isConsentGiven: false,
    isPasswordExpired: false,
    roles: [],
    accessToken: "",
  },
});

export const setUsername = (username) => ({
  type: SET_USERNAME,
  payload: username,
});

export const setAuthenticated = (isAuthenticated) => {
  return {
    type: SET_AUTHENTICATED,
    payload: isAuthenticated,
  };
};

export const setAccessToken = (token) => ({
  type: SET_ACCESS_TOKEN,
  payload: token,
});

export const setAccessRights = (rights) => ({
  type: SET_ACCESS_RIGHTS,
  payload: rights,
});

export const setAssignedGroupsCode = (groups) => ({
  type: SET_ASSIGNED_GROUPS_CODE,
  payload: groups
});
