import React from "react";
import http from "../api/http";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { useFormContext } from "react-hook-form";

const PublishedSingleResearchDropdown = ({ name, required, onChange }) => {
  const [research, setResearch] = React.useState(null);

  const [options, setOptions] = React.useState([]);

  const {
    setValue,
    formState: { errors },
    register,
  } = useFormContext();

  const handleChange = (event, research) => {
    if (onChange) onChange(event, research);

    setResearch(research);
    if (research) setValue(name, research.id);
  };

  const fetchPublishedResearch = async () => {
    try {
      const { data } = await http.backendInstance.get(
        "/api/research/publishedresearch"
      );
      setOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    fetchPublishedResearch();
  }, []);

  React.useEffect(() => {
    register(name, { required: required ? true : false });
  }, []);

  return (
    <>
      <Autocomplete
        disablePortal
        id="published-research-single-dropdown"
        size="small"
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        getOptionLabel={(option) => option.label || ""}
        options={options}
        value={research}
        renderInput={(params) => (
          <TextField
            {...params}
            error={errors[name] ? true : false}
            label="Pētījums"
          />
        )}
      />
      <FormHelperText error>
        {errors[name] ? errors[name].message : ""}
      </FormHelperText>
    </>
  );
};

export default PublishedSingleResearchDropdown;
