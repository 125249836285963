import { useState, useEffect } from "react";


const useCountdown = (expirationDate) => {
  const [timeLeft, setTimeLeft] = useState({ minutes: 2, seconds: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = expirationDate - now;

      const minutes = Math.floor(distance / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < -1) {
        clearInterval(interval);
        setTimeLeft({ minutes: -1, seconds: 0 });
      } else {
        setTimeLeft({ minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expirationDate]);

  return timeLeft;
};

export { useCountdown };
