import React, { useEffect, useState } from "react";
import BaseButton from "./buttons/BaseButton";
import { Box } from "@mui/material";

const ExpandableImage = ({ src, alt, initialHeight = "100px" }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.onload = () => {
            const heightConstraint = parseInt(initialHeight, 10);
            if (image.height > heightConstraint) {
                setShowButton(true);
            }
        };
        image.src = src;
    }, [src, initialHeight]);

    return (
        <Box>
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    width: isExpanded ? "auto" : "100%",
                    maxHeight: isExpanded ? "none" : initialHeight,
                    objectFit: "contain",
                }}
            />
            {showButton && (
                <BaseButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? "Samazināt attēlu" : "Paplašināt attēlu"}
                </BaseButton>
            )}
        </Box>
    );
};

export default ExpandableImage;