import { useCallback } from "react";
import publicService from "../services/public-service";
import moment from "moment";
import CONSTANTS from "../constants";

const useFileDownloader = () => {
    const downloadFile = useCallback(async (fileOrId, fileName, withTimeStamp = false) => {
        // If fileOrId is an instance of File, it's a recently uploaded file not yet sent to server
        if (fileOrId instanceof File) {
            const url = window.URL.createObjectURL(fileOrId);
            downloadBlob(url, fileName);
            window.URL.revokeObjectURL(url);
        } else {
            try {
                const response = await publicService.downloadFile(fileOrId);
                const url = window.URL.createObjectURL(new Blob([response.data]));

                let downloadedFileName = fileName;
                if (withTimeStamp) {
                    const lastDot = downloadedFileName.lastIndexOf(".");
                    const name = downloadedFileName.slice(0, lastDot);
                    const extension = downloadedFileName.slice(lastDot + 1);
                    const timestamp = moment(new Date()).format(CONSTANTS.TIMESTAMP_FORMAT);

                    downloadedFileName = `${name}_${timestamp}.${extension}`
                }

                downloadBlob(url, downloadedFileName);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error(error?.message);
            }
        }
    }, []);

    const downloadBlob = useCallback((url, fileName) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }, []);
    

    return { downloadFile };
};

export default useFileDownloader;