export const LOAD_USERGROUPS = "[userGroup] load";
export const UNLOAD_USERGROUPS = "[userGroup] unload";
export const LOAD_FAILURE = "[userGroup] load failure";
export const LOAD_USERRIGHTS = "[userRights] load";
export const UNLOAD_USERRIGHTS = "[userRights] unload";
export const LOAD_USER_ROLES = "[userRoles] load";
export const LOAD_USER_SETTINGS = "[userSettings] load";
export const UNLOAD_USER_SETTINGS = "[userSettings] unload";
export const LOAD_USERGROUP_RIGHTS = "[userGroup] load user rights";
export const UNLOAD_USERGROUP_RIGHTS = "[userGroup] unload user rights";
export const LOAD_GROUPUSERS = "[userGroup] load group users";
export const UNLOAD_GROUPUSERS = "[userGroup] unload group users";

export const loadGroupUsers = (groupUsers) => ({
  type: LOAD_GROUPUSERS,
  payload: groupUsers,
});

export const unloadGroupUsers = () => ({
  type: UNLOAD_GROUPUSERS,
});

export const loadUserGroupRights = (rights) => ({
  type: LOAD_USERGROUP_RIGHTS,
  payload: rights,
});

export const unloadUserGroupRights = () => ({
  type: UNLOAD_USERGROUP_RIGHTS,
});

export const loadUserGroups = (payload) => ({
  type: LOAD_USERGROUPS,
  payload: payload,
});

export const loadUserSettings = (payload) => ({
  type: LOAD_USER_SETTINGS,
  payload: payload,
});

export const unloadUserSettigns = () => ({
  type: UNLOAD_USER_SETTINGS,
});

export const unloadUserGroups = () => ({
  type: UNLOAD_USERGROUPS,
});

export const loadUserGroupsFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const loadUserRights = (userRights) => ({
  type: LOAD_USERRIGHTS,
  payload: userRights,
});

export const unloadUserRights = () => ({
  type: UNLOAD_USERRIGHTS,
});

export const loadUserRoles = (userRoles) => ({
  type: LOAD_USER_ROLES,
  payload: userRoles,
});
