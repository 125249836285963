import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import React from "react";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { useCallback } from "react";

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export default async function getCroppedImg(
  imageFile,
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  const { name, type } = imageFile;

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      const fileToUpload = new File([file], name, { type: type });
      resolve({ croppedImage: URL.createObjectURL(file), file: fileToUpload });
    }, type);
  });
}

export const ImageOverlay = ({
  imgSrc,
  open,
  setOpen,
  setImage,
  imageFile,
  uploadImageToRemoteServer
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [zoom, setZoom] = useState(1);

  const [croppedPixelArea, setCroppedPixelArea] = useState(null);

  const onCropComplete = useCallback((_, croppedPixelArea) => {
    setCroppedPixelArea(croppedPixelArea);
  }, []);

  const setCroppedImageAndCloseImageOverlay = useCallback(async () => {
    try {
      const { croppedImage, file } = await getCroppedImg(
        imageFile,
        imgSrc,
        croppedPixelArea
      );
      setOpen(false);
      await uploadImageToRemoteServer(file);
      setImage(file);
    } catch (e) {
      console.error(e);
    }
  }, [imgSrc, croppedPixelArea]);

  const handleCloseImageOverlay = () => {
    setOpen(false);
    setImage(null);
  };

  return (
    <Backdrop
      open={open}
      component={Paper}
      sx={{
        background: (theme) => theme.palette.common.white,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        overflow: "hidden",
      }}
    >
      <Grid container height={"100%"}>
        <Grid item container display={"flex"} justifyContent={"center"}>
          <Grid xs={4} item>
            <Grid item xs={12} mt={4} mb={2}>
              <Typography variant="h3" textAlign={"center"}>
                Pievienot attēlu
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              position={"relative"}
              height={"500px"}
              width={"100%"}
            >
              <Cropper
                image={imgSrc}
                crop={crop}
                zoom={zoom}
                zoomSpeed={1}
                axZoom={3}
                onCropComplete={onCropComplete}
                zoomWithScroll={true}
                showGrid={true}
                aspect={16 / 10}
                onCropChange={setCrop}
                onZoomChange={setZoom}
              />
            </Grid>
            <Grid
              item
              px={5}
              display="flex"
              justifyContent={"center"}
              xs={12}
              my={2}
            >
              <Slider
                value={zoom}
                min={1}
                max={8}
                step={0.00000001}
                onChange={(e, v) => setZoom(v)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              gap={"1rem"}
            >
              <Button
                onClick={setCroppedImageAndCloseImageOverlay}
                variant="contained"
                color="primary"
              >
                Turpināt
              </Button>
              <Button
                onClick={handleCloseImageOverlay}
                variant="outlined"
                color="primary"
              >
                Atcelt
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Backdrop>
  );
};
