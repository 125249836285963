import {
  SET_NOTIFICATIONS,
  SET_NUMBER_OF_UNREAD_MESSAGES,
} from "../actions/notification";

const initialState = {
  notification: [],
  numberOfUnReadNotifications: 0,
};

export const Notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, notification: action.payload };
    case SET_NUMBER_OF_UNREAD_MESSAGES:
      return { ...state, numberOfUnReadNotifications: action.payload };

    default:
      return state;
  }
};
