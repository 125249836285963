import React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
export const MoreInformationCms = () => {

  return (
    <Box width={'100%'}>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Box>
  );
};
