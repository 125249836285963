const CanDeleteResearchMessageEnum = Object.freeze({
  CanDeleteResearch: 1,
  ResearchContainsParticipantData: 2,
  ResultsLinkedToResearch: 3,
  ResearchNotExpired: 4,
});

const CanDeleteResearchMessageMapLV = new Map([
  [CanDeleteResearchMessageEnum.CanDeleteResearch, "Var dzēst pētījumu."],
  [
    CanDeleteResearchMessageEnum.ResearchContainsParticipantData,
    "Pētījumam ir piesaistīti pētījuma dalībnieku dati (iesniegtās anketas, piekrišanas un uzdevumi)",
  ],
  [
    CanDeleteResearchMessageEnum.ResultsLinkedToResearch,
    "Pētījumam ir piesaistīti dalībnieku individualie rezultāti",
  ],
  [
    CanDeleteResearchMessageEnum.ResearchNotExpired,
    "Nav pagājis pētījuma noslēguma datums",
  ],
]);

export { CanDeleteResearchMessageEnum, CanDeleteResearchMessageMapLV };
