const ResearchStatusEnum = Object.freeze({
  Draft: "DRAFT",
  UnpublishedEdit: "EDITED",
  Published: "PUBL",
  Completed: "CLOSED",
  PublicationPending: "PUBLICATION PENDING",
});

const ResearchStatusMapLV = new Map([
  [ResearchStatusEnum.Draft, "Sagatave"],
  [ResearchStatusEnum.UnpublishedEdit, "Nepublicēti labojumi"],
  [ResearchStatusEnum.Published, "Publicēts"],
  [ResearchStatusEnum.Completed, "Noslēdzies"],
  [ResearchStatusEnum.PublicationPending, "Nosūtīts publicēšanai"],
]);

export { ResearchStatusEnum, ResearchStatusMapLV };
