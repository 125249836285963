import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { TranslationInput } from "../components/cms/TranlsationInput";
import { useTranslation } from "../hooks/useTranslation";

//inputPositionNum prop is responsible for indicating position in row of inputs
//possible values 0,1,2
const ControlledTranslationInput = ({
  name,
  defaultValue,
  inputPositionNum,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { ValidationErrorMandatoryField } = useTranslation();

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: ValidationErrorMandatoryField }}
        defaultValue={defaultValue}
        render={({ field }) => <TranslationInput {...field} />}
      />
      <FormHelperText error>
        {errors[name] ? errors[name][inputPositionNum]?.message : ""}
      </FormHelperText>
    </>
  );
};

export default ControlledTranslationInput;
