import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Save from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { CustomBreadCrumbs, TabPanel, TitleForm } from "../../../MUI_components";
import { FormProvider, get } from "react-hook-form";
import { useState } from "react";
import http from "../../../api/http";
import { HomeCmsFields } from "./HomeCmsFields";
import { useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 } from "uuid";
import { useDispatch } from 'react-redux';
import { setHomePageImageSrcArray } from "../../../redux/actions/cms";
import moment from "moment";

function a11yProps(index) {
  return {
    id: `cms-tab-${index}`,
    "aria-controls": `cms-tabpanel-${index}`,
  };
}


const links = [{ to: "/cms/home", text: "Satura pārvaldība - Sākumlapa" }];

const HomeCms = () => {

  const form = useForm({
    defaultValues: {
      lv: [],
      en: [],
      ru: []
    },
    shouldUnregister: false
  });

  const [value, setValue] = useState(0);

  const [homePageCardsLv, setHomePageCardsLv] = useState([]);

  const [homePageCardsEn, setHomePageCardsEn] = useState([]);

  const [homePageCardsRu, setHomePageCardsRu] = useState([]);

  const [dateUpdated, setDateUpdated] = useState("");

  const [isHomePageCardsLoading, setIsHomePageCardsLoading] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //we need this state to implement cancel functionality
  const [defaultData, setDefaultData] = useState(null);

  const dispatch = useDispatch();

  const [titles, setTitles] = useState({
    sloganLvValue: "",
    sloganEnValue: "",
    sloganRuValue: "",
    mainTitleLv: "",
    mainTitleEn: "",
    mainTitleRu: "",
  });

  const theme = useTheme();

  const { main } = theme.palette.error;

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleSubmitForm = async (body, event) => {
    event.preventDefault();
    body.lv = body.lv.filter(item => item !== null);
    body.en = body.en.filter(item => item !== null);
    body.ru = body.ru.filter(item => item !== null);

    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000);

    try {
      const { data } = await http.backendInstance.put("/api/cms/home", body);
      setDateUpdated(data.dateUpdated);
    } catch (error) {
      console.log(error);
    }
  };

  const getIndicatorColor = (value) => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (value) {
      case 0:
        if (firstTabError()) return tabIndicatorColor;
        break;
      case 1:
        if (secondTabError()) return tabIndicatorColor;
        break;
      case 2:
        if (thirdTabError()) return tabIndicatorColor;
        break;
      default:
        return "";
    }
  };

  //submit has to take place from here
  const firstTabError = () => {
    return get(form.formState.errors, "lv");
  };

  const secondTabError = () => {
    return get(form.formState.errors, "en");
  };

  const thirdTabError = () => {
    return get(form.formState.errors, "ru");
  };

  const setHomePages = (data) => {

    let idImageSrcDict = {};

    const homePages = data?.homePage || [];

    for (const [idx, item] of homePages.entries()) {
      idImageSrcDict[`imageLink.${idx}`] = item.attachmentId;
    }

    dispatch(setHomePageImageSrcArray(idImageSrcDict));

    //const homePages = data.homePage;

    const homePageLv = homePages.map((item, idx) => ({
      id: item.id,
      count: idx,
      dateCreated: item.dateCreated,
      dateUpdated: item.dateUpdated,
      defaultTitle: item.titleLv,
      defaultContent: item.descriptionLv,
      defaultImageSrc: item.attachmentId
    }));

    const homePageEn = homePages.map((item, idx) => ({
      id: item.id,
      count: idx,
      dateCreated: item.dateCreated,
      defaultTitle: item.titleEn,
      defaultContent: item.descriptionEn,
      defaultImageSrc: item.attachmentId
    }));

    const homePageRu = homePages.map((item, idx) => ({
      id: item.id,
      count: idx,
      dateCreated: item.dateCreated,
      defaultTitle: item.titleRu,
      defaultContent: item.descriptionRu,
      defaultImageSrc: item.attachmentId
    }));


    setHomePageCardsLv(homePageLv);

    setHomePageCardsEn(homePageEn);

    setHomePageCardsRu(homePageRu);

    setDateUpdated(data.dateUpdated);

  }

  const fetchHomePage = async () => {
    try {

      setIsHomePageCardsLoading(true);

      const { data } = await http.backendInstance.get("/api/public/homepage");

      setHomePages(data);

      setDefaultData(data);

      setTitles(prevState => ({
        ...prevState,
        mainTitleEn: data.mainTitleEn,
        mainTitleLv: data.mainTitleLv,
        mainTitleRu: data.mainTitleRu,
        sloganLvValue: data.sloganLv,
        sloganEnValue: data.sloganEn,
        sloganRuValue: data.sloganRu,
      }));

    } catch (error) {
      console.log(error);
    } finally {
      setIsHomePageCardsLoading(false);
    }
  }

  const addCard = () => {

    setHomePageCardsLv(prevState => [...prevState, {
      id: "",
      internalId: v4(),
      count: homePageCardsLv.length + 1,
      defaultImageSrc: "",
      defaultContent: "",
      defaultTitle: ""
    }]);


    setHomePageCardsEn(prevState => [...prevState, {
      id: "",
      internalId: v4(),
      count: homePageCardsEn.length + 1,
      defaultImageSrc: "",
      defaultContent: "",
      defaultTitle: ""
    }]);

    setHomePageCardsRu(prevState => [...prevState, {
      id: "",
      internalId: v4(),
      count: homePageCardsRu.length + 1,
      defaultImageSrc: "",
      defaultContent: "",
      defaultTitle: ""
    }]);

  }

  const removeCard = (id, count) => {

    const entityToDeleteLv = homePageCardsLv.find(card => card.id === id);

    const entityToDeleteEn = homePageCardsEn.find(card => card.id === id);

    const entityToDeleteRu = homePageCardsRu.find(card => card.id === id);

    //we need to differentiate between those created on the server and those that are created on frontend
    //if entity marked for deletion has 

    if (entityToDeleteLv && entityToDeleteLv.id) {
      form.setValue(`lv.${count}.isDeleted`, true);
    } else form.unregister(`lv.${count}`);

    if (entityToDeleteEn && entityToDeleteEn.id) {
      form.setValue(`en.${count}.isDeleted`, true);
    } else form.unregister(`en.${count}`);

    if (entityToDeleteRu && entityToDeleteRu.id) {
      form.setValue(`ru.${count}.isDeleted`, true);
    } else form.unregister(`ru.${count}`);

    //regardless of whether it is saved on the server or not , card should be removed from frontend
    setHomePageCardsLv(prevState => prevState.filter(card => card.count !== count));
    setHomePageCardsEn(prevState => prevState.filter(card => card.count !== count));
    setHomePageCardsRu(prevState => prevState.filter(card => card.count !== count));

  }

  const handleCancel = () => {
    form.reset();
    setHomePages(defaultData);
    form.clearErrors(["lv", "en", "ru"]);
  }

  useEffect(() => {
    fetchHomePage();
  }, []);

  const {
    mainTitleEn,
    mainTitleLv,
    mainTitleRu,
    sloganEnValue,
    sloganLvValue,
    sloganRuValue,
  } = titles;


  if (mainTitleLv === "" || sloganLvValue === "" || mainTitleEn === "") return <CircularProgress />

  return (
    <FormProvider {...form}>
      <Box sx={{ width: "100%" }}>
        <CustomBreadCrumbs links={links} />
        <TitleForm
          title="Sākumlapa"
          titleCode="FRM.HOME.IL"
          dateChanged={moment(dateUpdated).isValid() && moment(dateUpdated).format("DD.MM.YYYY. HH:mm:ss")}
        />
        <form onSubmit={form.handleSubmit(handleSubmitForm)}>
          <Box my={2}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { backgroundColor: getIndicatorColor(value) },
              }}
              aria-label="language tabs"
            >
              <Tab
                label={
                  <Typography color={firstTabError() ? main : ""}>
                    LV
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Typography color={secondTabError() ? main : ""}>
                    EN
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Typography color={thirdTabError() ? main : ""}>
                    RU
                  </Typography>
                }
                {...a11yProps(2)}
              />
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
              <HomeCmsFields
                imageUpload={true}
                lang="lv"
                mainTitleName="mainTitlelv"
                sloganName="sloganlv"
                homePageCards={homePageCardsLv}
                defaultMainTitle={mainTitleLv}
                defaultSlogan={sloganLvValue}
                handleAdd={addCard}
                handleRemove={removeCard}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HomeCmsFields
                imageUpload={false}
                lang="en"
                mainTitleName="mainTitleen"
                sloganName="sloganen"
                homePageCards={homePageCardsEn}
                defaultMainTitle={mainTitleEn}
                defaultSlogan={sloganEnValue}
                handleAdd={addCard}
                handleRemove={removeCard}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <HomeCmsFields
                imageUpload={false}
                lang="ru"
                mainTitleName="mainTitleru"
                sloganName="sloganru"
                homePageCards={homePageCardsRu}
                defaultMainTitle={mainTitleRu}
                defaultSlogan={sloganRuValue}
                handleAdd={addCard}
                handleRemove={removeCard}
              />
            </TabPanel>
          </Box>
          <Grid
            my={2}
            display="flex"
            flexDirection="column"
            alignItems={{ md: "flex-end", xs: "flex-start" }}
            gap={2}
          >
            {showSuccessMessage && (
              <p style={{ color: 'green' }}>Izmaiņas veiksmīgi saglabātas.</p>)}
            <Grid
              display="flex"
              justifyContent={{ md: "flex-end", xs: "flex-start" }}
              gap={2}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
              >
                Atcelt
              </Button>
              <Button
                startIcon={<Save />}
                type="submit"
                variant="contained"
                color="success"
                disabled={showSuccessMessage}
              >
                Saglabāt
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </FormProvider>
  );
};

export default HomeCms;
