import React from "react";
import { Pagination, Typography, Box, Grid } from "@mui/material";

const PaginationWrapper = ({ total, currentPage, pageSize, onPageChange }) => {
    const pageCount = Math.ceil(total / pageSize);

    const start = total > 0 ? (currentPage - 1) * pageSize + 1 : 0;
    let end = currentPage * pageSize;
    if (end > total)
        end = total;

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, page) => onPageChange(page)}
                />
            </Grid>
            <Grid item>
                <Typography>
                    Ieraksti lapā {pageSize} | {start}-{end} no {total}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default PaginationWrapper;
