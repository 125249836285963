import { styled } from "@mui/material";
import Tab from "@mui/material/Tab";

const PrimaryTab = styled(Tab)(({ theme }) => ({
  "&.MuiTab-root": {
    color: "#BDBDBD",
    padding: "5px 0",
    textTransform: "none",
    fontSize: theme.typography.h5,
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: ".5rem",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.h5,
  },
}));

export default PrimaryTab;
