import {
  LOAD_TRANLSATIONS,
  LOAD_TRANSLATION,
  LOAD_TRANSLATIONS_ELEMENTS,
  LOAD_TRANSLATIONS_FAILURE,
  LOAD_TRANSLATIONS_NOTIFICATIONS,
  LOAD_TRANSLATION_FAILURE,
  SET_DATEUPDATED,
  SET_LANGUAGE,
  UNLOAD_TRANSLATIONS,
  UNLOAD_TRANSLATIONS_ELEMENTS,
  UNLOAD_TRANSLATIONS_NOTIFICATIONS,
} from "../actions/translation";

const initialState = {
  data: [],
  error: null,
  translation: [],
  elements: [],
  notifications: [],
  dateUpdated: "",
  language: "Lv",
};

export const Translation = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return { ...state, language: action.payload };
    case LOAD_TRANLSATIONS:
      return { ...state, data: action.payload };
    case LOAD_TRANSLATIONS_FAILURE:
      return { ...state, error: action.payload };
    case LOAD_TRANSLATION:
      return { ...state, translation: action.payload };
    case LOAD_TRANSLATION_FAILURE:
      return { ...state, error: action.payload };
    case LOAD_TRANSLATIONS_ELEMENTS:
      return { ...state, elements: action.payload };
    case LOAD_TRANSLATIONS_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case UNLOAD_TRANSLATIONS_NOTIFICATIONS:
      return { ...state, notifications: [] };
    case UNLOAD_TRANSLATIONS:
      return { ...state, data: [] };
    case UNLOAD_TRANSLATIONS_ELEMENTS:
      return { ...state, elements: [] };
    case SET_DATEUPDATED:
      return { ...state, dateUpdated: action.payload };
    default:
      return state;
  }
};
