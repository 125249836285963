import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useFormContext } from "react-hook-form";
import TextInputField from "./TextInputField";

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const validationMessage = "E-pasta formāts nav pareizs"; //useSelector(translations));

const MultipleEmailField = ({ forwardEmails, labelProps }) => {
  const { register } = useFormContext();

  const [secondEmailPresent, setSecondEmailPresent] = React.useState(
    forwardEmails?.length > 1 ? true : false
  );

  const handleRemoveEmail = () => {
    setSecondEmailPresent(false);
  };

  const handleAddingNewEmail = () => {
    setSecondEmailPresent(true);
  };

  return (
    <>
      <Box
        display="flex"
        rowGap="1rem"
        width={{ xs: "100%", md: "70%" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Box flex="1">
          <TextInputField
            label={"E-pasts*"}
            name={"emails.0.email"}
            defaultValue={
              forwardEmails &&
              forwardEmails.find((email) => email.primary === true)?.email
            }
            isRequired={true}
            validationRules={{
              pattern: {
                value: emailRegex,
                message: validationMessage,
              },
            }}
          />
          <input hidden {...register("emails.0.primary", { value: true })} />
        </Box>
        <Box flex="1"></Box>
      </Box>
      {secondEmailPresent && (
        <>
          <Box
            marginTop="1.5rem"
            display="flex"
            width="100%"
            flexDirection={{ md: "row", xs: "column" }}
          >
            <Box display="flex">
              <TextInputField
                label={"Papildu e-pasts*"}
                name={"emails.1.email"}
                defaultValue={
                  forwardEmails &&
                  forwardEmails.find((email) => email.primary === false)?.email
                }
                isRequired={true}
                validationRules={{
                  pattern: {
                    value: emailRegex,
                    message: validationMessage,
                  },
                }}
              />
              <input
                hidden
                {...register("emails.1.primary", { value: false })}
              />
              <IconButton onClick={handleRemoveEmail} sx={{ alignItems: "flex-start" }}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )}
      {!secondEmailPresent && (
        <Button
          sx={{ marginTop: "1rem" }}
          variant="outlined"
          onClick={handleAddingNewEmail}
          endIcon={<Add />}
          color="secondary"
        >
          Pievienot papildu e-pastu
        </Button>
      )}
    </>
  );
};

export default MultipleEmailField;
