import { SET_PERSON_ID } from "../actions/auth";
import {
  CLEAR_PERSON_REGCODE,
  LOAD_PERSONS,
  LOAD_PERSONS_FAILURE,
  PERSONS_LOADING,
  REMOVE_RECODE,
  SET_LINKING_SUCCESS,
  SET_PERSON_FAILURE,
  SET_PERSON_POST,
  SET_PERSON_REGCODE,
  SET_PERSON_REGCODES,
  SET_PERSON_REGCODE_INUSE_ERROR,
  SET_PERSON_REGCODE_NOTFOUND_ERROR,
  SET_SINGLE_PERSON_DATA,
  UNLOAD_PERSON_DATA,
  UNLOAD_PERSON_LIST,
  UNLOAD_PERSON_REGCODE,
} from "../actions/persons";

const initialState = {
  personId: "",
  allPersons: [],
  postPersonData: {},
  singlePersonData: {},
  regCode: [],
  regCodeNotFoundError: null,
  regCodeIsInUseError: null,
  regCodeLinkingStatus: null,
  isLoading: false,
  error: null,
};

export const Person = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PERSONS:
      return { ...state, allPersons: action.payload, error: null };
    case LOAD_PERSONS_FAILURE:
      return { ...state, allPersons: [], error: action.payload };
    case PERSONS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_PERSON_POST:
      return { ...state, postPersonData: action.payload };
    case SET_SINGLE_PERSON_DATA:
      return { ...state, singlePersonData: action.payload };
    case SET_PERSON_ID:
      return { ...state, personId: action.payload };
    case SET_PERSON_FAILURE:
      return { ...state, error: action.payload };
    case UNLOAD_PERSON_DATA:
      return { ...state, singlePersonData: {} };
    case UNLOAD_PERSON_LIST:
      return { ...state, allPersons: [] };
    case SET_PERSON_REGCODE:
      return { ...state, regCode: [...state.regCode, { ...action.payload }] };
    case SET_PERSON_REGCODES:
      return { ...state, regCode: action.payload };
    case REMOVE_RECODE:
      let updatedArray = state.regCode.filter((x) => x.id !== action.payload);
      return { ...state, regCode: updatedArray };
    case UNLOAD_PERSON_REGCODE:
      return {
        ...state,
        regCode: [],
        regCodeLinkingStatus: null,
        regCodeIsInUseError: null,
        regCodeNotFoundError: null,
      };
    case SET_PERSON_REGCODE_INUSE_ERROR:
      return { ...state, regCodeIsInUseError: action.payload };
    case SET_PERSON_REGCODE_NOTFOUND_ERROR:
      return { ...state, regCodeNotFoundError: action.payload };
    case CLEAR_PERSON_REGCODE:
      return {
        ...state,
        regCodeIsInUseError: null,
        regCodeNotFoundError: null,
        regCodeLinkingStatus: null,
      };
    case SET_LINKING_SUCCESS:
      return { ...state, regCodeLinkingStatus: action.payload };
    default:
      return state;
  }
};
