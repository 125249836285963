import Add from "@mui/icons-material/Add";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import ImagePlaceholder from "./img/imagePlaceholder.png";
import { useFormContext } from "react-hook-form";
import { ImageComponent } from "./HomeCmsImageComponent";
import { ImageOverlay } from "./ImageOverlay";
import http from "../../../api/http";
import { useDispatch, useSelector } from "react-redux";
import { setHomePageImageSrcArray } from "../../../redux/actions/cms";

export const HomeImageUpload = ({name, count, lang, defaultSrc, imageUpload }) => {

  const [imageId, setImageId] = React.useState(`${name}.${count}`);

  const { register, setValue } = useFormContext();

  const [imageSrc, setImageSrc] = React.useState(defaultSrc ?? "");

  const [image, setImage] = React.useState(null);

  const [openImageOverlay, setOpenImageOverlay] = React.useState(false);

  const dispatch = useDispatch();

  const imageDict = useSelector(state => state.Cms.homePageImageSrcArray);

  const uploadImageToRemoteServer = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await http.backendInstance.post("/api/public/file", formData);
      setImageSrc(data.location);
      imageDict[imageId] = data.location;
      dispatch(setHomePageImageSrcArray(imageDict));
      setValue(`${lang}.${count}.${name}`, data.location);
    } catch (error) {
      console.log(error);
    }
  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageSrc(URL.createObjectURL(file));
      setOpenImageOverlay(true);
      setImage(file);
    }
  };

  const handleDeleteImage = () => {
    setImageSrc("");
    //request to delete image
    imageDict[imageId] = null;
    dispatch(setHomePageImageSrcArray(imageDict));
    setImage(null);
    setValue(`${lang}.${count}.${name}`, null);
  };

  const setImageFormValue = (imgFile) => {
    setValue(`${lang}.${count}.${name}`, imgFile);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={11}>
          <Fade in={true} appear={true} unmountOnExit>
            <ImageComponent
              src={imageDict[imageId] ? imageDict[imageId] : ImagePlaceholder}
              alt={"cms home"}
            />
          </Fade>
        </Grid>
        { imageUpload ? (
          <Grid
            container
            direction={{ xs: "row", md: "column" }}
            alignItems={{ md: "unset", xs: "center" }}
          >
            <Grid my={1}>
              <Button
                size="small"
                component={"label"}
                color="primary"
                variant="outlined"
                startIcon={<Add />}
              >
                <input
                  id="cms"
                  multiple={false}
                  hidden
                  accept="*"
                  type="file"
                  defaultValue={null}
                  {...register(`${lang}.${count}.${name}`, { shouldUnregister: true, value: defaultSrc || null })}
                  onChange={handleImageUpload}
                />
                Pievienot attēlu
              </Button>
            </Grid>
            <Grid>
              <Button
                onClick={handleDeleteImage}
                startIcon={
                  <DeleteOutline color={imageSrc ? "info" : "disabled"} />
                }
              >
                <Typography color="gray">Dzēst attēlu</Typography>
              </Button>
            </Grid>
          </Grid>

        ): null }
      </Grid>
      <ImageOverlay
        uploadImageToRemoteServer={uploadImageToRemoteServer}
        setImageFormValue={setImageFormValue}
        imageFile={image}
        setImage={setImage}
        setOpen={setOpenImageOverlay}
        setImgSrc={setImageSrc}
        imgSrc={imageSrc}
        open={openImageOverlay}
      />
    </>
  );
};
