import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Save from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import http from "../../../api/http";
import moment from "moment";
import {
  CustomBreadCrumbs,
  TitleForm,
  TabPanel,
} from "../../../MUI_components";
import { FormProvider, get, useForm } from "react-hook-form";
import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { AboutUsFormFields } from "./AboutUsFormFields";

const links = [{ to: "/cms/about", text: "Satura pārvaldība - Par mums" }];

const defaultValues = {
  contentLv: "",
  contentEn: "",
  contentRu: "",
  dateUpdated: new Date(),
};

const AboutUsCms = () => {
  const form = useForm();

  const [value, setValue] = useState(0);

  const [defaultValue, setDefaultValue] = useState(defaultValues);

  const refContentLv = useRef(null);
  const refContentEn = useRef(null);
  const refContentRu = useRef(null);

  const theme = useTheme();

  const { main } = theme.palette.error;

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const firstTabError = () => {
    return get(form.formState.errors, "contentlv");
  };

  const secondTabError = () => {
    return get(form.formState.errors, "contenten");
  };

  const thirdTabError = () => {
    return get(form.formState.errors, "contentru");
  };

  const getIndicatorColor = (value) => {
    const tabIndicatorColor = theme.palette.error.main;
    switch (value) {
      case 0:
        if (firstTabError()) return tabIndicatorColor;
        break;
      case 1:
        if (secondTabError()) return tabIndicatorColor;
        break;
      case 2:
        if (thirdTabError()) return tabIndicatorColor;
        break;
      default:
        return "";
    }
  };

  const preparePayload = () => {
    return {
      contentLv: refContentLv.current.getContent() || "",
      contentEn: refContentEn.current.getContent() || "",
      contentRu: refContentRu.current.getContent() || "",
    };
  };

  const handleErrors = (error) => {
    const errorFields = error.response.data.data;
    for (const field in errorFields) {
      form.setError(field.toLowerCase());
    }
  }

  const handleFormSubmit = async (_, event) => {
    event.preventDefault();

    const body = preparePayload();

    try {
      const { data } = await http.backendInstance.put("/api/cms/aboutus", body);
      setDefaultValue(data);
      form.clearErrors();
    } catch (error) {
      if (error.response.data.statusCode === 400) {
        handleErrors(error);
      }
    }
  };

  const fetchAboutUsCmsPage = async () => {
    try {
      const { data } = await http.backendInstance.get("/api/public/aboutus");
      setDefaultValue(data);
      form.clearErrors();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = async () => {
    setDefaultValue(defaultValues);
    await fetchAboutUsCmsPage();
  };

  useEffect(() => {
    fetchAboutUsCmsPage();
  }, []);

  return (
    <FormProvider {...form}>
      <Box sx={{ width: "100%" }}>
        <CustomBreadCrumbs links={links} />
        <TitleForm
          title="Par mums"
          titleCode="FRM.ABOUTUS.IL"
          dateChanged={moment(defaultValue.dateUpdated).isValid() && moment(defaultValue.dateUpdated).format("DD.MM.YYYY. HH:mm:ss")}
        />
        <form onSubmit={form.handleSubmit(handleFormSubmit)}>
          <Box my={1}>
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { backgroundColor: getIndicatorColor(value) },
              }}
              aria-label="language tabs"
            >
              <Tab
                label={
                  <Typography color={firstTabError() ? main : ""}>
                    LV
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <Typography color={secondTabError() ? main : ""}>
                    EN
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <Typography color={thirdTabError() ? main : ""}>
                    RU
                  </Typography>
                }
                {...a11yProps(2)}
              />
            </Tabs>
            <Divider />
            <TabPanel value={value} index={0}>
              <AboutUsFormFields
                name={"contentlv"}
                defaultValue={defaultValue.contentLv}
                ref={refContentLv}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AboutUsFormFields
                name={"contenten"}
                ref={refContentEn}
                defaultValue={defaultValue.contentEn}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <AboutUsFormFields
                name={"contentru"}
                ref={refContentRu}
                defaultValue={defaultValue.contentRu}
              />
            </TabPanel>
          </Box>
          <Grid
            my={2}
            display="flex"
            justifyContent={{ md: "flex-end", xs: "flex-start" }}
            flexDirection={{ md: "row", xs: "column" }}
            gap={2}
          >
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Atcelt
            </Button>
            <Button
              startIcon={<Save />}
              type="submit"
              variant="contained"
              color="success"
            >
              Saglabāt
            </Button>
          </Grid>
        </form>
      </Box>
    </FormProvider>
  );
};

function a11yProps(index) {
  return {
    id: `aboutus-tab-${index}`,
    "aria-controls": `aboutus-tabpanel-${index}`,
  };
}

export default AboutUsCms;
