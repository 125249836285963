import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useFormContext, Controller } from "react-hook-form";
import React from "react";
import useClassifier from "../hooks/useClassifier";
import { useTranslation } from "../hooks/useTranslation";

const GenderSelect = ({
  name,
  defaultValue,
  labelProps,
  testId,
  inputProps,
  required,
  ...props
}) => {
  const { formState, control } = useFormContext();

  const { GetGenderClassifier, genderClassifiers } = useClassifier();

  const GetGenderClassifierCallback = React.useCallback(
    () => GetGenderClassifier(),
    []
  );

  const { InputFieldSex, ValidationErrorMandatoryField } = useTranslation();

  React.useEffect(() => {
    GetGenderClassifierCallback();
  }, [GetGenderClassifierCallback]);

  return (
    <>
      <FormControl
        size="small"
        error={formState.errors[name] ? true : false}
        fullWidth
        {...props}
      >
        <InputLabel {...labelProps}>{InputFieldSex + "*"}</InputLabel>
        <Controller
          rules={{
            required: ValidationErrorMandatoryField || "Lauks ir obligāts",
          }}
          control={control}
          name={name}
          defaultValue={defaultValue || ""}
          render={({ field }) => (
            <Select
              inputProps={{ ...inputProps, "data-testid": testId }}
              autoWidth
              id="gender"
              defaultValue={defaultValue || ""}
              label={InputFieldSex}
              {...field}
            >
              {genderClassifiers.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.code}>
                    {item.nameLv}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        />
        <FormHelperText error>
          {formState.errors[name] ? formState.errors[name]?.message : ""}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default GenderSelect;
