import {
  SET_SNACKBAR_MESSAGE,
  SET_SNACKBAR_MESSAGE_AND_OPEN,
  SNACKBAR_CLOSE,
  SNACKBAR_OPEN,
} from "../actions/snackbar";

const initialState = {
  open: false,
  message: "",
};

export const Snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SET_SNACKBAR_MESSAGE:
      return { ...state, message: action.payload };
    case SNACKBAR_OPEN:
      return { ...state, open: true };
    case SNACKBAR_CLOSE:
      return { ...state, open: false };
    case SET_SNACKBAR_MESSAGE_AND_OPEN:
      return { ...state, open: true, message: action.payload };
    default:
      return state;
  }
};
