import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useFormContext, get, useController } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "../../hooks/useTranslation";
import { Skeleton, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CONSTANTS from "../../constants";

const ControlledTextEditorTinyMce = ({
  name,
  defaultValue,
  width,
  height,
  maxCharLen,
  required,
  shouldUnregister,
  disableTextFormatting,
  hasImageUpload,
  title,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    unregister,
  } = useFormContext();
  const theme = useTheme();
  const errorBorderColor = theme.palette.error.main;

  const [isLoading, setIsLoading] = useState(true);

  const { ValidationErrorMandatoryField } =
    useTranslation() || "Lauks ir obligāts";

  const sizeLimit = maxCharLen ?? CONSTANTS.DEFAULT_TEXT_INPUT_MAX_LENGTH;

  const formattingOptions = !disableTextFormatting
    ? `undo redo | bold italic underline link | numlist bullist | fontsize ${hasImageUpload ? "| image" : ""
    }`
    : false;

  const { field } = useController({
    control,
    name,
    defaultValue,
    rules: { required: required ? ValidationErrorMandatoryField : false },
  });

  const [editor, setEditor] = useState();
  const error = get(errors, name);
  const [contentValue, setContentValue] = useState(defaultValue ?? "");

  const handleInit = (evt, editor) => {
    setEditor(editor);
    setIsLoading(false);
    field.ref.current = {
      focus: () => editor.focus(),
    };
    editor.setContent(defaultValue ?? "");
  };

  //the method is necessary to crop any extra content when pasting
  const handlePastePreprocess = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;

    const pastedContent = clipboardData.getData("text");

    const length = editor.plugins.wordcount.body.getCharacterCount();

    const remainingSpace = sizeLimit - length;

    if (contentValue.length + pastedContent.length > sizeLimit) {
      editor.insertContent(pastedContent.substring(0, remainingSpace));
      event.preventDefault();
      return;
    }

    editor.insertContent(pastedContent);
    event.preventDefault();
  };

  useEffect(() => {
    if (shouldUnregister) {
      return () => unregister(name);
    }
  }, [name, unregister, shouldUnregister]);

  return (
    <>
      {isLoading && <Skeleton animation="wave" variant="rect" height={150} />}
      {title && (
        <Typography
          gutterBottom
          sx={{ color: error ? errorBorderColor : "inherit" }}
        >
          {title}
        </Typography>
      )}
      <Box
        sx={{
          border: error ? `2px solid ${errorBorderColor}` : "none",
          borderRadius: "8px",
        }}
      >
        <Editor
          value={contentValue}
          onInit={handleInit}
          onPaste={handlePastePreprocess}
          onEditorChange={(content, editor) => {
            const characterCount = editor.plugins.wordcount
              ? editor.plugins.wordcount.getCount()
              : 0;
            if (characterCount <= sizeLimit) {
              setContentValue(content);
              setValue(name, content, { shouldValidate: true });
            }
          }}
          init={{
            height: height || 150,
            autoresize_min_height: 150,
            autoresize_max_height: 250,
            width: width || undefined,
            menubar: false,
            plugins:
              "preview autoresize wordcount importcss searchreplace autolink save directionality link fullscreen nonbreaking anchor lists",
            toolbar: formattingOptions,
            image_uploadtab: hasImageUpload,
            remove_trailing_brs: true,
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; } img { max-width: 100%; max-height: 370px; }",
          }}
          apiKey={
            process.env.REACT_APP_TINY_MCE ||
            "hcxdei8hc39pu4eens0gy6b8o9gkty84hvnsb7buh67buolp"
          }
        />
      </Box>
      {errors[name] && (
        <FormHelperText error>{ValidationErrorMandatoryField}</FormHelperText>
      )}
    </>
  );
};

export default ControlledTextEditorTinyMce;
