const ClSurveySubmitterTypeEnum = Object.freeze({
  Personally: 1,
  DoctorNurse: 2
});

const ClSurveySubmitterTypeMapLV = new Map([
  [ClSurveySubmitterTypeEnum.Personally, "Dalībnieks"],
  [ClSurveySubmitterTypeEnum.DoctorNurse, "Ārsts/Medmāsa"],
]);

export { ClSurveySubmitterTypeEnum, ClSurveySubmitterTypeMapLV };