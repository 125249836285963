const InformedConsentStatusEnum = Object.freeze({
  Active: "ACTIVE",
  Draft: "DRAFT",
  Invalid: "INVALID",
  Published: "PUBLISHED",
});

const InformedConsentStatusMapLV = new Map([
  [InformedConsentStatusEnum.Draft, "Sagatave"],
  [InformedConsentStatusEnum.Active, "Spēkā"],
  [InformedConsentStatusEnum.Published, "Publicēts"],
  [InformedConsentStatusEnum.Invalid, "Zaudējis spēku"],
]);

export { InformedConsentStatusEnum, InformedConsentStatusMapLV };