import Grid from "@mui/material/Grid";
import useRightTranslation from "../../hooks/useRightTranslation";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ControlledCheckbox } from "../../MUI_components";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, useFormContext } from "react-hook-form";

function ConsentClassifier({
  id,
  type,
  additionalText,
  consentNameLv,
  consentNameEn,
  consentNameRu,
  firstChoiceLv,
  firstChoiceEn,
  firstChoiceRu,
  secondChoiceLv,
  secondChoiceEn,
  secondChoiceRu,
  thirdChoiceLv,
  thirdChoiceEn,
  thirdChoiceRu,
  obligatory,
}) {
  const { GetRightTranslation } = useRightTranslation();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <>
      {type === "CHECKBOX" && (
        <ControlledCheckbox
          defaultValue={""}
          name={`consent.${id}`}
          label={GetRightTranslation(
            consentNameLv,
            consentNameEn,
            consentNameRu
          )}
          required={obligatory ? true : false}
        />
      )}
      {type === "RADIOBUTTON" && (
        <FormControl
          error={
            errors[`radiobutton`] && errors["radiobutton"][id]?.answer
              ? true
              : false
          }
        >
          {" "}
          {/* //errors[`radiobutton.${id}.answer`] ? true : false}> */}
          <FormLabel id={`radiobutton.${id}`}>
            {GetRightTranslation(consentNameLv, consentNameEn, consentNameRu)}
          </FormLabel>
          <Controller
            control={control}
            name={`radiobutton.${id}.answer`}
            defaultValue={""}
            rules={{ required: obligatory ? true : false }}
            render={({ field }) => (
              <RadioGroup {...field} aria-labelledby={`radiobutton.${id}`}>
                {firstChoiceLv ? (
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label={GetRightTranslation(
                      firstChoiceLv,
                      firstChoiceEn,
                      firstChoiceRu
                    )}
                  />
                ) : null}
                {secondChoiceLv ? (
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label={GetRightTranslation(
                      secondChoiceLv,
                      secondChoiceEn,
                      secondChoiceRu
                    )}
                  />
                ) : null}
                {thirdChoiceLv ? (
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label={GetRightTranslation(
                      thirdChoiceLv,
                      thirdChoiceEn,
                      thirdChoiceRu
                    )}
                  />
                ) : null}
              </RadioGroup>
            )}
          />
        </FormControl>
      )}
      {type === "RADIOBUTTONWITHTEXT" && <div></div>}
    </>
  );
}

export default ConsentClassifier;
