import {
  SET_CURRENT_STAGE,
  SET_FIRST_STAGE,
  SET_FIRST_STAGE_ERROR,
  SET_FOURTH_STAGE,
  SET_SECOND_STAGE,
  SET_THIRD_STAGE,
  UNSET_RESEARCH_STAGES,
  SET_PUBLIC_RESEARCH_TO_NAVIGATE_TO, SET_RESEARCH_MODE
} from "../actions/research";
import { SET_RESEARCH_ID, SET_THIRD_STAGE_ERROR, SET_SUBMIT_SUCCESSFUL, RESET_SUBMIT_SUCCESSFUL } from "../actions/researcher";

const initialState = {
  stage: 0,

  id: "",

  firstStage: {
    id: "",
    code: "",
    startDate: "",
    endDate: null,
    academicTitle: "",
    leadingResearcherUserId: "",
    leadingResearcherFullName: "",
    researchersInvolvedIds: [],
    researcherAssistantIds: [],
    titleLv: "",
    titleEn: "",
    titleRu: "",
    fundingLv: "",
    fundingEn: "",
    fundingRu: "",
    researchPublicationId: ""
  },

  secondStage: {
    researchDraftId: "",
    logoFileRequest: "",
    summaryLv: "",
    summaryEn: "",
    summaryRu: "",
    contentLv: "",
    contentEn: "",
    contentRu: "",
    ethicsCommitteeOpinionLv: "",
    ethicsCommitteeOpinionEn: "",
    ethicsCommitteeOpinionRu: "",
    additionalInfoLv: "",
    additionalInfoEn: "",
    additionalInfoRu: "",
    attachments: [],
  },

  thirdStage: {
    researchDraftId: "",
    participants: "",
    personUniqueId: null,
    applicationTime: "",
  },

  fourthStage: {
    researchDraftId: "",
    getSamples: true
  },

  thirdStageError: {
    errorMessage: "",
    showError: false,
  },

  firstStageError: {
    error: "",
  },

  isSubmitSuccessful: false,

  publicResearchToNavigateTo: null,

  isAddMode: false,
};

export const Research = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_RESEARCH_ID:
      return { ...state, id: payload };
    case SET_SUBMIT_SUCCESSFUL:
      return { ...state, isSubmitSuccessful: payload };
    case RESET_SUBMIT_SUCCESSFUL:
      return {
        ...state,
        isSubmitSuccessful: payload
      };
    case SET_CURRENT_STAGE:
      return { ...state, stage: payload };
    case SET_FIRST_STAGE:
      return {
        ...state,
        firstStage: {
          ...state.firstStage,
          id: payload.id,
          code: payload.code,
          startDate: payload.startDate,
          endDate: payload.endDate,
          titleEn: payload.titleEn,
          titleLv: payload.titleLv,
          titleRu: payload.titleRu,
          academicTitle: payload.academicTitle,
          leadingResearcherFullName: payload.leadingResearcherFullName,
          leadingResearcherUserId: payload.leadingResearcherUserId,
          researchersInvolvedIds: payload.researchersInvolvedIds,
          researcherAssistantIds: payload.researcherAssistantIds,
          fundingEn: payload.fundingEn,
          fundingLv: payload.fundingLv,
          fundingRu: payload.fundingRu,
        },
      };
    case SET_SECOND_STAGE:
      return {
        ...state,
        secondStage: {
          ...state.secondStage,
          researchDraftId: payload.id,
          summaryLv: payload.summaryLv,
          summaryEn: payload.summaryEn,
          summaryRu: payload.summaryRu,
          contentLv: payload.contentLv,
          contentEn: payload.contentEn,
          contentRu: payload.contentRu,
          ethicsCommitteeOpinionLv: payload.ethicsCommitteeOpinionLv,
          ethicsCommitteeOpinionEn: payload.ethicsCommitteeOpinionEn,
          ethicsCommitteeOpinionRu: payload.ethicsCommitteeOpinionRu,
          additionalInfoLv: payload.additionalInfoLv,
          additionalInfoEn: payload.additionalInfoEn,
          additionalInfoRu: payload.additionalInfoRu,
          logoFileRequest: payload.logoFile,
          attachments: payload.researchAttachments,
        },
      };
    case SET_THIRD_STAGE:
      return {
        ...state,
        thirdStage: {
          ...state.thirdStage,
          participants: payload.participants,
          researchDraftId: payload.id,
          applicationTime: payload.applicationTime,
        },
      };
    case SET_FOURTH_STAGE:
      return {
        ...state,
        fourthStage: {
          ...state.fourthStage,
          referralFile: payload.referralFile,
          getSamples: payload.getSamples,
        },
      };
    case SET_THIRD_STAGE_ERROR:
      return { ...state, thirdStageError: payload };
    case UNSET_RESEARCH_STAGES:
      return {
        ...state,
        id: "",
        stage: 0,
        firstStage: initialState.firstStage,
        secondStage: initialState.secondStage,
        thirdStage: initialState.thirdStage,
        fourthStage: initialState.fourthStage,
      };
    case SET_FIRST_STAGE_ERROR:
      return {
        ...state,
        firstStageError: payload
      };
    
    case SET_PUBLIC_RESEARCH_TO_NAVIGATE_TO:
      return {
        ...state,
        publicResearchToNavigateTo: payload
      };
    case SET_RESEARCH_MODE:
      return {
        ...state,
        isAddMode: action.payload,
      };
    default:
      return state;
  }
};
