import {
  LOAD_USER,
  LOAD_USERS,
  LOAD_USER_FAILURE,
  SET_USER_DEFAULT,
  SET_USER_PAGINATION_OPTIONS,
} from "../actions/user";

const initialState = {
  users: [],
  user: {
    userEmail: [],
    userPhone: [],
  },
  userPaginationOptions: {
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [5, 10, 15],
    pageSize: 10,
    page: 0,
  },
  error: null,
};

export const User = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return { ...state, users: action.payload };
    case LOAD_USER:
      return { ...state, user: action.payload };
    case LOAD_USER_FAILURE:
      return { ...state, error: action.payload };
    case SET_USER_DEFAULT:
      return { ...state, error: null, user: initialState.user };
    case SET_USER_PAGINATION_OPTIONS:
      return { ...state, userPaginationOptions: action.payload };
    default:
      return state;
  }
};
