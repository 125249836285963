export const SET_FIRST_STAGE_MESSAGE = "SET_FIRST_STAGE_MESSAGE";
export const SET_FIST_STAGE_ATTACHMENT = "FIRST_STAGE_ATTACHMENT";
export const SET_SECOND_STAGE_MESSAGE = "SECOND_STAGE_MESSAGE";
export const DESTROY_FIRST_STAGE_ATTACHMENT = "DESTROY_FIRST_STAGE_ATTACHMENT";
export const UNSET_FIRST_STAGE_MESSAGE = "UNSET_FIRST_STAGE_MESSAGE";

export const setFirstStageMessage = (payload) => ({
  type: SET_FIRST_STAGE_MESSAGE,
  payload: payload,
});

export const unsetCreateMessage = () => ({
  type: UNSET_FIRST_STAGE_MESSAGE,
});

export const setFirstStageAttachment = (payload) => ({
  type: SET_FIST_STAGE_ATTACHMENT,
  payload: payload,
});

export const destroyFirstStageAttachment = (id) => ({
  type: DESTROY_FIRST_STAGE_ATTACHMENT,
  payload: id,
});

export const setSecondStageMessage = (payload) => ({
  type: SET_SECOND_STAGE_MESSAGE,
  payload: payload,
});
