import ButtonBase from "@mui/material/ButtonBase";
import React from "react";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import { useTheme } from "../../theme";

export const DisplayTextIncreaseDecrease = () => {
  const { increaseFontAction, decreaseFontAction } = useTheme();

  return (
    <>
      <ButtonBase onClick={increaseFontAction}>
        <TextIncreaseIcon fontSize="small" />
      </ButtonBase>
      <ButtonBase onClick={decreaseFontAction}>
        <TextDecreaseIcon fontSize="small" />
      </ButtonBase>
    </>
  );
};
