import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import { useDispatch } from "react-redux";
import { GetTranslation } from "../api/translations/translation";

const LanguageDropdownMobile = () => {
  const [language, setLanguage] = React.useState("Lv");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(GetTranslation(event.target.value));
  };

  React.useEffect(() => {
    dispatch(GetTranslation(language));
  }, [dispatch, language]);

  return (
    <Select
      disableUnderline
      defaultValue={language}
      labelId="mobile-language-dropdown-label"
      id="mobile-language-dropdown"
      variant="standard"
      MenuProps={{ style: { marginLeft: "5rem" } }}
      value={language}
      onChange={handleChange}
      SelectDisplayProps={{
        style: { width: "150px", paddingLeft: "6rem", background: "#FFFFF" },
      }}
      IconComponent={ArrowRightIcon}
      background
    >
      <MenuItem value={"Lv"}>Latvian</MenuItem>
      <MenuItem value={"En"}>English</MenuItem>
      <MenuItem value={"Ru"}>Russian</MenuItem>
    </Select>
  );
};

export default LanguageDropdownMobile;
