import { useEffect, useState } from "react";

import {
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

// SelectListItem = { text: string, value?: string}
const SelectList = ({
  selectLabel,
  inputLabel,
  selectListItems,
  handleSelectedListItem,
  isClearenceNeeded,
  handleValueClear,
  disabled,
  error: externalError = false
}) => {
  const [selectedListItem, setSelectedListItem] = useState("");
  const [selectError, setSelectError] = useState(externalError);

  const handleListItemChange = (event) => {
    const listItemName = event.target.value;
    const selectedItem = selectListItems.find((listItem) => {
      return listItem.text === listItemName;
    });

    if (!selectedItem || !selectedItem.text) {
      setSelectError(true);
    } else {
      setSelectError(false);
    }

    setSelectedListItem(selectedItem);
    handleSelectedListItem(selectedItem);
  };

  useEffect(() => {
    setSelectError(externalError);
  }, [externalError]);

  useEffect(() => {
    if (!isClearenceNeeded) return;

    setSelectedListItem("");
    handleValueClear(false);
  }, [isClearenceNeeded]);

  return (
    <>
      <Grid container item>
        <Grid item xs={12} container direction="column">
          <Typography>{selectLabel}</Typography>
        </Grid>
        <Grid container xs={12} item>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" sx={{ mb: 0.5 }} error={selectError}>
              <InputLabel>{inputLabel || selectLabel}</InputLabel>
              <Select
                value={selectedListItem?.text || ""}
                label={selectLabel}
                onChange={handleListItemChange}
                disabled={disabled}
              >
                {selectListItems &&
                  selectListItems.map((listItem) => {
                    return (
                      <MenuItem key={listItem.text} value={listItem.text}>
                        <Typography>{listItem.value}</Typography>
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectList;
