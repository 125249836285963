import {
  CLEAR_CLSINGLE,
  CLEAR_CLSTATUS,
  CLEAR_CONSENT_CLASSIFIER,
  SET_CLASSIFIER,
  SET_CLASSIFIER_FAILURE,
  SET_CLCONSENTRESPONSE,
  CLEAR_CLCONSENTRESPONSE,
  SET_CLSEX,
  SET_CLSINGLE,
  SET_CLSTATUS,
  SET_CONSENT_CLASSIFIER,
  SET_CONSENT_CLASSIFIER_DROPDOWN_IDS,
  SET_CONSENT_CLASSIFIER_NUMBER,
  SET_CLTESTGROUPS,
  SET_CLTESTS,
  SET_CLQUESTIONTYPES,
  CLEAR_CLQUESTIONTYPES,
  SET_CLANSWERTYPES,
  CLEAR_CLANSWERTYPES,
  SET_CLREFERRALSTATUS,
  CLEAR_CLREFERRALSTATUS,
} from "../actions/classifier";

const initialState = {
  data: [],
  error: null,
  clSex: [],
  singleCl: [],
  clStatus: [],
  clConsent: [],
  clConsentDropdownIds: [],
  clConsentResponse: [],
  clTestGroups: [],
  clTests: [],
  clQuestionTypes: [],
  clAnswerTypes: [],
  clConsentNumber: -1,
  clReferralStatus: [],
};

export const Classifier = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSENT_CLASSIFIER_NUMBER:
      return { ...state, clConsentNumber: action.payload };
    case SET_CONSENT_CLASSIFIER_DROPDOWN_IDS:
      return { ...state, clConsentDropdownIds: action.payload };
    case SET_CONSENT_CLASSIFIER:
      return { ...state, clConsent: action.payload };
    case CLEAR_CONSENT_CLASSIFIER:
      return { ...state, clConsent: [] };
    case CLEAR_CLSINGLE:
      return { ...state, singleCl: [] };
    case SET_CLSINGLE:
      return { ...state, singleCl: action.payload };
    case SET_CLASSIFIER:
      return { ...state, data: action.payload };
    case SET_CLASSIFIER_FAILURE:
      return { ...state, error: action.payload };
    case SET_CLSEX:
      return { ...state, clSex: action.payload };
    case SET_CLSTATUS:
      return { ...state, clStatus: action.payload };
    case CLEAR_CLSTATUS:
      return { ...state, clStatus: [] };
    case SET_CLCONSENTRESPONSE:
      return { ...state, clConsentResponse: action.payload };
    case CLEAR_CLCONSENTRESPONSE:
      return { ...state, clConsentResponse: [] };
    case SET_CLTESTGROUPS:
      console.log('Reducer received payload for SET_CL_TEST_GROUPS:', action.payload);
      return { ...state, clTestGroups: action.payload };
    case SET_CLTESTS:
      return { ...state, clTests: action.payload };
    case SET_CLQUESTIONTYPES:
      return { ...state, clQuestionTypes: action.payload };
    case CLEAR_CLQUESTIONTYPES:
      return { ...state, clQuestionTypes: [] };
    case SET_CLANSWERTYPES:
      return { ...state, clAnswerTypes: action.payload };
    case CLEAR_CLANSWERTYPES:
      return { ...state, clAnswerTypes: [] };
    case SET_CLREFERRALSTATUS:
      return { ...state, clReferralStatus: action.payload };
    case CLEAR_CLREFERRALSTATUS:
      return { ...state, clReferralStatus: [] };
      default:
      return state;
  }
};
