import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, Grid } from "@mui/material";
import { BaseButton } from "../../buttons";

const SysNotificationDeleteDialog = ({ open, setIsOpen, handleContinue }) => {
  const handleClose = () => {
    setIsOpen();
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-delete-notification"
      aria-describedby="alert-dialog-notification-description"
    >
      <DialogTitle id="alert-dialog-delete-notification">
        <Typography variant="h4">Dzēst?</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-notification-description">
          <Typography variant="h5">
            Vai tiešām vēlaties dzēst paziņojumu?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <BaseButton
              onClick={handleContinue}
              variant="contained"
              color="primary"
              fullWidth
              autoFocus
            >
              Turpināt
            </BaseButton>
          </Grid>
        <Grid item>
          <BaseButton
            variant="contained"
            color="error"
            onClick={handleClose}
          >
            Atcelt
          </BaseButton>
        </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default SysNotificationDeleteDialog;
