export const LOAD_TRANLSATIONS = "[translations] load";
export const LOAD_TRANSLATIONS_FAILURE = "[translations] load failure";
export const LOAD_TRANSLATION = "[translation] load";
export const LOAD_TRANSLATION_FAILURE = "[translation] failure";
export const LOAD_TRANSLATIONS_ELEMENTS =
  "[translations] load elements translations";
export const UNLOAD_TRANSLATIONS = "[translations] unload";
export const UNLOAD_TRANSLATIONS_ELEMENTS = "[translations] unload elements";
export const LOAD_TRANSLATIONS_NOTIFICATIONS =
  "[translations] load notifications";
export const UNLOAD_TRANSLATIONS_NOTIFICATIONS =
  "[translations] unload notifications";
export const SET_DATEUPDATED = "[translations] load date updated";
export const SET_LANGUAGE = "[translations] set language";

export const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload: payload,
});

export const setTranslationsDateUpdated = (date) => ({
  type: SET_DATEUPDATED,
  payload: date,
});

export const unloadTranslationElements = () => ({
  type: UNLOAD_TRANSLATIONS_ELEMENTS,
});

export const unloadTranslations = () => ({
  type: UNLOAD_TRANSLATIONS,
});

export const loadElementsTranslation = (data) => ({
  type: LOAD_TRANSLATIONS_ELEMENTS,
  payload: data,
});

export const loadNotifications = (data) => ({
  type: LOAD_TRANSLATIONS_NOTIFICATIONS,
  payload: data,
});

export const unloadNotifications = () => ({
  type: UNLOAD_TRANSLATIONS_ELEMENTS,
});

export const loadTranslations = (data) => ({
  type: LOAD_TRANLSATIONS,
  payload: data,
});

export const loadTranslation = (data) => ({
  type: LOAD_TRANSLATION,
  payload: data,
});

export const loadTranslationsFailure = (data) => ({
  type: LOAD_TRANSLATIONS_FAILURE,
  payload: data,
});

export const loadTranslationFailure = (data) => ({
  type: LOAD_TRANSLATION_FAILURE,
  payload: data,
});
